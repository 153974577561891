   

 
import React, {useEffect,useRef, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import DropDown from './DropDown';
import {useFormik} from "formik";
import * as yup from "yup"
import { BsCheck } from 'react-icons/bs';
function AddCustomerService(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])
const nameRef=useRef()
const phoneRef=useRef()
const userNameRef=useRef()
const codeRef=useRef()
const passwordRef=useRef()
const typeRef=useRef()


let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let numRegEx =/^[0-9]+$/;
    const Schema= yup.object().shape({
        name: yup.string().required("Agent name is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Agent name should contain letters only")}),
        phone: yup.string().required("Phone number is required").matches(phoneRegEx,"Enter a valid phone number"),
        userName: yup.string().required("Username is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Username name should contain letters only")}),
        code: yup.string().required("Employee code is required").matches(numRegEx,"Employee code must contain digits only"),
        type: yup.string().required("Employee type  is required"),
        password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),
   
        })


        const formik = useFormik({
          initialValues:{
          name: '',
          phone: '',
          userName: '',
          code: '',
          type: '',
          password: '',

           
          },
          validationSchema: Schema,
          onSubmit,
          });



          function onSubmit(value){
          }

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Customer Service</p>
<div className='maxHeight'>

<label>Name :</label>
<input placeholder='Enter name' value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.name && formik.touched.name?formik.errors.name:null}</span>

    <label>Phone :</label>
    <input placeholder='Enter phone' value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.phone && formik.touched.phone?formik.errors.phone:null}</span>

    <label>Username :</label>
    <input placeholder='Enter name' value={formik.values.userName} id="userName" name="userName"  ref={userNameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.userName && formik.touched.userName ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.userName && formik.touched.userName?formik.errors.userName:null}</span>

    <label>Employee Code :</label>
    <input placeholder='Enter code' value={formik.values.code} id="code" name="code"  ref={codeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.code && formik.touched.code?formik.errors.code:null}</span>

    <label>Account Type</label>
    <div className='select-container'>
  <select value={formik.values.type} id="type" name="type"  ref={typeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.type && formik.touched.type ?"invalid":null}>
  <option value={null}>10</option>

      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span  style={formik.errors.type && formik.touched.type?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.type && formik.touched.type?formik.errors.type:null}</span>


  <label>Password:</label>
    <input placeholder='Enter name'  value={formik.values.password} id="password" name="password"  ref={passwordRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password?formik.errors.password:null}</span>



<div className='supervisor'>

<div className='containCheck'>
    <div> 
<input id="supervisor" type="checkbox" className="chooseClass"/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>


<label>Supervisor</label>
</div>
    
<label>Supervisor</label>
<div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  
 <DropDown label="Roles"/>

  
           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddCustomerService;
 