import '../styles/categories.css';
import pp from "../assets/categories.png"

import meal from "../assets/meal.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {IoIosCloseCircleOutline} from 'react-icons/io';
import {RiHome6Line,RiDeleteBinLine}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';

import AddNewsPost from '../components/AddNewsPost';
function Observation() {
  const [filter,setFilter]=useState(false)


  return (
    <div className='dashboardContainer usersContainer'>
 
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>



  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Chat</p>
<div className='navigateTop'>
  
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/chat">

<p className='bc1'>Chat</p>
</Link>
<img src={right}/>

  <Link to="/chat/observation">

<p className='bc2'>Observation List</p>
</Link>
</div>
    </div>
  
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Observations list</p>
  <button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>

  </div>


  <div className={filter?'filtersMore':'filtersLess'}>
<div className='filtersContainer'>
  
<div className='flex-2'>
  <label>Search for order </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
<label>Show </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>

<div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>


  </div>
<div style={{marginTop:"16px"}}>
<div className='filtersContainer'>
  

  
  
  <div className='flex-2 '>
  
  <button className='clear' style={{paddingLeft:"0"}}><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  
  
  </div>
</div>
 


  </div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>User</th>
    <th>Phone</th>
    <th>Order ID</th>
    <th>Branch</th>
    <th>About</th>
    <th>Msg</th>
    <th>At </th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Nourhan elmansy </td>
<td  >0583928398 </td>
<td> 1231</td>
<td> Rawabi</td>
<td> Time</td>
<td> 1231</td>
<td> 3-3-2023</td>


    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Nourhan elmansy </td>
<td  >0583928398 </td>
<td> 1231</td>
<td> Rawabi</td>
<td> Time</td>
<td> 1231</td>
<td> 3-3-2023</td>


    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Observation;
