import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import pin from "../assets/pin.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"

import menu from "../assets/menu2.svg"
import gift from "../assets/gift.svg"
import location from "../assets/location.svg"
import wallet from "../assets/wallet2.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import {BsTelephone,BsDot} from 'react-icons/bs';

import {RxDotFilled} from 'react-icons/rx';

import {HiOutlineMail} from 'react-icons/hi';

import {IoIosCloseCircleOutline} from 'react-icons/io';
import AddWallet from '../components/AddWallet';
import { useState } from 'react';
function EditUser() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])



  function handleCheck(value){
    const newArr=checkList
if (newArr.includes(value)) {
newArr.splice(newArr.indexOf(value), 1);  // Remove value
} else {
newArr.push(value);  // Add value
}
setCheckList(newArr)
}


document.addEventListener("change", function(event) {
  if (event.target.matches("input[type='checkbox']")) {
    console.log("test")

    var checkbox = event.target;
    var parentElement = checkbox.parentElement.parentElement.parentElement;
    
    if (checkbox.checked) {
      parentElement.classList.add("checked");
    } else {
      parentElement.classList.remove("checked");
    }
  }
});


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddWallet setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Edit Profile</p>
<div className='navigateTop'>
  
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/users/users-list">
  <p className='bc1'>Users</p>

  </Link>
<img src={right}/>
<Link to="/users/users-list">

<p className='bc1'>Users List</p></Link>

<img src={right}/>
<Link to="/users/edit-user">

<p className='bc2'>User Profile</p>
</Link>
</div>
    </div>
    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
Export Positive Balance </button>
  </div>
 

<div style={{height:"90%"}} className='editContainer'>
  <div>


<p className='editTitle'>Edit User Data</p>
<div className='editRow'>


<div>
    <label>Name</label>
    <input placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
    <label>Phone</label>
    <input placeholder='test'/>
</div>
<div>
    <label>Passwors</label>
    <input placeholder='test'/>
</div>

</div>
<div>


<div className='editRow'>
<div>
    <label>Confirm Password</label>
    <input placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
    <label>Classification</label>
   <div  className={open?'mutli-select':'mutli-select mutli-selectClosed'}>
   <div className='select-container'>

   <div onClick={()=>setOpen(!open)} className='multi-selectInput'>
<p className='multi-selectDef'>Choose</p>
</div>
  <BiChevronDown className='select-arrow' size={20}/>
</div>

<div className='select-dropdown'>
<div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='Search'/>

<div className='spaceBtn'>

<label className="labelCheck">kalam</label>

<div className='containCheck'>
    <div>

   
<input type="checkbox" className="chooseClass" value="Other" onChange={(e)=>handleCheck(e.target.value)}/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>
    </div>
   
    <div className='spaceBtn'>

<label className="labelCheck">kalam</label>

<div className='containCheck'>
    <div>

   
<input type="checkbox" className="chooseClass" value="Other" onChange={(e)=>handleCheck(e.target.value)}/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>
    </div>
    <div className='spaceBtn'>

<label className="labelCheck">kalam</label>

<div className='containCheck'>
    <div>

   
<input type="checkbox" className="chooseClass" value="Other" onChange={(e)=>handleCheck(e.target.value)}/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>
    </div>

    </div>
</div>
   </div>
</div>
<div></div>
</div>
</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditUser;
