import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import {useFormik} from "formik";
import * as yup from "yup"
import AddDistrict from '../components/AddDistrict';
import { useState } from 'react';
function EditDistrict() {
    const [add,setAdd]=useState(false)
    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

    const Schema= yup.object().shape({
        nameAr: yup.string().required("District name is required").matches(nameRegEx,{message:("District name should contain letters only")}),
        nameEn: yup.string().required("District name is required").matches(nameRegEx,{message:("District name should contain letters only")}),
  
        })

        const formik = useFormik({
          initialValues:{
            nameAr: '',
            nameEn: '',           
          },
          validationSchema: Schema,
          onSubmit,
          });
  
  
  
          function onSubmit(value){
          }

          
  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddDistrict setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Country</p>
    <div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/countries">
  <p className='bc1'>Country</p>

  </Link>


<img src={right}/>
<Link to="/countries/districts">

<p className='bc2'>Districts</p>
</Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add District </button>
  </div>
 

<div className='editContainer editAgent'>
    <div>


<p className='editTitle'>Edit District Data</p>
<div className='editRow'>


<div>
    <label>Name Ar</label>
    <input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>
</div>
<div style={{margin:"0 25px"}}>
    <label>Name En</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>
</div>
<div>
   
</div>

</div>
</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditDistrict;
