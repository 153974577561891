    

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import Button from "../assets/Button.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';
import {GrFormClose} from 'react-icons/gr';
import pos from "../assets/pos.svg"
import es from "../assets/es.svg"
import qr from "../assets/qrStatus.svg"
import ca from "../assets/ca.svg"
import cs from "../assets/cs.svg"
import web from "../assets/web.svg"

function MealStatus(props) {

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal changeStatus maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCity" style={{height:"auto",width:"48vw"}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             <div style={{marginBottom:"38px"}} className='flexAlign'>
             <p style={{marginBottom:"0",paddingRight:"0"}} className='modalTitle'>Churros sweet Oreo status:</p>
<button className='apply' style={{backgroundColor:"#F73782",width:"160px",margin:"0",marginRight:"32px"}}>Close All</button>
             </div>


             <div className='maxHeight'>


             <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={web}/>
                     <div>

                
                     <p className='statusTitle'>Website</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>


             <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={cs}/>
                     <div>

                
                     <p className='statusTitle'>Customer Service</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>

             <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={ca}/>
                     <div>

                
                     <p className='statusTitle'>Customer Application</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>

             <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={qr}/>
                     <div>

                
                     <p className='statusTitle'>QR Code</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>

             <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={es}/>
                     <div>

                
                     <p className='statusTitle'>External-sales</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>
                 <div className='mealStatus'>
                 <div style={{display:"flex", alignItems:"center"}}>
                     <img src={pos}/>
                     <div>

                
                     <p className='statusTitle'>Pos</p>
                     <p className='statusTxt'>test text tetst</p>     </div>
                 </div>
                 <div className="form-check form-switch">
  <input style={{ backgroundImage: `url(${Button})` }} onChange={(e)=>console.log(e.target.value)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
</div>
                 </div>
           <div className='modalBtns ' >
               <div style={{width:"100%"}}>

          
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button>
           </div>
           </div>
            </div>
           
     
            </div>
          </div>
          </div>
        </div>
    

    );
}

export default MealStatus;
 