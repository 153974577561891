   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';

import {GrFormClose} from 'react-icons/gr';

function DropDown(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])
const [empty,setEmpty]=useState(false)


function handleCheck(value){
    const newArr=checkList
if (newArr.includes(value)) {
newArr.splice(newArr.indexOf(value), 1);  // Remove value
} else {
newArr.push(value);  // Add value
}
setCheckList(newArr)
}


document.addEventListener("change", function(event) {
    console.log(event.target.id=="supervisor")

  if (event.target.matches("input[type='checkbox']") && !(event.target.id=="supervisor")) {
    console.log("test")

    var checkbox = event.target;
    var parentElement = checkbox.parentElement.parentElement.parentElement;
    
    if (checkbox.checked) {
      parentElement.classList.add("checked");
    } else {
      parentElement.classList.remove("checked");
    }
  }
});



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
<>
 
    <label>{props.label? props.label :"Permissions"}</label>
   <div  className={open?'mutli-select':'mutli-select mutli-selectClosed'}>
   <div className='select-container'>

   <div onClick={()=>setOpen(!open)} className='multi-selectInput'>
{/*<p className='multi-selectDef'>Choose</p>*/}
<div className='chocies'>

  <p><RxDotFilled color='rgba(245, 10, 102, 1)'/> Branches <GrFormClose className='closeGray' />
  </p>  
</div>
<div className='chocies'>

  <p><RxDotFilled color='rgba(245, 10, 102, 1)'/> Branches <GrFormClose className='closeGray' />
  </p>  
</div>
<div className='chocies'>

  <p><RxDotFilled color='rgba(245, 10, 102, 1)'/> Branches <GrFormClose className='closeGray' />
  </p>  
</div>

</div>
{empty?
  <BiChevronDown className='select-arrow' size={20}/>:null}
</div>

<div className='select-dropdown'>
<div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='Search'/>
    <div className='spaceBtn'>
<label className="labelCheck">kalam</label>
<div className='containCheck'>
    <div> 
<input type="checkbox" className="chooseClass" value="Other" onChange={(e)=>handleCheck(e.target.value)}/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>
    </div>

    </div>
</div>
   </div>

           </>
    

    );
}

export default DropDown;
 