import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import {useFormik} from "formik";
import * as yup from "yup"
import AddArea from '../components/AddArea';
import { useState,useRef,useEffect } from 'react';
function EditArea() {
    const [add,setAdd]=useState(false)
    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
    let numRegEx =/^[0-9]+$/;
    
        const Schema= yup.object().shape({
            nameAr: yup.string().required("Area name is required").matches(nameRegEx,{message:("Area name should contain letters only")}),
            nameEn: yup.string().required("Area name is required").matches(nameRegEx,{message:("Area name should contain letters only")}),
            ordinary: yup.string().required("Delivery price is required").matches(numRegEx,"Delivery price should contain digits only"),
            fast:yup.string().required("Delivery price is required").matches(numRegEx,"Delivery price should contain digits only"),
            min: yup.string().required("Minimum order is required").matches(numRegEx,"Minimum order should contain digits only"),
            time:yup.string().required("Delivery time is required").matches(numRegEx,"Delivery time should contain digits only"),
            distance: yup.string().required("Distance is required").matches(numRegEx,"Distance should contain digits only"),
            })
    
    
            const formik = useFormik({
              initialValues:{
                nameAr: '',
                nameEn: '',
                ordinary: '',
                fast: '',
                min: '',
                min: '',
                distance: '',
    
               
              },
              validationSchema: Schema,
              onSubmit,
              });
    
    
    
              function onSubmit(value){
              }
  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddArea setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Country</p>
    <div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/countries">
  <p className='bc1'>Country</p>

  </Link>
<img src={right}/>
<Link to="/countries">

<p className='bc1'>Country List</p></Link>
<img src={right}/>
<Link to="/countries/cities">

<p className='bc1'>Cities List</p></Link>

<img src={right}/><Link to="/countries/cities/areas">

<p className='bc2'>Area List</p></Link>

</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Area </button>
  </div>
 

<div style={{height:"80%"}} className='editContainer editAgent'>
    <div>


<p className='editTitle'>Edit Area Data</p>
<div className='editRow'>


<div>
    <label>Name Ar:</label>
    <input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>

</div>
<div style={{margin:"0 25px"}}>
    <label>Name En:</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>

</div>
<div style={{margin:"0 25px"}}>
    <label>Ordinary Delivery Price: </label>
    <input placeholder='Enter name' value={formik.values.ordinary} id="ordinary" name="ordinary" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinary && formik.touched.ordinary ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.ordinary && formik.touched.ordinary?formik.errors.ordinary:null}</span>
</div>
</div>

<div>
<div className='editRow'>


<div>
    <label>Fast Delivery Price: </label>
    <input placeholder='Enter name' value={formik.values.fast} id="fast" name="fast" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fast && formik.touched.fast ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.fast && formik.touched.fast?formik.errors.fast:null}</span>
</div>
<div style={{margin:"0 25px"}}>
    <label>Minimum Order:</label>
    <input placeholder='Enter name' value={formik.values.min} id="min" name="min" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.min && formik.touched.min ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.min && formik.touched.min?formik.errors.min:null}</span>
</div>
<div style={{margin:"0 25px"}}>
    <label>Delivery Time: </label>
    <input placeholder='Enter name' value={formik.values.time} id="time" name="time" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.time && formik.touched.time ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.time && formik.touched.time?formik.errors.time:null}</span>
</div>
</div> 
</div>

<div>
<div className='editRow'>


<div>
    <label>Distance:</label>
    <input placeholder='Enter name' value={formik.values.distance} id="distance" name="distance" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.distance && formik.touched.distance ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.distance && formik.touched.distance?formik.errors.distance:null}</span>

</div>
<div style={{margin:"0 25px"}}>

</div>
<div style={{margin:"0 25px"}}>
 
</div>
</div> 
</div>


</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditArea;
