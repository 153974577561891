import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import AddOffer from '../components/AddOffer';
import { useState ,useRef,useEffect} from 'react';
import DropDown from "../components/DropDown";
import AddSalesMenu from "../components/AddSalesMenu";
import saudi from "../assets/saudi.svg"
import { Link } from 'react-router-dom';

import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';

function EditSalesMenu() {
  const [add,setAdd]=useState(false)
  const uploadImg= useRef()
  const [image,setImage]=useState()
  const [preview,setPreview]=useState()

  useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
      }
    
      };

  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddSalesMenu setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Sales</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/sales/menus">

<p className='bc1'>Sales</p></Link>
<img src={right}/>
<Link to="/sales/menus">

<p className='bc2'>Menus List</p></Link>

</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Menu </button>
  </div>
 

<div style={{height:"auto"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Sales Menu</p>
<div className='editRow'>

<div>
    <label>Name Ar :</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
<label>Name En :</label>
<input type="text" placeholder='test'/>


</div>

<div >
<label>Description Ar :</label>
<input type="text" placeholder='test'/>

</div>


</div>

<div>
<div className='editRow'>

<div>
    <label>Description En :</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
<label>Active :</label>
<div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
  </div>
</div>

<div >
<label>Code :</label>
<input type="text" placeholder='test'/>

</div>


</div>

</div>

<div>
<div className='editRow'>

<div>
    <label>Price Per-Person :</label>
    <input type="number" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
<label>Min Persons :</label>
<input type="number" placeholder='test'/>

</div>

<div >
<label>Max Persons :</label>
<input type="text" placeholder='test'/>

</div>


</div>

</div>

<div>
<div className='editRow'>

<div style={{maxWidth:"31.5%"}}>

<DropDown label="Meals :"/>
</div>

<div></div>
<div></div>

</div>


</div>

<p className="editQuan">Meal Quantity</p>

<div className='editRow'>

<div>
    <label>Mix grill Quarter Bashawer Rice :</label>
    <input type="number" placeholder='32'/>
</div>

<div  style={{margin:"0 25px"}}>
    <label>Mix grill Quarter Bashawer Rice :</label>
    <input type="number" placeholder='33'/>
</div>

<div  >
    <label>Mandi Goat Meal Saudi Rice :</label>
    <input type="number" placeholder='44'/>
</div>


</div>

<div className='editRow'>

<div >
<label>Photo :</label>
<div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>
<div className='countryImg'  style={{ backgroundImage: `url(${saudi})` }}>

    </div>

<div></div></div>

</div>
 </div>


<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditSalesMenu;
