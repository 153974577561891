import '../styles/categories.css';
import pp from "../assets/categories.png"

import meal from "../assets/meal.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line,RiDeleteBinLine}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';

import AddPost from '../components/AddPost';
function Blog() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [del,setDel]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
      {add?<AddPost setModal={setAdd}/>:null}
      {del?<DeleteModal text="Are you sure you want to delete this post?" setModal={setDel}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>


  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Blog</p>
<div className='navigateTop'>
  
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/blog">

<p className='bc1'>Blog</p></Link>
<img src={right}/>
<Link to="/blog">

<p className='bc2'>Blog List</p>
</Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Post </button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your blog list</p>
  </div>

  <div className='filtersMore'>
<div className='filtersContainer'>
  
<div className='flex-2' style={{marginRight:"70px"}}>
  <label>Search </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
<label>Show </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>
 

<div className='flex-2' >
  </div>
  </div></div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>Title</th>
    <th>Image </th>

    <th style={{textAlign:"center"}}>Action</th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Spanish Meals</td>
<td> <div className='featImg'   style={{ backgroundImage: `url(${pp})`, width:"80px" , height:"47px"}}>
</div> </td>

<td style={{textAlign:"center"}}>

<Link to="/blog/edit-post">
  <button>
  <img src={edit}/>
  </button>

  </Link>
  <button className='deleteBtn' onClick={()=>setDel(true)}>

 <RiDeleteBinLine size={19}/></button>

  </td>
  
    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Spanish Meals</td>
<td> <div className='featImg'   style={{ backgroundImage: `url(${pp})`, width:"80px" , height:"47px"}}>
</div> </td>




  <td style={{textAlign:"center"}}>

<Link to="/blog/edit-post">
  <button>
  <img src={edit}/>
  </button>

  </Link>
  <button className='deleteBtn' onClick={()=>setDel(true)}>

 <RiDeleteBinLine size={19}/></button>

  </td>

  
    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Blog;
