   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import user from "../assets/user.svg"

function UserModal(props) {

const history= useNavigate();



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="userModal" style={{position:"absolute",width:"100%"}}>
     
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"40vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
            
            <div style={{position:"relative"}}>

      
             <div className='navPp' style={{ backgroundImage: `url(${user})` }}>
  </div>
  </div>
<p className='usrName'>Ahmed Moustafa Abdelkuder</p>
<p className='usrPhone'>(+02)1282032962</p>
    
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default UserModal;
 