import '../styles/orders.css';

import logoPlain from "../assets/logoPlain.png"
import car from "../assets/car.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import Navbar from '../components/Navbar';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"
import Status from '../components/Status';
import {RiHome6Line}  from 'react-icons/ri';
import Invoice from '../components/Invoice';
import {FiSearch} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import UserModal from '../components/UserModal';
import { useState } from 'react';
import { GoDotFill} from "react-icons/go";
import AddArea from '../components/AddArea';
function OrderDetails() {
    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries:['places']
    })
  const [status,setStatus]=useState(false)
  const [add,setAdd]=useState(false)
  const [invoice,setInvoice]=useState(false)
  const [show,setShow]=useState(false)
  const [position,setPostion]=useState(

    {
lat:20,
lng:30


    }
  )  



  function onMarkerDragEnd(evt){

    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();

   setPostion({
  
    lat:newLat,
    lng:newLng
   })
  
  };
  return (
    <div className='dashboardContainer usersContainer'>
      {status?<Status setModal={setStatus}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Orders</p>
<div className='navigateTop'>
 
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>
</Link>
  <img src={right}/>
  <Link to="/orders">

<p className='bc1'>Order</p></Link>
<img src={right}/>
<Link to="/orders">

<p className='bc1'>Order List</p>
</Link>
<img src={right}/>
<Link to="/orders/5">

<p className='bc2'>Order Details</p></Link>
</div>
    </div>
    <div className='orderDet'>

    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
View Map   </button>
<button className='sync'>
    Sync
</button>
</div></div>

<div className='orderContainer'>
    <div className=' container-fluid orderPadding'>


    <div className='row gy-4'>
  <div className="col-12">
    {isLoaded?
        <GoogleMap zoom={8} 
    center={{ lat: position.lat, lng: position.lng}}

         mapContainerClassName="map"

    options={{
        mapTypeControl: false
    }}
    > 
<MarkerF  
draggable={true}

position={{ lat: position.lat, lng: position.lng}}

onDragEnd={(e) =>onMarkerDragEnd(e)}

/>

    </GoogleMap>:null}
        </div>

        <div className='col-4'>
            <div className='trackOrder'>
                <p className='otTitle'>Track Order</p>
                <p className='otId'>Order ID: #23094</p>
<div className='orderTimeLine'> 

<div className="connectedDots">
<div className='dot'></div>

<div className='line'></div>
<div className='dot'></div>

<div className='line'></div>
<div className='dot'></div>
<div className='line' style={{background:"#CECDCB"}}></div>
<div className='dot' style={{background:"#CECDCB"}}></div>
<div className='line' style={{background:"#CECDCB"}}></div>

</div>

<div className='timeLine'>
    <div>
    <p>Order placed</p> 
   <p className='odDate'>April 21/23/32</p> 
    </div>
    <div>
    <p>Recived</p> 
   <p className='odDate'>April 21/23/32</p> 
    </div>
    <div>
    <p>Start</p> 
   <p className='odDate'>April 21/23/32</p> 
    </div>
    <div>
    <p> Delivered</p> 
   <p className='odDate'>April 21/23/32</p> 
    </div>
 
</div>
</div>
<div className='totalTime'>
<p>Actual Time: <span>45min</span></p>
<p>Order Time: <span>45min</span></p>
</div>
                </div>
            </div>
            <div className='col-8'>
<div className='odTable'>

<div className='odTable-flex'>
<p>Items from order #10932</p>
<div>
<div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='Search'/>
    </div>
    <button>Cancel</button>
</div>
</div>
<div className='odTableContainer'>
<table>
    <thead>
        <tr>

        <th>Product Name</th>
        <th>Size</th>
        <th>Quantity</th>
        <th>Price</th>
        <th>Additionals</th>
        <th>Extras</th>
            
        </tr>
    </thead>
    <tbody>
        <tr>
<td>حلوى الشوروز</td>
<td>حلوى </td>
<td>5</td>
<td>100</td>
<td>حلوى الشوروز</td>
<td>حلوى الشوروز</td>
        </tr>
        <tr className='lastRow'>
<td>حلوى الشوروز</td>
<td>حلوى </td>
<td>5</td>
<td>100</td>
<td>حلوى الشوروز</td>
<td>حلوى الشوروز</td>
        </tr>
    </tbody>
</table>
</div>
</div>


            </div>

            <div className='col-4'>
<div className='orderDetContainer'>
<p className='orderDet-title'>Shipping information</p>
<p>Mariam Aly</p>
<p>Address: <span>7 Mahmoud Shoukry street</span></p>
<p>Branch: <span>Rawabi</span></p>
<p>Phone: <span>7 Mahmoud Shoukry street</span></p>
<p>Order Type: <span>Home Delivery</span></p>
<p>Note: <span>test</span></p>
<p>Time Creation: <span>12:21 am</span></p>
<p>Platform: <span>Web</span></p>

</div>
            </div>
            <div className='col-4'>
            <div className='orderDetContainer'>
<p className='orderDet-title'>Invoice</p>
<p>Mariam Aly</p>
<p>Price: <span>234 SAR</span></p>
<p>Delivery Price: <span>32 SAR</span></p>
<p>Discount: <span>23 SAR</span></p>
<p>Wallet Pay: <span>33 SAR</span></p>
<p>Awafi Wallet Amount: <span>21 SAR</span></p>
<p> Remain: <span>3 SAR</span></p>
<p>Total Price: <span>323 SAR</span></p>

</div>
</div>
<div className='col-4'>
<div className='deliveryContainer'>
<p style={{marginBottom:"16px"}} className='delivery-title'>Order Info</p>
<img src= {car}/>
<p style={{margin:"12px 0"}} className='delivery-title'>Delivery</p>
<p>Order ID: <span>3234</span></p>
<p>Payment Method: <span> Cash</span></p>

<p> Sync: <span>Yes</span></p>
<button onClick={()=>setStatus(true)}>Update Status</button>
    </div>
</div>
        </div>    </div>
    </div>
  </div>
 


  </div>

   </div>
    </div>
  );
}

export default OrderDetails;
