import '../styles/terms.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import { EditorState } from 'draft-js';

import {FiSearch} from 'react-icons/fi';
import { Editor } from "react-draft-wysiwyg";

import { useState } from 'react';
import AddSource from '../components/AddSource';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditTerms() {
    const [add,setAdd]=useState(false)
    const [text, setText] = useState('');

 /*   const onContentStateChange = (newContentState) => {
      setContentState(newContentState);
    };*/
    const modules = {
            toolbar: [
                ['bold', 'italic', 'underline',{ 'color': [] },{ 'font': [] },{size: []}],
              [{ 'align': [] },{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
         
            ]
      };
    
      const formats = [
         'font', 'size','align','color',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'link', 'image', 'video'
      ];
    
      const handleTextChange = (value) => {
        setText(value);
      };
    


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddSource setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 
 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Settings</p>
    <div className='navigateTop'>

<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/settings">

<p className='bc1'>Settings</p></Link>
<img src={right}/>
<Link to="/settings/terms">

<p className='bc2'>Terms And Conditions</p>
</Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Edit Terms </button>
  </div>

<div className='editTermsContainer '>
   <div className='quillCard'>


<ReactQuill
   theme="snow"
   modules={modules}
   formats={formats}
   value={text}
   
   onChange={handleTextChange}
   />
   </div>



   <div style={{marginTop:"32px"}} className='quillCard'>

<ReactQuill
   theme="snow"
   modules={modules}
   formats={formats}
   value={text}
   
   onChange={handleTextChange}
   />

   </div>
 
   <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button   className='cancel'>     
           Cancel</button></div>



</div>


</div>
  </div>

   </div>
    </div>
  );
}

export default EditTerms;
