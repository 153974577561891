import '../styles/orders.css';
import settings from "../assets/settings.svg"
import list from "../assets/list2.svg"
import area from "../assets/area.svg"
import promo from "../assets/promo.svg"

import qr from "../assets/qr.svg"
import monitor from "../assets/monitor.svg"
import logoPlain from "../assets/logoPlain.png"
import coin from "../assets/coin.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import file from "../assets/file.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import Invoice from '../components/Invoice';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {RxDotFilled} from 'react-icons/rx';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {IoIosCloseCircleOutline} from 'react-icons/io';
import {BsCheck} from 'react-icons/bs';
import UserModal from '../components/UserModal';
import { useState } from 'react';
import EditBranchArea from '../components/EditBranchArea';
import AddBranch from '../components/AddBranch';
import BranchMap from '../components/BranchMap';
import BranchQr from '../components/BranchQr';
import MealStatus from '../components/MealStatus';
function BranchMenu() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [qrModal,setQrModal]=useState(false)
  const [editModal,setEditMOdal]=useState(false)
  const [map,setMap]=useState(false)
  const [mealModal,setMealModal]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
      {add?<AddBranch setModal={setAdd}/>:null}
      {mealModal?<MealStatus setModal={setMealModal}/>:null}
      {qrModal?<BranchQr setModal={setQrModal}/>:null}
      {editModal?<EditBranchArea setModal={setEditMOdal}/>:null}
      {map?<BranchMap setModal={setMap}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Branches</p>
<div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/branches">

<p className='bc1'>Branches</p></Link>

<img src={right}/>
<Link to="/branches">

<p className='bc1'>Branches List</p>
</Link>
<img src={right}/>
<Link to="/branches/menu">

<p className='bc2'>Rawabi branch menu list</p></Link>
</div>
    </div>
    <div className='orderDet'>

    <button onClick={()=>{setAdd(true)}} className='export'>
  
Add Branch   </button>
<button onClick={()=> setMap(true)} className='sync'>
    Map Branch
</button>
</div>

  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your branches list data</p>
<button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>
  </div>

  <div className={filter?'filtersMore':'filtersLess'}>
<div className='filtersContainer'>
  
<div  style={{margin:"0"}} className='flex-1' >
  <label> Categories</label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  <div className='flex-1' >
  <label> Products</label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
<div  className='flex-1' >
  <label> Sizes</label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
 
  <div className='flex-1' >
  <label> Additionals </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  <div className='flex-1' >
  <label>Platforms</label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  <div>

     
  </div>

  </div>

  <div style={{marginTop:"16px"}}>
<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>All </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  <div  className='flex-1' >
  <label>Statues </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  <div  className='flex-1' >

  </div>
  
 

  <div style={{marginLeft:"8px"}} className='flex-2 alignEnd'>
  
  <button className='clear'><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  
  
  </div>
  </div>

  </div>
  <div style={{marginTop:"82px"}}  className='flexAlign'>
  <p className='tableTitle'>Save your changes</p>
<button className='apply' style={{backgroundColor:"#F73782",width:"120px"}}>Save</button>
  </div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th className='alignCheck'>
    <div className='containCheck'>
    <div> 
<input type="checkbox"  id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>Meal ID</th>

    <th>Category</th>
    <th>Product </th>
    <th >Size </th>
    <th >Additional  </th>
    <th >Code </th>
    <th >Price </th>
    <th >Available Quantity </th>

    <th style={{textAlign:"center"}}>Meal Status </th>
   

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}} className='alignCheck' ><div className='containCheck'>
    <div> 
<input type="checkbox" id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div> 
    <button >
    3066
    </button>
    </td>

    <td >Spanish Meals</td>
    <td > Spanish</td>
    <td >Lotus </td>
  <td >Lotus</td>
  <td >3423</td>
  <td >12</td>
  <td ><input style={{minWidth:"188px"}} type="number"/></td>

<td className='centerData'>
<button onClick={()=>setMealModal(true)} style={{border:"none"}}>
  <img src={settings}/>
</button> </td>

  
    </tr>
    <tr>
<td style={{color:"#101828"}} className='alignCheck' ><div className='containCheck'>
    <div> 
<input type="checkbox" id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div> 
    <button >
    3066
    </button>
    </td>

    <td >Spanish Meals</td>
    <td > Spanish</td>
    <td >Lotus </td>
  <td >Lotus</td>
  <td >3423</td>
  <td >12</td>
  <td ><input style={{minWidth:"188px"}} type="number"/></td>

<td className='centerData'>
<button onClick={()=>setMealModal(true)} style={{border:"none"}}>
  <img src={settings}/>
</button> </td>

  
    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default BranchMenu;
