import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { Link } from 'react-router-dom';
import {useFormik} from "formik";
import * as yup from "yup"
import AddPromo from '../components/AddPromo';
import { useState } from 'react';
function EditPromo() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])


  let numRegEx =/^[0-9]+$/;

  const Schema= yup.object().shape( 
   {
      code: yup.string().required("Code is required"),
      type: yup.string().required("Type is required"),
      amount: yup.string().required("Amount is required").matches(numRegEx,{message:("Amount should contain digits only")}),
      uses: yup.string().required("Number of uses is required").matches(numRegEx,{message:("Amount should contain digits only")}),
      from: yup.string().required("Time from is required"),
              to: yup.string().required("Time to is required"),
              limit: yup.string().required("Limit is required").matches(numRegEx,{message:("Limit should contain digits only")}),

    }
      )
  
  
      const formik = useFormik({
        initialValues:{
          code: '',
          type: '',
          amount: '',
          uses:'',
          from:'',
          to:'',
          limit:''
        },
        validationSchema: Schema,
        onSubmit,
        });
  
  
  
        function onSubmit(value){
        }
  
  


  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddPromo setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Promo Codes</p>
    <div className='navigateTop'>
<Link to="/dashboard">

  
<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/promotions">

<p className='bc1'>Promotions</p></Link>
<img src={right}/>
<Link to="/promotions">

<p className='bc2'>Promo Codes List</p></Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Promotion </button>
  </div>
 

<div style={{height:"80%"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Promo Code</p>
<div className='editRow'>

<div>
    <label>Code:</label>
    <input placeholder='Enter name' value={formik.values.code} id="code" name="code" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.code && formik.touched.code?formik.errors.code:null}</span>
</div>
<div style={{margin:"0 25px"}}>
<label>Type:</label>
<div className='select-container'>
  <select value={formik.values.type} id="type" name="type" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.type && formik.touched.type ?"invalid":null}>
      <option>Cash</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span className='errSpan'>{formik.errors.type && formik.touched.type?formik.errors.type:null}</span>


</div>

<div>
    <label>Amount:</label>
    <input  type="number" placeholder='Enter amount' value={formik.values.amount} id="amount" name="amount" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.amount && formik.touched.amount ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.amount && formik.touched.amount?formik.errors.amount:null}</span>
</div>

</div>

<div>
<div className='editRow'>

<div>

<label>Number of uses:</label>
<input  type="number" placeholder='Enter number' value={formik.values.uses} id="uses" name="uses" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.uses && formik.touched.uses ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.uses && formik.touched.uses?formik.errors.uses:null}</span>

</div>

<div style={{margin:"0 25px"}}>
<label>From</label>
<div className='dateDiv'>
    <input type="date" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span className='errSpan'>{formik.errors.from && formik.touched.from?formik.errors.from:null}</span>

</div>

<div >
<label>Expiry Date</label>
<div className='dateDiv'>
    <input type="date" value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span  className='errSpan'>{formik.errors.to && formik.touched.to?formik.errors.to:null}</span>

</div>
</div>
</div>
<div>


<div className='editRow'>
<div >
<label>Limit</label>
<input  type="number" placeholder='Enter number' value={formik.values.limit} id="limit" name="limit" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.limit && formik.touched.limit ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.limit && formik.touched.limit?formik.errors.limit:null}</span>

</div>

<div style={{margin:"0 25px"}}>
  
</div>
<div>
  
</div>
</div>
</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditPromo;
