import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

import AddExtra from '../components/AddExtra';
import { useState } from 'react';
function EditExtra() {
    const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddExtra setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 
 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Products</p>
    <div className='navigateTop'>
  
  <Link to="/dashboard">
    <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>
  
    </Link>
    <img src={right}/>
    <Link to="/products">
  
  <p className='bc1'>Products</p></Link>
  <img src={right}/>
  <Link to="/products/extras-list">
  
  <p className='bc2'>Extras List</p></Link>
  
  </div>
    </div>

    <div className='orderDet'>

    <button onClick={()=>setAdd(true)} className='export'>
Add Extra   </button>


</div>

  </div>

<div className='editContainer editAgent'>
    <div>


<p className='editTitle'>Edit Extra Data</p>
<div className='editRow'>


<div>
    <label>Name Ar</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
    <label>Name En</label>
    <input  type="text" placeholder='test'/>
</div>
<div>

</div>

</div>
</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditExtra;
