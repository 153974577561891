import '../styles/terms.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';

import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

import { useState } from 'react';
import AddSource from '../components/AddSource';
function Terms() {
    const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 
 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Settings</p>
<div className='navigateTop'>

<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/settings">

<p className='bc1'>Settings</p></Link>
<img src={right}/>
<Link to="/settings/terms">

<p className='bc2'>Terms And Conditions</p>
</Link>
</div>
    </div>
    <Link to="/settings/edit-terms">
    <button className='export'>

Edit Terms </button>
    </Link>
 
  </div>

<div className='termsContainer '>
   <div className='termsTop'>
     <p className='termsTitle'>Terms & Conditions</p>
     <p className='termsDate'>Updated On : 12-32-2022</p>
</div>
<div className='termsBody'>

<div className='termsCard'>
  <div className='cardTop'>
<p>WELCOME TO THE Alromansiah ADMIN TEMPLATE. PLEASE REVIEW THE FOLLOWING BASIC TERMS & CONDITIONS. PLEASE NOTE THAT YOUR USE OF OUR TEMPLATE HAS SOME IMPORTANT TERMS TO FOLLOW AND ADHERE TO.</p>
    </div>
    <p className='decrip'>Description English:</p>
    <p className='dexripTxt'>A overview of the project, goals and outcomes.</p>
    test

    <p style={{marginTop:"32px"}} className='decrip'>Description Arabic:</p>
    <p className='dexripTxt'>A overview of the project, goals and outcomes.</p>
    test

  </div>


</div>
</div>


</div>
  </div>

   </div>
    </div>
  );
}

export default Terms;
