import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import ReactQuill from 'react-quill';

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {BiChevronDown} from 'react-icons/bi';
import {RiHome6Line}  from 'react-icons/ri';

import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill,BsCheck}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import AddProduct from '../components/AddProduct';
import { useState,useRef,useEffect } from 'react';
import AddPost from '../components/AddPost';
import AddNewsPost from '../components/AddNewsPost';
function EditNewsPost() {
    const [add,setAdd]=useState(false)
    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()
 


    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };

  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddNewsPost setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Blog</p>
    <div className='navigateTop'>
      
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/blog">

<p className='bc1'>Blog</p></Link>
<img src={right}/>
<Link to="/blog/newsPosts">

<p className='bc2'>Customer Service News List</p>
</Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Post </button>
  </div>
 

<div style={{height:"unset"}} className='editContainer editAgent editPost'>
    <div>

<p className='editTitle'>Edit Post</p>


<div className='editRow'>

<div>
    <label>Description Ar :</label>
    <textarea type="text" placeholder='test'/>
    <p className='chars'>275 characters left</p>

</div>


</div>

<div className='editRow'>

<div>
    <label>Description En :</label>
    <textarea type="text" placeholder='test'/>
    <p className='chars'>275 characters left</p>

</div>


</div>

<div className='editRow'>




<div>
    <label>Active</label>
    <div className='select-container'>
<select>
    <option>Yes</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>
<div>
    </div>
    <div>
    </div>
</div>

</div>

<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditNewsPost;
