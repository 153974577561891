import '../styles/users.css';
import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import { Link } from 'react-router-dom';
import {useFormik} from "formik";
import * as yup from "yup"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import DropDown from '../components/DropDown';
import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import { useState ,useRef} from 'react';
import AddCustomerService from '../components/AddCustomerService';
function EditAgent() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])

  const nameRef=useRef()
  const phoneRef=useRef()
  const userNameRef=useRef()
  const codeRef=useRef()
  const passwordRef=useRef()
  const typeRef=useRef()
  const statusRef=useRef()

  
  let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
  let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
  let numRegEx =/^[0-9]+$/;
      const Schema= yup.object().shape({
          name: yup.string().required("Agent name is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Agent name should contain letters only")}),
          phone: yup.string().required("Phone number is required").matches(phoneRegEx,"Enter a valid phone number"),
          userName: yup.string().required("Username is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Username name should contain letters only")}),
          code: yup.string().required("Employee code is required").matches(numRegEx,"Employee code must contain digits only"),
          type: yup.string().required("Employee type  is required"),
          status: yup.string().required("Activation status is required"),
          password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),
     
          })
  


          const formik = useFormik({
            initialValues:{
            name: '',
            phone: '',
            userName: '',
            code: '',
            type: '',
            password: '',
  
             
            },
            validationSchema: Schema,
            onSubmit,
            });
  
  
  
            function onSubmit(value){
            }

  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddCustomerService setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
  <div >
    <p className='screenTitle'>Admins</p>
<div className='navigateTop'>
  
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
<Link to="/admins/admins-list">

<p className='bc1'>Admin</p></Link>
<img src={right}/>
<Link to="/admins/customer-service">

<p className='bc2'>Customer Service List</p>
</Link></div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Customer Service </button>
  </div>
 

<div style={{height:"70%"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Customer Agent</p>
<div className='editRow'>


<div>
    <label>Name</label>
    <input placeholder='Enter name' value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.name && formik.touched.name?formik.errors.name:null}</span>

</div>
<div style={{margin:"0 25px"}}>
    <label>User Name</label>
    <input placeholder='Enter name' value={formik.values.userName} id="userName" name="userName"  ref={userNameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.userName && formik.touched.userName ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.userName && formik.touched.userName?formik.errors.userName:null}</span>
</div>
<div>
    <label>Employee Code</label>
    <input placeholder='Enter code' value={formik.values.code} id="code" name="code"  ref={codeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.code && formik.touched.code?formik.errors.code:null}</span>
</div>

</div>

<div>
<div className='editRow'>


<div>
    <label>Password</label>
    <input placeholder='Enter name'  value={formik.values.password} id="password" name="password"  ref={passwordRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password?formik.errors.password:null}</span>

</div>


<div style={{margin:"0 25px"}}>
<label>Account type</label>
<div className='select-container'>
  <select value={formik.values.type} id="type" name="type"  ref={typeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.type && formik.touched.type ?"invalid":null}>
  <option value={null}>10</option>

      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span className='errSpan'>{formik.errors.type && formik.touched.type?formik.errors.type:null}</span>


</div>
<div>
<label>Activation Status</label>

<div className='select-container'>
  <select value={formik.values.status} id="status" name="status"  ref={statusRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.status && formik.touched.status ?"invalid":null}>
  <option value={null}>10</option>

      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span className='errSpan'>{formik.errors.status && formik.touched.status?formik.errors.status:null}</span>

</div>

</div>
</div>
<div>


<div className='editRow'>
<div >
<DropDown label="Branches:"/>
</div>

<div style={{margin:"0 25px"}}>
    <DropDown label="Sources:"/>

</div>
<div >
    <DropDown label="Types:"/>

</div>

</div>
<div className='editRow'>
<div >
<DropDown label="Roles:"/>
</div>

<div style={{margin:"0 25px"}}>

</div>
<div >

</div>

</div>
</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditAgent;
