import logo from "../assets/logo.png"

function PasswordContainer({children,icon,title,text,style}) {
  return (
    <div className='emailContainer'>
    <div className='container '>
    <form className='emailForm'>
    <div className='loginLogo ' style={{ backgroundImage: `url(${logo})` }}>
      </div>
      <div className='passIcn' style={style}>
    
   {icon}
    
      </div>
      <p id="forgot">{title}</p>
      <p id="forgotTxt">{text}</p>

      {children}
   
    </form>
    </div>
        </div>
  );
}

export default PasswordContainer;
