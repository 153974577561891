   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import del from "../assets/delete.svg"

function DeleteModal(props) {

const history= useNavigate();



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="DelModal" style={{position:"absolute",width:"100%"}}>
     
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
                 <button className='closeModal' onClick={()=>props.setModal(false)}>
                 <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M24.3 9.19698L22.604 7.50098L15.9 14.205L9.196 7.50098L7.5 9.19698L14.204 15.901L7.5 22.605L9.196 24.301L15.9 17.597L22.604 24.301L24.3 22.605L17.596 15.901L24.3 9.19698Z" fill="#B8C4CE"/>
</svg>
                 </button>
<img src={del}/>

<p>{props.text}</p>
           <div className='modalBtns'>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button>
           <button className='confirm' >Delete</button>

           </div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default DeleteModal;
 