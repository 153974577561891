import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {BiChevronDown} from 'react-icons/bi';
import {RiHome6Line}  from 'react-icons/ri';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"
import mapLogo from "../assets/mapLogo.png"
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill,BsCheck}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import BranchMap from '../components/BranchMap';

import AddBranch from '../components/AddBranch';
import { useState,useRef,useEffect } from 'react';
function EditBranch() {
    const [add,setAdd]=useState(false)
    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()
    const [map,setMap]=useState(false)
    const libraries = ['places'];

    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries:libraries
    })
    const [position,setPostion]=useState(

        {
      lat:parseFloat(43.4),
      lng:parseFloat(43.4)
      
      
        }
      )  


      function onMarkerDragEnd(evt){

        var newLat = evt.latLng.lat();
        var newLng = evt.latLng.lng();
    
       setPostion({
      
        lat:newLat,
        lng:newLng
       })
      
      };

    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };

  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddBranch setModal={setAdd}/>:null}
 {map?<BranchMap setModal={setMap}/>:null}

   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>  

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Branches</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/branches">

<p className='bc1'>Branches</p></Link>

<img src={right}/>
<Link to="/branches">

<p className='bc2'>Branches List</p>
</Link>
</div>
    </div>
    <div className='orderDet'>

    <button onClick={()=>{setAdd(true)}} className='export'>
  
Add Branch   </button>
<button onClick={()=> setMap(true)} className='sync'>
    Map Branch
</button>
</div>

  </div>

<div style={{height:"unset"}} className='editContainer editAgent'>
    <div>

<p className='editTitle'>Edit Branch Data</p>
<div className='editRow'>


<div>
    <label>Name :</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
    <label>Name En :</label>
    <input  type="text" placeholder='test'/>
</div>
<div>
    <label>Branch Code :</label>
    <input  type="text" placeholder='test'/>

</div>

</div>

<div>
<div className='editRow'>


<div>
    <label>Select City :</label>
    <div className='select-container'>
<select>
    <option>Sweet</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div style={{margin:"0 25px"}}>
    <label>Select District :</label>
    <div className='select-container'>
<select>
    <option>Sweet</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div>
    <label>Branch Code :</label>
  <input/></div>

</div>
</div>

<div className='editRow'>

<div >
<label>Image :</label>
<div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>
<div className='countryImg'  style={{ backgroundImage: `url(${saudi})` }}>

    </div>

<div></div></div>

</div>

<div className='editRow'>

<div >
<label style={{marginTop:"24px",marginBottom:"12px"}}>Map :</label>

{isLoaded?
<GoogleMap zoom={8} 
    center={{ lat: position.lat, lng: position.lng}}

         mapContainerClassName="editMap"

    options={{
        mapTypeControl: false
    }}
    > 
<MarkerF
icon={mapLogo}
draggable={true}
position={{ lat: position.lat, lng: position.lng}}

onDragEnd={(e) =>onMarkerDragEnd(e)}

/>

</GoogleMap>:null}
</div></div>
</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditBranch;
