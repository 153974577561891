    

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';
import {GrFormClose} from 'react-icons/gr';
import {useFormik} from "formik";
import * as yup from "yup"
function AddArea(props) {


let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let numRegEx =/^[0-9]+$/;

    const Schema= yup.object().shape({
        nameAr: yup.string().required("Area name is required").matches(nameRegEx,{message:("Area name should contain letters only")}),
        nameEn: yup.string().required("Area name is required").matches(nameRegEx,{message:("Area name should contain letters only")}),
        ordinary: yup.string().required("Delivery price is required").matches(numRegEx,"Delivery price should contain digits only"),
        fast:yup.string().required("Delivery price is required").matches(numRegEx,"Delivery price should contain digits only"),
        min: yup.string().required("Minimum order is required").matches(numRegEx,"Minimum order should contain digits only"),
        time:yup.string().required("Delivery time is required").matches(numRegEx,"Delivery time should contain digits only"),
        distance: yup.string().required("Distance is required").matches(numRegEx,"Distance should contain digits only"),
        })


        const formik = useFormik({
          initialValues:{
            nameAr: '',
            nameEn: '',
            ordinary: '',
            fast: '',
            min: '',
            min: '',
            distance: '',

           
          },
          validationSchema: Schema,
          onSubmit,
          });



          function onSubmit(value){
          }

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addArea" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Area</p>
<div className='maxHeight'>

<label>Name Ar:</label>
<input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>

    <label>Name En:</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>

    <label>Ordinary delivery price :</label>
    <input placeholder='Enter name' value={formik.values.ordinary} id="ordinary" name="ordinary" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinary && formik.touched.ordinary ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.ordinary && formik.touched.ordinary?formik.errors.ordinary:null}</span>

    <label>Fast delivery price :</label>
    <input placeholder='Enter name' value={formik.values.fast} id="fast" name="fast" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fast && formik.touched.fast ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.fast && formik.touched.fast?formik.errors.fast:null}</span>

    <label>Minimum order:</label>
    <input placeholder='Enter name' value={formik.values.min} id="min" name="min" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.min && formik.touched.min ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.min && formik.touched.min?formik.errors.min:null}</span>

    <label>Delivery time :</label>
    <input placeholder='Enter name' value={formik.values.time} id="time" name="time" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.time && formik.touched.time ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.time && formik.touched.time?formik.errors.time:null}</span>

  
    <label>Distance :</label>
    <input placeholder='Enter name' value={formik.values.distance} id="distance" name="distance" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.distance && formik.touched.distance ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.distance && formik.touched.distance?formik.errors.distance:null}</span>


  
           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddArea;
 