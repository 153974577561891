import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import pin from "../assets/pin.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import { Link } from 'react-router-dom';
import menu from "../assets/menu2.svg"
import gift from "../assets/gift.svg"
import location from "../assets/location.svg"
import wallet from "../assets/wallet2.svg"

import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import {BsTelephone,BsDot} from 'react-icons/bs';

import {RxDotFilled} from 'react-icons/rx';

import {HiOutlineMail} from 'react-icons/hi';

import {IoIosCloseCircleOutline} from 'react-icons/io';
import AddWallet from '../components/AddWallet';
import { useState } from 'react';
function SingleUser() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)

  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddWallet setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>User Profile</p>
<div className='navigateTop'>
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/users/users-list">
  <p className='bc1'>Users</p>

  </Link>
<img src={right}/>
<Link to="/users/users-list">

<p className='bc2'>Users List</p></Link>
<img src={right}/>
<Link to="/users/users-list/5">

<p className='bc2'>User Profile</p>
</Link>
</div>
    </div>
    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
Export Positive Balance </button>
  </div>
 
<div className='tableContainer'>
    <div className='profile-container'>
<div className='profileCard'>
<div className='profileId'>
<p>#1232</p>
</div>
<div className='profileData'>
<div className='profileImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <p className='userName'>Mariam Aly</p>
  <p className='joinDate'>June 12/12/22</p>
  <div className='profileContact'>
<div>

<img src={pin}/>
  <p>Country: <span>Saudi Arabia</span></p>
</div>
<div>
<HiOutlineMail color='rgba(245, 10, 102, 1)' size={15.7}/>
  <p>Email: <span>test@test.com</span></p>
</div>
<div>
<BsTelephone  color='rgba(245, 10, 102, 1)' size={15.7}/>
  <p>Phone: <span>01382938293</span></p>
</div>
  </div>
  <p className='usrAddress'><span>Address:</span> Dorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad</p>

<p className='calss'>Classification</p>
<div className='calssDiv'>
<p># موظف <RxDotFilled /></p>
<p># موظف <RxDotFilled /></p>
<p># موظف <RxDotFilled /></p>
<p># موظف <RxDotFilled /></p>


</div>
</div>


<div>


</div>
</div>
    </div>
    <div className='singleUserTableContainer'>

<div className='tableTop'>
  <div className='tableChoice'>
<div className='activeChoice'>
<p>Wallet</p>
<img src={wallet}/>
<p className='tableCount'>2,3212</p>
</div>
<div style={{margin:"0 25px"}}>
<p>Awafi</p>
<img src={gift}/>
<p className='tableCount'>2,3212</p>
</div>
<div style={{margin:"0 25px"}}>
<p>Orders</p>
<img src={menu}/>
<p className='tableCount'>2,3212</p>
</div>
<div>
<p>Address</p>
<img src={location}/>
<p className='tableCount'>2,3212</p>
</div>

  </div>
  <div className='flexAlign' style={{marginBottom:"25px"}}>
  <p className='choiceTitle'>Wallet List</p>
<div>
<button className='titleAdd' onClick={()=>setAdd(true)}>Add Wallet</button>
<button className='titleExp'>Export</button>
</div>


  </div> 
  <div className='filtersContainer'>
  
  <div className='flex-1' >
  <label>Show </label>
  <div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  <div style={{margin:"0 80px"}} className='flex-1'>
  <label>All </label>
  <div className='select-container'>
  <select>
      <option>in</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  <div className='flex-1'>
    <label>Search </label>
    <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input />
    </div>
 
  </div>
  
    </div>

</div>


<div className='tableUsers'>

<table>
  <thead>
  <tr>
    <th>ID Number</th>
    <th>Name</th>
    <th>Phone</th>
    <th>Email</th>
    <th>Verification</th>
    <th>Country</th>
    <th>CreateAt</th>

  </tr>
  </thead>

  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  className='tableName'>
<div   className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  
  Mariam Aly


</td>
<td>0523452799</td>
<td  className='tableName'>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 


</td>
<td>732938</td>
<td>Egypt</td>
<td>2023-2-3</td>

    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  className='tableName'>
<div   className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  
  Mariam Aly


</td>
<td>0523452799</td>
<td  className='tableName'>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 


</td>
<td>732938</td>
<td>Egypt</td>
<td>2023-2-3</td>

    </tr>
  </tbody>
</table>
</div> </div> </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default SingleUser;
