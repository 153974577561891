
    

 
import React, {useEffect, useState,useRef} from 'react';
import { BeatLoader } from 'react-spinners';
import {BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';

function AddMeal(props) {



    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCateg" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Meal</p>
<div className='maxHeight'>

<label> Product :</label>
    <div className='select-container'>
  <select>
      <option>No</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <label> Size :</label>
    <div className='select-container'>
  <select>
      <option>No</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <label> Additional :</label>
    <div className='select-container'>
  <select>
      <option>No</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>


    <label>Default Aggregators Price SAR :</label>
    <input placeholder='Enter name'/>

    <label>Code :</label>
    <input placeholder='Enter name'/>

    <label>Calories :</label>
    <input placeholder='Enter name'/>

   
  
           <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div></div>
          
          </div>
        </div>
    

    );
}

export default AddMeal;
 