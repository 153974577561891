import '../styles/users.css';
import logoPlain from "../assets/logoPlain.png"
import sack from "../assets/sack.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import chat from "../assets/chatP.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';

import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {LuRectangleHorizontal} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import AddAdmin from '../components/AddAdmin';
import {IoIosCloseCircleOutline} from 'react-icons/io';

import {RiDeleteBinLine} from 'react-icons/ri';

import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';
function Reports() {
  const [filter,setFilter]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
   
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Products</p>
<div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/products">

<p className='bc1'>Products</p></Link>
<img src={right}/>
<Link to="/products/meals-list">

<p className='bc1'>Meals List</p></Link>

<img src={right}/>
<Link to="/products/reports">

<p className='bc2'>Reports</p></Link>
</div>
    </div>
    <button className='export'>

Export</button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your reports</p>
<button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>
  </div>

  <div className={filter?'filtersMore':'filtersLess'}>

<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>Branch </label>
  <input />

  </div>
  
  <div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  
  
  
  <div style={{marginLeft:"8px"}} className='flex-2 alignEnd'>
  
  <button className='clear'><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  
  
  </div>

 


  </div>

</div>
<div className='tableUsers adminTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>Products</th>
    <th>Size</th>
    <th>Additional</th>
    <th>Code</th>
    <th>Price</th>
    <th>Calories</th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Mandi Goat</td>
<td  >One</td>
<td>Pepsi</td>
<td>422</td>
<td>100</td>
<td>3298</td>
    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >Mandi Goat</td>
<td  >One</td>
<td>Pepsi</td>
<td>422</td>
<td>100</td>
<td>3298</td>
    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Reports;
