import '../styles/login.css';
import logo from "../assets/logo.png"
import { BiArrowBack } from 'react-icons/bi';
import {BsCheckCircle} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import PasswordContainer from '../components/PasswordContainer';
function PasswordReset() {
  return (
 <PasswordContainer
 title="Password reset"
 text="Your password has been successfully reset. Click below to log in magically."
 icon={ <BsCheckCircle color='#039855' size={23}/>}
 style={{borderColor:"#ECFDF3",background:"#D1FADF"}}>
  
  <Link to="/">
  <button  id="loginBtn">Continue</button></Link>
  <Link to="/">

    <button className='bck' ><BiArrowBack size={18} color='#475467'/> Back to log in</button>
 </Link>
    </PasswordContainer>
  );
}

export default PasswordReset;
