import '../styles/dashboard.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import line from "../assets/line.svg"
import box from "../assets/box.svg"
import logoPlain from "../assets/logoPlain.png"
import sack from "../assets/sack.svg"
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';

import {LuRectangleHorizontal} from 'react-icons/lu';

import {BsArrowUpShort} from 'react-icons/bs';

import {FiLogOut}  from 'react-icons/fi';

function Dashboard() {
  const data = [
    {
      name: 'Jan',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Feb',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Mar',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Apr',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Mai',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Jun',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    
  ];

  return (
    <div className='dashboardContainer'>
 <Navbar/>
   <div className='dashobard'>
<div className='analysisContainer'>
  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='welcome'>Welcome Mariam,</p>
    <p className='welcomeTxt'>Stay up to date with your store current status</p>

    </div>
    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
      Export</button>
  </div>
  <div className='dashNavigate'>
<div className='dashType'>
  <button className='activeType'>Users</button>
  <button>Orders</button>
  <button>Registered</button>
  <button>Branches</button>
  <button>Discounts</button>
  <button>Prices</button>
  <button>Products</button>

</div>
<div className='dataStatus'>
<button className='activeStatus'>All</button>
<button  className='midStatus'>Today</button>

<button>In Progress</button>

</div>

  </div>

  <div className='chartsDiv'>
  <div class="container px-4 text-center">
  <div class="row gy-5">
    <div class="col-4 ">
     <div class="p-3 cardShadow">
       <div className='priceCount'>
         <div className='cashLogo'>
         <img src={sack}/>
         </div>
           <div>
<p className='totalTitle'>Total Done Price</p>
<p className='totalNum'>2,3234234 <br/> R.s</p>
           </div>
        
       </div>
       <p className='upPercentage'><span><BsArrowUpShort size={25} color='#12B76A'/> 20%</span> From last month (R.s 100,185)</p>
       </div>
    </div>
    <div class="col-4 ">
     <div class="p-3 cardShadow">
       <div className='priceCount'>
         <div className='cashLogo'>
         <img src={sack}/>
         </div>
           <div>
<p className='totalTitle'>Total Done Price</p>
<p className='totalNum'>2,3234234 <br/> R.s</p>
           </div>
        
       </div>
       <p className='upPercentage'><span><BsArrowUpShort size={25} color='#12B76A'/> 20%</span> From last month (R.s 100,185)</p>
       </div>
    </div>
    <div class="col-4 ">
     <div class="p-3 cardShadow">
       <div className='priceCount'>
         <div className='cashLogo'>
         <img src={sack}/>
         </div>
           <div>
<p className='totalTitle'>Total Done Price</p>
<p className='totalNum'>2,3234234 <br/> R.s</p>
           </div>
        
       </div>
       <p className='upPercentage'><span><BsArrowUpShort size={25} color='#12B76A'/> 20%</span> From last month (R.s 100,185)</p>
       </div>
    </div>
    <div class="col-8">
     <div class="cardShadow">
       <div className='p-3'>
     <div className='chartTitle flexBetween'>
<div>
<p className='graphTitle'>Order Graphs <span><BsArrowUpShort size={25} color='#12B76A'/> 20%</span></p>
<p className='graphTotal'>4.3829,37</p>
</div>
<select>
<option>All Orders </option>
</select>
     </div></div>
     <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
     <LineChart
       width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray=""  stroke="#f5f5f5" strokeWidth={2}/>
      <XAxis dataKey="name" axisLine={false} fontSize={12} />
      <YAxis axisLine={false} fontSize={12}/>
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#165BAA"
        activeDot={{ r: 8 }}
        strokeWidth={2} 
        dot={(props) => (
          <circle
            strokeWidth="2"
            fill="#165BAA"
            r="3"
            cx={props.cx}
            cy={props.cy}
          />
        )}
      />
       <Line
        type="monotone"
        dataKey="amt"
        stroke="#F765A3"
        activeDot={{ r: 8 }}
        strokeWidth={2} 
        dot={(props) => (
          <circle
            strokeWidth="2"
            fill="#F765A3"
            r="3"
            cx={props.cx}
            cy={props.cy}
          />
        )}
      />
      <Line type="monotone"  strokeWidth={2}  activeDot={{ r: 8 }} dataKey="uv" stroke="#A155B9" 
       dot={(props) => (
        <circle
          strokeWidth="2"
          fill="#A155B9"
          r="3"
          cx={props.cx}
          cy={props.cy}
        />
      )}/>
    </LineChart></ResponsiveContainer></div>
    <div className='p-3 lineColors'>
      <p><LuRectangleHorizontal style={{fill:"#F765A3"}} color="#F765A3"/> Product 1</p>
      <p> <LuRectangleHorizontal style={{fill:"#165BAA"}} color="#165BAA"/> Product 2</p>
      <p> <LuRectangleHorizontal style={{fill:"#A155B9"}} color="#A155B9"/> Product 3</p>
      </div> </div>
    </div>
    <div className="col-4 pieCol">
     <div className="cardShadow">
       <div className="p-3 pieTop">
         <p className='pieTitle'>Vendors monitored</p>
         <p className='pieTxt'>You’re using 80% of available spots.</p>
       </div>
       <div className="p-3">
     <PieChart
     animate={true}
     lineWidth={50} 
     radius={30}
     center={[50,30]}
     viewBoxSize={[100,60]}
  data={[
    { title: 'One', value: 10, color: '#F765A3' },
    { title: 'Two', value: 15, color: '#165BAA' },
    { title: 'Three', value: 20, color: '#A155B9' },
  ]}
/>
<p className='pieBottom'>You have used 80% of your available spots. Upgrade plan to monitor more vendors.</p>

</div>

  </div>
    </div>
    <div class="col-8">
     <div class="cardShadow">
       <div className='p-3'>
     <div className='chartTitle flexBetween'>
<div>
<p className='graphTitle'>Order Graphs <span><BsArrowUpShort size={25} color='#12B76A'/> 20%</span></p>
<p className='graphTotal'>4.3829,37</p>
</div>
<select>
<option>All Orders </option>
</select>
     </div></div>
     <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
     <LineChart
       width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid   strokeDasharray="" stroke="#f5f5f5" strokeWidth={2}/>
      <XAxis dataKey="name" axisLine={false} fontSize={12} />
      <YAxis axisLine={false} fontSize={12}/>
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#165BAA"
        activeDot={{ r: 8 }}
        strokeWidth={2} 
        dot={(props) => (
          <circle
            strokeWidth="2"
            fill="#165BAA"
            r="3"
            cx={props.cx}
            cy={props.cy}
          />
        )}
      />
       <Line
        type="monotone"
        dataKey="amt"
        stroke="#F765A3"
        activeDot={{ r: 8 }}
        strokeWidth={2} 
        dot={(props) => (
          <circle
            strokeWidth="2"
            fill="#F765A3"
            r="3"
            cx={props.cx}
            cy={props.cy}
          />
        )}
      />
      <Line type="monotone"  strokeWidth={2}  activeDot={{ r: 8 }} dataKey="uv" stroke="#A155B9" 
       dot={(props) => (
        <circle
          strokeWidth="2"
          fill="#A155B9"
          r="3"
          cx={props.cx}
          cy={props.cy}
        />
      )}/>
      
    </LineChart></ResponsiveContainer></div>
    <div className='p-3 lineColors'>
      <p><LuRectangleHorizontal style={{fill:"#F765A3"}} color="#F765A3"/> Product 1</p>
      <p> <LuRectangleHorizontal style={{fill:"#165BAA"}} color="#165BAA"/> Product 2</p>
      <p> <LuRectangleHorizontal style={{fill:"#A155B9"}} color="#A155B9"/> Product 3</p>
      </div> </div>
    </div>
    <div className="col-4 pieCol">
     <div className="cardShadow">
       <div className="p-3 pieTop">
         <p className='pieTitle'>Vendors monitored</p>
         <p className='pieTxt'>You’re using 80% of available spots.</p>
       </div>
       <div className="paddingRatting">
       <Rating initialValue={5} />
<p className='ratings'>(2000)</p>
<button className='RateBtn'>View Report</button>
</div>

  </div>
    </div>
  </div>
</div>
  </div>
  </div>
   </div>
    </div>
  );
}

export default Dashboard;
