   

 
import React, {useEffect,useRef, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"
import mapLogo from "../assets/mapLogo.png"
import OrdinaryNo from './OrdinaryNo';
import * as yup from "yup"
import {useFormik} from "formik";

function AddBranch(props) {
    const libraries = ['places'];

    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries:libraries
    })
    const markerRef=useRef()

    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [ordinary,setOrdinary]=useState(true)

    const [preview,setPreview]=useState()

const [position,setPostion]=useState(

    {
  lat:parseFloat(43.4),
  lng:parseFloat(43.4)
  
  
    }
  )  
  
let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let decimalRegx= /^\d*\.?\d*$/
let numRegEx =/^[0-9]+$/;

    const Schema= yup.object().shape({
        delivery: yup.string().required("Delivery status is required"),
        fast: yup.string().required("Delivery status is required"),
        fastPrice: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
        fastOut: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
        ordinary: yup.string().required("Delivery status is required"),
        ordinaryPrice: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
        ordinaryOut: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
        minIn: yup.string().required("Minimum price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
        minOut: yup.string().required("Minimum price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
        branchPickup: yup.string().required("Branch option is required"),
        carOPtion: yup.string().required("Car option is required"), //depends on top
        nameAr: yup.string().required("Branch name is required").matches(nameRegEx,{message:("Branch name should contain letters only")}),
        nameEn: yup.string().required("Branch name is required").matches(nameRegEx,{message:("Branch name should contain letters only")}),
        code:yup.string().required("Branch code is required").matches(numRegEx,{message:("Code must contain digits only")}),
        phone: yup.string().required("Phone number is required").matches(phoneRegEx,"Enter a valid phone number"),
        from: yup.string().required("Time from  is required"),
        to:yup.string().required("Time to required"),
        country: yup.string().required("Country is required"),
        city: yup.string().required("City is required"),
        district: yup.string().required("District is required"),

        cash:yup.string().required("Status is required"),
        wallet:yup.string().required("Status is required"),
        apple:yup.string().required("Status is required"),
        urway:yup.string().required("Status is required"),

        })


        const formik = useFormik({
          initialValues:{
            delivery: '',
            fast:'',
            fastPrice:'',   //depends on top
            fastOut: '', //depends on top
            ordinary: '',
            ordinaryPrice:'',   //depends on top
            ordinaryOut:'',   //depends on top
            minIn:'', //depends on top
            minOut: '', //depends on top
            branchPickup:'',
            carOPtion:'', //depends on top
            nameAr:'',
            nameEn:'',
            code:'',
            phone: '',
            from: '',
            to:'',
            country: '',
            city: '',
            district:'',
            cash:'',
            wallet: '',
            applez: '',
            urway:'',

           
          },
          validationSchema: Schema,
          onSubmit,
          });

console.log(formik)

          function onSubmit(value){
          }


  useEffect(()=>{
    window.scrollTo(20000, 0);
  },[])

  



  function onMarkerDragEnd(evt){

    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();

   setPostion({
  
    lat:newLat,
    lng:newLng
   })
  
  };

  useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
 
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
      }
    
      };



    return ( 
      
 
 
        <div  className="AddModal addBranch maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"63vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Branch</p>
<div className='maxHeight'>
 
<div className='container-fluid'>
<div className='row'>



    {ordinary?
    <>
<div className='col-6'>

<label>Delivery :</label>

<div className='select-container'>
  <select  value={formik.values.delivery} id="delivery" name="delivery" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.delivery && formik.touched.delivery ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.delivery && formik.touched.delivery?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.delivery && formik.touched.delivery?formik.errors.delivery:null}</span>



  <label>Fast Delivery Price :</label>
  <input placeholder='Enter price'  value={formik.values.fastPrice} id="fastPrice" name="fastPrice" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fastPrice && formik.touched.fastPrice ?"invalid":null}/>
<span className='errSpan'>{formik.errors.fastPrice && formik.touched.fastPrice ?formik.errors.fastPrice:null}</span>

  <label>Ordinary Delivery :</label>
  <div className='select-container'>
  <select  value={formik.values.ordinary} id="ordinary" name="ordinary" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinary && formik.touched.ordinary ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.ordinary && formik.touched.ordinary?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.ordinary && formik.touched.ordinary?formik.errors.ordinary:null}</span>


    <label>Ordin Delivery KM Price OUT Area :</label>
    <input placeholder='Enter price'  value={formik.values.ordinaryOut} id="ordinaryOut" name="ordinaryOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinaryOut && formik.touched.ordinaryOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.ordinaryOut && formik.touched.ordinaryOut ?formik.errors.ordinaryOut:null}</span>

    <label>Min Order Price Out Price :</label>
    <input placeholder='Enter price'  value={formik.values.minOut} id="minOut" name="minOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.minOut && formik.touched.minOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.minOut && formik.touched.minOut ?formik.errors.minOut:null}</span>


   <label>Car Option :</label>
   <div className='select-container'>
  <select  value={formik.values.carOPtion} id="carOPtion" name="carOPtion" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.carOPtion && formik.touched.carOPtion ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.carOPtion && formik.touched.carOPtion?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.carOPtion && formik.touched.carOPtion?formik.errors.carOPtion:null}</span>


    <label>Name En :</label>
    <input placeholder='Enter name'  value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
<span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn ?formik.errors.nameEn:null}</span>


    <label>Phone :</label>
    <input placeholder='Enter phone'  value={formik.values.phone} id="phone" name="phone" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone ?"invalid":null}/>
<span className='errSpan'>{formik.errors.phone && formik.touched.phone ?formik.errors.phone:null}</span>


    <label>Working from :</label>
    <input type="time" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
<span className='errSpan'>{formik.errors.from && formik.touched.from ?formik.errors.from:null}</span>


    <label>Select Country :</label>
    <div className='select-container'>
  <select  value={formik.values.country} id="country" name="country" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.country && formik.touched.country ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.country && formik.touched.country?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.country && formik.touched.country?formik.errors.country:null}</span>


  <label>Select District :</label>
  <div className='select-container'>
  <select  value={formik.values.district} id="district" name="district" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.district && formik.touched.district ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.district && formik.touched.district?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.district && formik.touched.district?formik.errors.district:null}</span>



  <label>Wallet Payment:</label>
  <div className='select-container'>
  <select  value={formik.values.wallet} id="wallet" name="wallet" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.wallet && formik.touched.wallet ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.wallet && formik.touched.wallet?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.wallet && formik.touched.wallet?formik.errors.wallet:null}</span>


  <label>Online Payment :</label>
  <div className='select-container'>
  <select  value={formik.values.urway} id="urway" name="urway" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.urway && formik.touched.urway ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.urway && formik.touched.urway?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.urway && formik.touched.urway?formik.errors.urway:null}</span>



   
</div>
<div className='col-6'>

<label>Fast Delivery :</label>
<div className='select-container'>
  <select  value={formik.values.fast} id="fast" name="fast" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fast && formik.touched.fast ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.fast && formik.touched.fast?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.fast && formik.touched.fast?formik.errors.fast:null}</span>


  <label>Fast Delivery Price OUT Area :</label>
  <input placeholder='Enter price'  value={formik.values.fastOut} id="fastOut" name="fastOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fastOut && formik.touched.fastOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.fastOut && formik.touched.fastOut ?formik.errors.fastOut:null}</span>

  <label>Ordinary Delivery Price :</label>
  <input placeholder='Enter price'  value={formik.values.ordinaryPrice} id="ordinaryPrice" name="ordinaryPrice" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinaryPrice && formik.touched.ordinaryPrice ?"invalid":null}/>
<span className='errSpan'>{formik.errors.ordinaryPrice && formik.touched.ordinaryPrice ?formik.errors.ordinaryPrice:null}</span>

    <label>Min Order Price In Area :</label>
    <input placeholder='Enter price'  value={formik.values.minIn} id="minIn" name="minIn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.minIn && formik.touched.minIn ?"invalid":null}/>
<span className='errSpan'>{formik.errors.minIn && formik.touched.minIn ?formik.errors.minIn:null}</span>

    <label>Branch Pickup :</label>
    <div className='select-container'>
  <select  value={formik.values.branchPickup} id="branchPickup" name="branchPickup" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.branchPickup && formik.touched.branchPickup ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.branchPickup && formik.touched.branchPickup?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.branchPickup && formik.touched.branchPickup?formik.errors.branchPickup:null}</span>


    <label>Name Ar :</label>
    <input placeholder='Enter name'  value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
<span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr ?formik.errors.nameAr:null}</span>

    <label>Branch Code :</label>
    <input placeholder='Enter code'  value={formik.values.code} id="code" name="code" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
<span className='errSpan'>{formik.errors.code && formik.touched.code ?formik.errors.code:null}</span>



    <label>Image :</label>
    <div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop</p>
</>}
</div>


    <label>Working To :</label>
    <input type="time"   value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
<span className='errSpan'>{formik.errors.to && formik.touched.to ?formik.errors.to:null}</span>


    <label>Select City :</label>
    <div className='select-container'>
  <select  value={formik.values.city} id="city" name="city" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.city && formik.touched.city ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.city && formik.touched.city?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.city && formik.touched.city?formik.errors.city:null}</span>


  <label>Cash Payment :</label>
  <div className='select-container'>
  <select  value={formik.values.cash} id="cash" name="cash" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.cash && formik.touched.cash ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.cash && formik.touched.cash?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.cash && formik.touched.cash?formik.errors.cash:null}</span>



  <label>ApplePay Payment:</label>
  <div className='select-container'>
  <select  value={formik.values.apple} id="apple" name="apple" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.apple && formik.touched.apple ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.apple && formik.touched.apple?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.apple && formik.touched.apple?formik.errors.apple:null}</span>


  <label>Map Address :</label>
  <input/>

</div>
</>
: <OrdinaryNo/>}






<div className='col-12'>
{isLoaded?
<GoogleMap zoom={8} 
    center={{ lat: position.lat, lng: position.lng}}

         mapContainerClassName="map"

    options={{
        mapTypeControl: false
    }}
    > 
<MarkerF
icon={mapLogo}
draggable={true}
position={{ lat: position.lat, lng: position.lng}}

onDragEnd={(e) =>onMarkerDragEnd(e)}

/>

</GoogleMap>:null}
</div>
<div className='col-6'>
<div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
</div>
    </div>
    </div></div>
    
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddBranch;
 