      

 
import React, {useEffect,useRef, useState} from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {FiUploadCloud} from 'react-icons/fi';

import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
function AddSalesMenu(props) {


    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()
    
    
    
    
    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
     
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal  maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"52vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Sales Menu</p>
<div className='maxHeight'>

<label>Name Ar :</label>
<input placeholder='Type Name Ar'/>

<label>Name En :</label>
<input placeholder='Type Name En'/>

<label>Description Ar :</label>
<input placeholder='Default Message'/>

<label>Description En :</label>
<input placeholder='Type Name En'/>
<label>Code :</label>
<input placeholder='Type Name En'/>
<DropDown label="Meals :"/>
<label>Price Per Person :</label>
<input placeholder='Type Name En'/>

<label> Max Person :</label>
<input placeholder='Type Name En'/>
<label> Min Person :</label>
<input placeholder='Type Name En'/>
<label>Image :</label>
    <div id="uploadImg" style={{marginBottom:"24px"}}
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p style={{marginBottom:"0"}}><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>

    <div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>

    </div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddSalesMenu;
 