import '../styles/admins.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import pin from "../assets/pin.svg"
import right from "../assets/right.svg"
import noDocs from "../assets/noDocs.gif"

import menu from "../assets/menu2.svg"
import gift from "../assets/gift.svg"
import location from "../assets/location.svg"
import wallet from "../assets/wallet2.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import {BsTelephone,BsDot} from 'react-icons/bs';

import {RxDotFilled} from 'react-icons/rx';

import {HiOutlineMail} from 'react-icons/hi';

import {IoIosCloseCircleOutline} from 'react-icons/io';
import AddWallet from '../components/AddWallet';
import { useState } from 'react';
function ExternalSalesUser() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])
  const [active,setActive]=useState(0)



  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddWallet setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>



  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Admins </p>
<div className='navigateTop'>
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
<img src={right}/>
<Link to="/admins/admins-list">

<p className='bc1'>Admin</p></Link>
<img src={right}/>
<Link to="/admins/external-sales">
<p className='bc1'>External Sales List</p>
</Link>

<img src={right}/>
<Link to="/admins/external-sales/6">

<p className='bc2'>External Sales Postpaid</p>
</Link>
</div>
    </div>
  
  </div>
 

<div style={{justifyContent:'normal'}} className='editContainer externalContainer'>
<p className='editTitle'>External Sales Postpaid</p>
<div className='externalNav'>
    <p className={active==0?'activeTab':null} onClick={()=>setActive(0)}>Basic Info</p>
    <p className={active==1?'activeTab':null} onClick={()=>setActive(1)}>Facility Information</p>
    <p className={active==2?'activeTab':null} onClick={()=>setActive(2)}>Owner Information</p>
    <p className={active==3?'activeTab':null} onClick={()=>setActive(3)}>Delegates</p>
    <p className={active==4?'activeTab':null} onClick={()=>setActive(4)}>Documents</p>

</div>

<div className='externalData container-fluid'>
{active==0?
<div className='row'>
<div className='col-6'>
<p><span>Name:</span>Mariam Aly</p>
</div>
<div className='col-6'>
<p><span>Phone:</span>0129102901</p>

</div>
<div className='col-6'>
<p><span>Facility Name:</span>Test </p>

</div>
<div className='col-6'>
<p><span>City:</span> Riyadh</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>

</div>
:active==1?
<div className='row'>
<div className='col-6'>
<p><span>Commercial Registarion Number:</span>Mariam Aly</p>
</div>
<div className='col-6'>
<p><span>Landline Number:</span>0129102901</p>

</div>
<div className='col-6'>
<p><span>Branches Number:</span>Test </p>

</div>
<div className='col-6'>
<p><span>Branches Number:</span>Test </p>

</div>
<div className='col-6'>
<p><span>Email address:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>
</div>
:active==2?
<div className='row'>
<div className='col-6'>
<p><span>Name:</span>Mariam Aly</p>
</div>
<div className='col-6'>
<p><span>Phone:</span>0129102901</p>

</div>
<div className='col-6'>
<p><span>Facility Name:</span>Test </p>

</div>
<div className='col-6'>
<p><span>City:</span> Riyadh</p>

</div>
<div className='col-6'>
<p><span>Facility Activity:</span>Mariam Aly</p>

</div>

</div>
:active==3?
<div className='row gy-4'>
<div className='col-6'>

<div className='delegateDiv'>
<p className='delegateTitle'>Delegates <span>(Verified)</span></p>
<div>
    <p className='delegateName'>Name</p>
    <p>Mariam Aly</p>
    </div>
    <div>
    <p className='delegateName'>Position</p>
    <p>Mariam Aly</p>
    </div>
    <div>
    <p className='delegateName'>Mobile Number</p>
    <p>0129102901</p>
    </div>
</div></div>
<div className='col-6'>
<div className='delegateDiv unVeri'>
<p className='delegateTitle'>Delegates <span>(Verified)</span></p>
<div>
    <p className='delegateName'>Name</p>
    <p>Mariam Aly</p>
    </div>
    <div>
    <p className='delegateName'>Position</p>
    <p>Mariam Aly</p>
    </div>
    <div>
    <p className='delegateName'>Mobile Number</p>
    <p>0129102901</p>
    </div>
</div>
</div>


</div>:

<div className="noDocs">
<div  style={{ backgroundImage: `url(${noDocs})` }}>

</div>
<p>No documents Uploaded</p>  
{/*
<div className='row gy-4 documents'>
 
<div className='col-12'>


</div>
<div className='col-12'>
<p>commercial Registration</p>
<div className='documentImg'  style={{ backgroundImage: `url(${pp})` }}>

</div>
</div>
<div className='col-12'>
<p>commercial Registration</p>
<div className='documentImg'  style={{ backgroundImage: `url(${pp})` }}>

</div>
</div>



</div>*/}


    </div>
}

</div>

</div>

</div>

<div></div>

</div>
  </div>



  );
}

export default ExternalSalesUser;
