 
    

 
import React, {useEffect, useState,useRef} from 'react';
import { BeatLoader } from 'react-spinners';
import {BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';

function AddMdiafCategory(props) {

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])


const[errMsg,setErrMsg]=useState()
const[err,setErr]=useState(false)
const uploadImg= useRef()
const [image,setImage]=useState()
const [preview,setPreview]=useState()




useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setErr(false)
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
 
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
        setErr(true)
        setErrMsg("Please drop only one image file");
      }
    
      };


    return ( 
      
 
 
        <div  className="AddModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCateg" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Mdiaf Category</p>
<label>Name Ar :</label>
    <input placeholder='Enter name'/>

    <label>Name En :</label>
    <input placeholder='Enter name'/>

    <label>Image :</label>
    <div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>

  
  
           <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddMdiafCategory;
 