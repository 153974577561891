import '../styles/dashboard.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import carOrder from "../assets/carOrder.svg"
import mdiaf from "../assets/mdiaf.svg"
import gift from "../assets/gift-01.svg"
import condition from "../assets/condition.svg"
import tag from "../assets/tag.svg"
import meal from "../assets/meal2.svg"
import orderType from "../assets/type.svg"
import setting from "../assets/setting.svg"
import version from "../assets/version.svg"
import size from "../assets/size.svg"
import additional from "../assets/additional.svg"
import file from "../assets/file-minus.svg"
import fileSearch from "../assets/file-search.svg"
import question from "../assets/question.svg"

import imageCheck from "../assets/image-check.svg"

import coins from "../assets/coins-hand.svg"
import line from "../assets/line.svg"
import box from "../assets/box.svg"
import menu from "../assets/menu.svg"
import wallet from "../assets/wallet.svg"
import chat from "../assets/chat.svg"
import blog from "../assets/blog.svg"
import external from "../assets/external.svg"
import list from "../assets/list.svg"
import building from "../assets/building.svg"
import questions from "../assets/questions.svg"
import news from "../assets/news.svg"
import { NavLink ,Link} from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import {FiLogOut}  from 'react-icons/fi';
import { useEffect,useState } from 'react';

function Navbar() {

    let location= useLocation()
let currentLocation= location.pathname
let currentNav= location.pathname.split("/")[1]

  return (

    <div className={ location.pathname.split("/")[1]=="dashboard"?'dashboardContainer':"dashboardContainer dashboardContainer2"}>


   <div className='sideNav' style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
     <div style={{display:"flex",padding:"16px",paddingTop:"0"}} >


   <div>
<div className='navLogo' style={{ backgroundImage: `url(${logo})` }}>
  </div>
  <div className='navPp' style={{ backgroundImage: `url(${pp})` }}>
  </div>
<p className='usrName'>Mariam Aly</p>
<p  className='usrPosition'>Sales Manager</p>

<ul>
  <li>
<NavLink to="/dashboard">
<RiHome6Line size={25}/>
<p className="removeTxt">
Home

</p >
</NavLink>
  </li>

  <li>
<NavLink className={currentLocation.split("/")[1]=="admins"?'active':null} to="/admins/admins-list">
<PiUserSquare size={25}/>
<p className="removeTxt">
Admin
</p >

</NavLink>

  </li>

  <li>
<NavLink className={currentLocation.split("/")[1]=="users"?'active':null} to="/users/users-list">
<FiUsers size={23}/>
<p className="removeTxt">

Users</p >

</NavLink>

  </li>

  <li>
<NavLink className={currentLocation.split("/")[1]=="orders"?'active':null} to="/orders">
<GoChecklist size={24}/>
<p className="removeTxt">

Orders</p >
</NavLink>

  </li>

  <li>
<NavLink to="/countries">
<BiGlobe size={25}/>
<p className="removeTxt">

Countries</p >
</NavLink>

  </li>

  <li>
<NavLink to="/branches">
<img src={branch}/>
<p className="removeTxt">

branches</p >
</NavLink>

  </li>

  <li>
<NavLink to="/categories">
<BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>
<p className="removeTxt">

Categories</p >

</NavLink>

  </li>
  <li>
<NavLink  to="/products">
<img src={box}/>
<p className="removeTxt">

Products</p>
</NavLink>

  </li>
  <li>
<NavLink to="/mdiaf/menu" className={currentLocation.split("/")[1]=="mdiaf"?'active':null}>
<img src={cart}/>
<p className="removeTxt">

mdiaf</p >
</NavLink>

  </li>


  <li>
<NavLink to="/promotions">
<img src={sale}/>
<p className="removeTxt">

Promotions</p >
</NavLink>

  </li>


  <li>
<NavLink to="/sales/menus" className={currentLocation.split("/")[1]=="sales"?'active':null}>
<img src={line}/>
<p className="removeTxt">

Sales</p>
</NavLink>

  </li>

 

  <li>
<NavLink to="/banners">
<LuImage size={25}/>
<p className="removeTxt">

Banners</p >
</NavLink>

  </li>

 
  <li>
<NavLink to="/settings">
<FiSettings size={25}/>
<p className="removeTxt">

Settings</p >
</NavLink>

  </li>
  <li>
<NavLink to="/chat">
<img src={chat}/>
<p className="removeTxt">

Chat</p >
</NavLink>

  </li>
  <li>
<NavLink to="/blog">
<img src={blog}/>
<p className="removeTxt">

Blog</p >
</NavLink>

  </li>
</ul>
<div className='navBottom'>
  <div className='alignCenter'>
<div className='usrLogo' style={{ backgroundImage: `url(${pp})` }}>
  </div>
<div>
  <p className='bottomName'>Eman Moustafa</p>
  <p className='bottomEmail'>Eman.moustafa@gmail.com</p>
</div>
</div>
<FiLogOut size={20} color="white"/>
</div>
   
    </div>
    <div className='navBranch' style={{ display: currentNav === 'users' ? "block" : "none" }}>
<p>Users</p>
<ul>
  <li>
<NavLink className={currentLocation.split("/")[2]!=="wallets"?'active':null} to="/users/users-list">
<img src={menu}/>
Users list

</NavLink>
  </li>

  <li>
<NavLink to="/users/wallets" className="walletAlign">
  <div>
  <img src={wallet}/>
Wallets
  </div>


<p className='walletBalance'>10</p>
</NavLink>

  </li>

</ul>


   </div>
<div className='navBranch' style={{ display: currentNav === 'admins' ? "block" : "none" }}>
<p>Admin</p>
<ul>
  <li>
<NavLink className={currentLocation=='/admins/edit-admin'?"active":null} to="/admins/admins-list">
<FiUsers size={23}/>

Admin list

</NavLink>
  </li>

  <li>
<NavLink to="/admins/customer-service"   className={currentLocation=='/admins/edit-agent'?"active":null}>
  <div>
  <img src={list}/>
Customers Services List
  </div>


</NavLink>

  </li>
  <li>
<NavLink to="/admins/external-sales">
<img src={external}/>
External Sales List
</NavLink>
  </li>
</ul>


   </div>
   

  <div className='navBranch' style={{ display: currentNav === 'countries' ? "block" : "none" }}>
<p>Countries</p>
<ul>
  <li>
<NavLink  to="/countries" className={location.pathname.split("/")[2]=="districts" || location.pathname.split("/")[2]=="edit-district"? "removeActive":"active"}>
<BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>

Countries

</NavLink>
  </li>

  <li>
<NavLink to="/countries/districts" className={location.pathname.split("/")[2]=="edit-district"?"active":null}>
  <div>
  <img src={building}/>
Districts
  </div>


</NavLink>

  </li>
 
</ul>


   </div>
   <div className='navBranch' style={{ display: currentNav === 'orders' ? "block" : "none" }}>
   <p>Countries</p>
   <ul>
     <li>

   <NavLink to="/orders" className={location.pathname.split("/")[2]=="car-orders" || location.pathname.split("/")[2]=="mdiaf-orders"? "removeActive":"active"}>
   <BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>
   
   Orders List
   
   </NavLink>
     </li>
   
     <li>
     <NavLink to="/orders/car-orders"  className={location.pathname.split("/")[2]=="car-orders"? "active":null}>
        
     <img width={27} height={27} src={carOrder}/>

   Car Orders 
      
   </NavLink>
   </li>
   <li>
   <NavLink to="/orders/mdiaf-orders"  className={location.pathname.split("/")[2]=="mdiaf-orders"? "active":null}>
   
   <img src={mdiaf}/>

   mdiaf Orders 
   
   </NavLink>

   </li>
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'promotions' ? "block" : "none" }}>
   <p>Promotions</p>
   <ul>
     <li>


   <NavLink to="/promotions" className={location.pathname.split("/")[2]!==undefined && location.pathname.split("/")[2]!=='edit-promotions'?"removeActive":null}>
   <img src={tag}/>
   
   Promo codes
   
   </NavLink>
     </li>
   
     <li>
     <NavLink to="/promotions/offers" className={location.pathname.split("/")[2]=="edit-offer"? "active":null}>
        
     <img src={gift}/>

Offers
      
   </NavLink>
   </li>
   <li>
   <NavLink to="/promotions/conditions" className={location.pathname.split("/")[2]=="edit-conditions"? "active":null}>
   
   <img src={condition}/>

Conditions
   
   </NavLink>

   </li>
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'categories' ? "block" : "none" }}>
   <p>Categories</p>
   <ul>
     <li>


   <NavLink to="/categories">
   <BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>
   Categories List
   
   </NavLink>
     </li>
   
   
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'products' ? "block" : "none" }}>
   <p>Products</p>
   <ul>
    
     <li>
   <NavLink to="/products" className={location.pathname.split("/")[2]!==undefined && location.pathname.split("/")[2]!=='edit-product'?"removeActive":null}>
   <img src={box}/>
   Products
   </NavLink>
     </li>

     <li>
   <NavLink  className={location.pathname.split("/")[2]=="reports" || location.pathname.split("/")[2]=="edit-meal"  || location.pathname.split("/")[2]=="branches"?"active":null} to="/products/meals-list">
   <img src={meal}/>
   Meals
   </NavLink>
     </li>
     <li>
   <NavLink to="/products/additionals-list"  className={ location.pathname.split("/")[2]=="edit-additional" ?"active":null}>
   <img src={additional}/>
   Additional Products
   </NavLink>
     </li>
     <li>
   <NavLink to="/products/extras-list" className={ location.pathname.split("/")[2]=="edit-extra" ?"active":null}>
   <BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>
 Extras
   </NavLink>
     </li>
     <li>
   <NavLink to="/products/sizes-list"  className={ location.pathname.split("/")[2]=="edit-size" ?"active":null}>
   <img src={size}/>
  Sizes
   </NavLink>
     </li>
   
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'branches' ? "block" : "none" }}>
   <p>Branches</p>
   <ul>
   <li>
   <NavLink to="/branches">
   <BiCategory size={25} style={{ transform: 'scaleY(-1)'}}/>
 Branch List
   </NavLink>
     </li>
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'mdiaf' ? "block" : "none" }}>
   <p>Mdiaf</p>
   <ul>
   <li>
   <NavLink to="/mdiaf/menu" className={ location.pathname.split("/")[2]=="edit-menu" ?"active":null}>
   <img src={file}/>
 Menus
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/categories" className={ location.pathname.split("/")[2]=="edit-category" ?"active":null}>
   <img src={additional}/>
 Categories
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/meals" className={ location.pathname.split("/")[2]=="edit-meal" ?"active":null}>
   <img src={meal}/>
 Meals
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/products" className={ location.pathname.split("/")[2]=="edit-product" ?"active":null}>
   <img src={box}/>
 Products
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/services" className={ location.pathname.split("/")[2]=="edit-service" ?"active":null}>
   <img src={coins}/>
 Services
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/inquires" >
   <img src={question}/>
   Inquires
   </NavLink>
     </li>
     <li>
   <NavLink to="/mdiaf/images" className={ location.pathname.split("/")[2]=="edit-image" ?"active":null}>
   <LuImage size={25}/>
 Images
   </NavLink>
     </li>
    
   </ul>
   
   
      </div>
   <div className='navBranch' style={{ display: currentNav === 'sales' ? "block" : "none" }}>
   <p>Sales</p>
   <ul>
   <li>
   <NavLink to="/sales/menus"  className={ location.pathname.split("/")[2]=="edit-menu" ?"active":null}>
   <img src={file}/>
 Menus
   </NavLink>
     </li>
     <li>
   <NavLink to="/sales/meals" className={ location.pathname.split("/")[2]=="edit-meal" ?"active":null}>
   <img src={meal}/>
 Meals
   </NavLink>
     </li>
     <li>
   <NavLink to="/sales/services" className={ location.pathname.split("/")[2]=="edit-service" ?"active":null}>
   <img src={coins}/>
 Services
   </NavLink>
     </li>
     <li>
   <NavLink to="/sales/inquires" >
   <img src={question}/>
   Inquires
   </NavLink>
     </li>
     <li>
   <NavLink to="/sales/clients" >
   <FiUsers size={23}/>
 Clients
   </NavLink>
     </li>
    
   </ul>
      
      
      
      
   
      </div>
      
  
   <div className='navBranch' style={{ display: currentNav === 'settings' ? "block" : "none" }}>
   <p>Settings</p>
   <ul>
   <li>

   <NavLink to="/settings" className={location.pathname.split("/")[2]!==undefined && location.pathname.split("/")[2]!=="edit-setting"?"removeActive":null}>
   <img src={setting}/>
   Settings
   </NavLink>
     </li>
     <li>
   <NavLink to="/settings/terms" className={ location.pathname.split("/")[2]=="edit-terms" ?"active":null}>
   <img src={condition}/>
 Terms And Conditions
   </NavLink>
     </li>
     <li>
   <NavLink to="/settings/versions" className={ location.pathname.split("/")[2]=="edit-version" ?"active":null}>
   <img src={version}/> 
 App Versions
   </NavLink>
     </li>
     <li>
   <NavLink to="/settings/types" className={ location.pathname.split("/")[2]=="edit-type" ?"active":null}>
   <img src={orderType}/>
   Order Types
   </NavLink>
     </li>
     <li>
   <NavLink to="/settings/sources" className={ location.pathname.split("/")[2]=="edit-source" ?"active":null}>
   <img src={list}/>
 Order Sources
   </NavLink>
     </li>
    
   </ul>
   </div>
   <div className='navBranch' style={{ display: currentNav === 'blog' ? "block" : "none" }}>
   <p>Blog</p>
   <ul>
   <li>
   <NavLink to="/blog" className={location.pathname.split("/")[2]!==undefined && location.pathname.split("/")[2]!=="edit-post"  ?"removeActive":null}>
   <img src={file}/>
   Blog List
   </NavLink>
     </li>
     <li>
   <NavLink to="/blog/newsPosts" className={ location.pathname.split("/")[2]=="edit-newsPost" ?"active":null}>
   <img src={news}/>
 Customer Services News 
   </NavLink>
     </li>
     <li>
   <NavLink to="/blog/questions" className={ location.pathname.split("/")[2]=="edit-question" ?"active":null}>
   <img src={questions}/>
  Questions List
   </NavLink>
     </li>
   
   
    
   </ul>
   </div>

<div className='navBranch' style={{ display: currentNav === 'chat'? "block" : "none" }}>
<p>Chat</p>
<ul>
<li>
<NavLink to="/chat" className={location.pathname.split("/")[2]!==undefined && location.pathname.split("/")[2]!=="actions"?"removeActive":null}>
<img src={file}/>
Chat
</NavLink>
  </li>
  <li>
<NavLink to="/chat/observation">
<img src={fileSearch}/>
Observation List 
</NavLink>
  </li>



 
</ul>
</div>
<div className='navBranch' style={{ display:  currentNav === 'banners' ? "block" : "none" }}>
<p>Banners</p>
<ul>
<li>
<NavLink to="/banners" >
<img src={imageCheck}/>
Banner List
</NavLink>
  </li>




 
</ul>
</div>

</div>


   
  
    </div>
 
    </div>
  );
}

export default Navbar;
