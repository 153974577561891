import '../styles/login.css';
import logo from "../assets/logo.png"
import { BiArrowBack } from 'react-icons/bi';
import {HiOutlineMail} from 'react-icons/hi';
import PasswordContainer from '../components/PasswordContainer';
import { Link } from 'react-router-dom';
function CheckEmail() {
  return (
 <PasswordContainer
 title="Check your email"
 text="We sent a password reset link to"
 icon={ <HiOutlineMail color='#D16700'size={23}/>}
 >
  
 
  <p className='passEmail'>Eman@Alromnaceya.com</p>
  <Link to="/set-password">
  <button id="loginBtn">Open email app</button>

  </Link>
  <p className='passresend'>Didn’t receive the email? <span>Click to resend</span></p>
  <Link to="/">

    <button className='bck' ><BiArrowBack size={18} color='#475467'/> Back to log in</button>
 
</Link>
    </PasswordContainer>
  );
}

export default CheckEmail;
