     

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import qr from "../assets/qr.png"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';

import {GrFormClose} from 'react-icons/gr';

function BranchQr(props) {

const history= useNavigate();


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal tablesModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"63vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p style={{marginBottom:"32px"}} className='modalTitle'>QR Code</p>

<div className='qrCode'  style={{ backgroundImage: `url(${qr})` }}>
    </div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default BranchQr;
 