import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import {useFormik} from "formik";
import * as yup from "yup"
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {BiChevronDown} from 'react-icons/bi';
import {RiHome6Line}  from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill,BsCheck}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';

import AddProduct from '../components/AddProduct';
import { useState,useRef,useEffect } from 'react';
function EditProduct() {
    const [add,setAdd]=useState(false)
    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()



    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
    let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
    let decimalRegx= /^\d*\.?\d*$/
        const Schema= yup.object().shape({
            nameAr: yup.string().required("Product name is required").matches(nameRegEx,{message:("Product name should contain letters only")}),
            nameEn: yup.string().required("Product name is required").matches(nameRegEx,{message:("Product name should contain letters only")}),
            describeAr: yup.string().required("Description  is required"),
            describeEn:yup.string().required("Description is required"),
            category: yup.string().required("Product category  is required"),
            delivery:yup.string().required("Delivery option is required"),
    
            })
    
    
            const formik = useFormik({
              initialValues:{
                nameAr: '',
                nameEn: '',
                describeAr: '',
                describeEn: '',
                category: '',
                delivery: '',
        
    
               
              },
              validationSchema: Schema,
              onSubmit,
              });
    
    
    
              function onSubmit(value){
              }
     


    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };

  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddProduct setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Product</p>
<div className='navigateTop'>
<Link to="/dashboard">
<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/products">

<p className='bc1'>Products</p></Link>
<img src={right}/>

<Link to="/products">

<p className='bc1'>Product List</p></Link>
<img src={right}/>
<Link to="/products/edit-product">

<p className='bc2'>Edit Product</p></Link>

</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Product </button>
  </div>
 

<div style={{height:"unset"}} className='editContainer editAgent'>
    <div>

<p className='editTitle'>Edit Product Data</p>
<div className='editRow'>


<div>
    <label>Name Ar:</label>
    <input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>
</div>
<div style={{margin:"0 25px"}}>
    <label>Name En:</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>
</div>
<div>
    <label>Delivery:</label>
    <div className='select-container'>
  <select  value={formik.values.delivery} id="delivery" name="delivery" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.delivery && formik.touched.delivery ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span className='errSpan'>{formik.errors.delivery && formik.touched.delivery?formik.errors.delivery:null}</span>

</div>

</div>

<div>
<div className='editRow'>


<div>
    <label>Description Ar:</label>
    <input placeholder='Enter name' value={formik.values.describeAr} id="describeAr" name="describeAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describeAr && formik.touched.describeAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.describeAr && formik.touched.describeAr?formik.errors.describeAr:null}</span>
</div>
<div style={{margin:"0 25px"}}>
    <label>Description En</label>
    <input placeholder='Enter name' value={formik.values.describeEn} id="describeEn" name="describeEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describeEn && formik.touched.describeEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.describeEn && formik.touched.describeEn?formik.errors.describeEn:null}</span>
</div>
<div>
    <label>Category</label>
    <div className='select-container'>
    <select value={formik.values.category} id="category" name="category" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.category && formik.touched.category ?"invalid":null}>
    <option>Sweet</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
<span className='errSpan'>{formik.errors.category && formik.touched.category?formik.errors.category:null}</span>

</div>

</div>
</div>


<div style={{marginBottom:"50px"}}>
<div className='editRow'>


<div style={{display:"flex",alignItems:"center"}}>
   
    <div className='supervisor' style={{width:"100%",marginBottom:"0"}}>

<div style={{flex:"none"}}  className='containCheck'>
    <div > 
<input id="supervisor" type="checkbox" className="chooseClass"/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>


    <label>Set as Active</label>
</div>
</div>
<div>
    </div>
    <div>
    </div>
</div>
</div>
<div className='editRow'>

<div >
<label>Image :</label>
<div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>
<div className='countryImg'  style={{ backgroundImage: `url(${saudi})` }}>

    </div>

<div></div></div>

</div></div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditProduct;
