import '../styles/countries.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import line from "../assets/line.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {LuRectangleHorizontal} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import AddAdmin from '../components/AddAdmin';
import {IoIosCloseCircleOutline} from 'react-icons/io';

import {RiDeleteBinLine} from 'react-icons/ri';

import { useState } from 'react';
import AddCountry from '../components/AddCountry';
function Countries() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
      {add?<AddCountry setModal={setAdd}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Country</p>
<div className='navigateTop'>
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/countries">
  <p className='bc1'>Country</p>

  </Link>
<img src={right}/>
<Link to="/countries">

<p className='bc2'>Country List</p></Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Country </button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your country data</p>
<button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>
  </div>

  <div className={filter?'filtersMore':'filtersLess'}>
<div className='filtersContainer'>
  
<div className='flex-2' >
  <label>Search </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>

  <div style={{marginLeft:"8px"}} className='flex-1 alignEnd '>
  
  <button className='clear'><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  </div></div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>Name Ar</th>
    <th>Name En</th>

    <th>ISO Code</th>
    <th>Phone Code</th>
    <th>Currency Ar</th>
    <th>Currency En</th>
    <th>Image</th>
    <th>Exchange from SAR</th>
    <th>Cities</th>
    <th>Action</th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >
السعودية
</td>
<td  >
Sa
</td>
<td>+232</td>
<td>Saudi</td>
<td>SAR</td>
<td>SAR</td>

<td>
 
<div className='countryFlag'  style={{ backgroundImage: `url(${saudi})` }}>

    </div>
</td>
<td>0.1</td>

<td className='centerData'>
<Link to="/countries/cities">
  <img src={globe}/>
  </Link>
  </td>

  <td className='centerData'>
<Link to="/countries/edit-country">
  <img src={edit}/>
  </Link>
  </td>
    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >
السعودية
</td>
<td  >
Sa
</td>
<td>+232</td>
<td>Saudi</td>
<td>SAR</td>
<td>SAR</td>

<td>
 
<div className='countryFlag'  style={{ backgroundImage: `url(${saudi})` }}>

    </div>
</td>
<td>0.1</td>

<td className='centerData'>
<Link to="/countries/cities">
  <img src={globe}/>
  </Link>
  </td>

  <td className='centerData'>
<Link to="/countries/edit-country">
  <img src={edit}/>
  </Link>
  </td>
    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Countries;
