import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { Link } from 'react-router-dom';
import {useFormik} from "formik";
import * as yup from "yup"
function EditConditions() {

  let numRegEx =/^[0-9]+$/;

        const Schema= yup.object().shape({
            amount: yup.string().required("Amount is required").matches(numRegEx,{message:("Amount should contain digits only")}),
            from: yup.string().required("Time from is required"),
            to: yup.string().required("Time to is required"),

            })
    
    
            const formik = useFormik({
              initialValues:{
                amount: '',
                from: '',
                to: '',
               
              },
              validationSchema: Schema,
              onSubmit,
              });
    
    
    
              function onSubmit(value){
              }
     


  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Promo Codes</p>
    <div className='navigateTop'>
  
  <Link to="/dashboard">
  
    
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
  <img src={right}/>
  <Link to="/promotions">
  
  <p className='bc1'>Promotions</p></Link>
  <img src={right}/>
  <Link to="/promotions/conditions">
  
  <p className='bc2'>Conditions List</p></Link>
  </div>
    </div>
 
  </div>
 

<div  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Conditions Data</p>
<div>


<div className='editRow'>

<div >
<label>Amount</label>
<input placeholder='Enter name' value={formik.values.amount} id="amount" name="amount" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.amount && formik.touched.amount ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.amount && formik.touched.amount?formik.errors.amount:null}</span>

</div>

<div style={{margin:"0 25px"}}>
<label>From</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span className='errSpan'>{formik.errors.from && formik.touched.from?formik.errors.from:null}</span>

</div>

<div >
<label>Expiry Date</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span className='errSpan'>{formik.errors.to && formik.touched.to?formik.errors.to:null}</span>

</div>

</div>
</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditConditions;
