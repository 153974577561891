import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {BiChevronDown} from 'react-icons/bi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import AddMdiafCategory from '../components/AddMdiafCategory';
import { useState,useRef,useEffect } from 'react';
import AddSalesMeal from '../components/AddSalesMeal';
function EditSalesMeal() {
    const [add,setAdd]=useState(false)
    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()




    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };

  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddSalesMeal setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Sales</p>
    <div className='navigateTop'>
  
  <Link to="/dashboard">
  
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
  <img src={right}/>
  <Link to="/sales/menus">
  
  <p className='bc1'>Sales</p></Link>
  <img src={right}/>
  <Link to="/sales/meals">
  
  <p className='bc2'>Meals List</p>
  </Link>
  </div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Meal </button>
  </div>
 

<div style={{height:"70%"}} className='editContainer editAgent'>
    <div>

<p className='editTitle'>Edit Sa Category</p>
<div className='editRow'>


<div>
    <label>Name Ar :</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{marginLeft:"25px"}}>
    <label>Name En :</label>
    <input  type="text" placeholder='test'/>
</div>




</div>




<div>
<div className='editRow'>
    <div>
<label> Photo :</label>
<div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>
<div className='countryImg'  style={{ backgroundImage: `url(${saudi})` }}>
</div>

    </div></div></div>


<div>

</div></div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditSalesMeal;
