   

 
import React, {useEffect, useState,useRef} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import {useFormik} from "formik";
import * as yup from "yup"
function AddPromo(props) {
const history= useNavigate();
const [offer,setOffer]=useState();

let numRegEx =/^[0-9]+$/;

const Schema= yup.object().shape(  offer==null?{
    code: yup.string().required("Code is required"),
    type: yup.string().required("Type is required"),
    amount: yup.string().required("Amount is required").matches(numRegEx,{message:("Amount should contain digits only")}),
    uses: yup.string().required("Number of uses is required").matches(numRegEx,{message:("Amount should contain digits only")}),

 

    }:{
    code: yup.string().required("Code is required"),
    type: yup.string().required("Type is required"),
    amount: yup.string().required("Amount is required").matches(numRegEx,{message:("Amount should contain digits only")}),
    uses: yup.string().required("Number of uses is required").matches(numRegEx,{message:("Amount should contain digits only")}),
    from: yup.string().required("Time from is required"),
            to: yup.string().required("Time to is required"),

  }
    )


    const formik = useFormik({
      initialValues:{
        code: '',
        type: '',
        amount: '',
        uses:'',
        from:'',
        to:''
      },
      validationSchema: Schema,
      onSubmit,
      });



      function onSubmit(value){
      }


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Promo Code</p>
<div className='maxHeight'>

<label>Code :</label>
<input placeholder='Enter name' value={formik.values.code} id="code" name="code" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.code && formik.touched.code?formik.errors.code:null}</span>

    <label>Type :</label>
    <div className='select-container'>
  <select value={formik.values.type} id="type" name="type" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.type && formik.touched.type ?"invalid":null}>
      <option>Cash</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.type && formik.touched.type?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.type && formik.touched.type?formik.errors.type:null}</span>



  <label>Amount :</label>
    <input  type="number" placeholder='Enter amount' value={formik.values.amount} id="amount" name="amount" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.amount && formik.touched.amount ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.amount && formik.touched.amount?formik.errors.amount:null}</span>

    <label>Number of uses :</label>
    <input  type="number" placeholder='Enter number' value={formik.values.uses} id="uses" name="uses" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.uses && formik.touched.uses ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.uses && formik.touched.uses?formik.errors.uses:null}</span>

  <label>Offer :</label>
    <div className='select-container'>
  <select value={offer} onChange={(e)=>setOffer(e.target.value)}>
  <option value={null}>Choose Offer</option>
  <option value="valid">Choose Offer</option>

      <option value="valid">Choose Offer</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

 {offer==null?
 null:
 <>
    <label>Valid From :</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span style={formik.errors.from && formik.touched.from?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.from && formik.touched.from?formik.errors.from:null}</span>

    <label>Expiry Date :</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span style={formik.errors.to && formik.touched.to?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.to && formik.touched.to?formik.errors.to:null}</span>

    </>}

           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddPromo;
 