import logoPlain from "../../assets/logoPlain.png"
import right from "../../assets/right.svg"
import Navbar from '../../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import BranchMap from '../../components/BranchMap';
import { Link } from "react-router-dom";
import AddBranch from '../../components/AddBranch';
import { useState } from "react";
import * as yup from "yup"
import {useFormik} from "formik";

function EditBranchQr() {
let type=1
const [add,setAdd]=useState(false)
const [map,setMap]=useState(false)

  
let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let decimalRegx= /^\d*\.?\d*$/
let numRegEx =/^[0-9]+$/;

    const Schema= yup.object().shape({
        active: yup.string().required("Active status is required"),
        busy: yup.string().required("Time is required"),
        branchOrderTime: yup.string().required("Delivery status is required"),
        delivery: yup.string().required("Delivery status is required"),
    deliveryOff: yup.string().required("Delivery off time is required"),
    fast: yup.string().required("Delivery status is required"),
    ordinary: yup.string().required("Delivery status is required"),
    ordinaryTime: yup.string().required("Delivery time is required"),
    ordinaryPrice: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
    ordinaryOut: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
    minIn: yup.string().required("Minimum price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
    minOut: yup.string().required("Minimum price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
    branchPickup: yup.string().required("Branch option is required"),
        carOPtion: yup.string().required("Car option is required"), 
//depends on fast choice
    fastTime: yup.string().required("Delivery time is required"),
        fastPrice: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}),   //depends on top
        fastOut: yup.string().required("Delivery price is required").matches(numRegEx,{message:("Price should contain digits only")}), //depends on top
    
       from: yup.string().required("Time from  is required"),
        to:yup.string().required("Time to required"),  
        cash:yup.string().required("Status is required"),
        wallet:yup.string().required("Status is required"),
        apple:yup.string().required("Status is required"),
        urway:yup.string().required("Status is required"),

        })


        const formik = useFormik({
          initialValues:{
            active: '',
            busy:'',
            branchOrderTime:'',
            deliveryOff: '',
            fastTime: '',
            ordinaryTime:'',

            delivery: '',
            fast:'',
            fastPrice:'',   //depends on top
            fastOut: '', //depends on top
            ordinary: '',
            ordinaryPrice:'',   //depends on top
            ordinaryOut:'',   //depends on top
            minIn:'', //depends on top
            minOut: '', //depends on top
            branchPickup:'',
            carOPtion:'', //depends on top
            from: '',
            to:'',
            cash:'',
            wallet: '',
            apple: '',
            urway:'',

           
          },
          validationSchema: Schema,
          onSubmit,
          });

console.log(formik)

          function onSubmit(value){
          }




  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddBranch setModal={setAdd}/>:null}
 {map?<BranchMap setModal={setMap}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Branches</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/branches">

<p className='bc1'>Branches</p></Link>

<img src={right}/>
<Link to="/branches">

<p className='bc2'>Branches List</p>
</Link>
</div>
    </div>
    <div className='orderDet'>

<button onClick={()=>{setAdd(true)}} className='export'>

Add Branch   </button>
<button onClick={()=> setMap(true)} className='sync'>
Map Branch
</button>
</div>

  </div>
 

<div style={{height:"auto"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Rawabi Branch - QR Data</p>
<div>
<div className='editRow'>

<div >
<label>Active</label>

<div className='select-container'>
  <select  value={formik.values.active} id="active" name="active" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.active && formik.touched.active ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.active && formik.touched.active?{marginBottom:"24px"}:null}   className='errSpan'>{formik.errors.active && formik.touched.active?formik.errors.active:null}</span>


</div>

<div style={{margin:"0 25px"}}>
<label>Busy</label>
<div className='select-container'>
  <select  value={formik.values.busy} id="busy" name="busy" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.busy && formik.touched.busy ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.busy && formik.touched.busy?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.busy && formik.touched.busy?formik.errors.busy:null}</span>

</div>

<div >
<label>Branch Order Time</label>
<input type="time" value={formik.values.branchOrderTime} id="branchOrderTime" name="branchOrderTime" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.branchOrderTime && formik.touched.branchOrderTime ?"invalid":null}/>
<span className='errSpan'>{formik.errors.branchOrderTime && formik.touched.branchOrderTime ?formik.errors.branchOrderTime:null}</span>



</div>

</div>

<div>
<div className='editRow'>

<div >
<label>Delivery</label>
<div className='select-container'>
  <select  value={formik.values.delivery} id="delivery" name="delivery" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.delivery && formik.touched.delivery ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.delivery && formik.touched.delivery?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.delivery && formik.touched.delivery?formik.errors.delivery:null}</span>

</div>


<div style={{marginLeft:"25px"}}>
<label>Delivery Off Time:</label>
<input type="time" value={formik.values.deliveryOff} id="deliveryOff" name="deliveryOff" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.deliveryOff && formik.touched.deliveryOff ?"invalid":null}/>
<span className='errSpan'>{formik.errors.deliveryOff && formik.touched.deliveryOff ?formik.errors.deliveryOff:null}</span>


</div>




</div>

</div>

<div>
<div className='editRow'>

<div>
<label>Fast Delivery</label>
<div className='select-container'>
  <select  value={formik.values.fast} id="fast" name="fast" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fast && formik.touched.fast ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.fast && formik.touched.fast?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.fast && formik.touched.fast?formik.errors.fast:null}</span>

</div>

<div style={{marginLeft:" 25px"}}>
<label>Ordinary Delivery</label>
<div className='select-container'>
  <select  value={formik.values.ordinary} id="ordinary" name="ordinary" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinary && formik.touched.ordinary ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.ordinary && formik.touched.ordinary?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.ordinary && formik.touched.ordinary?formik.errors.ordinary:null}</span>


</div>
</div></div>
<div>
<div className='editRow'>


<div >
<label>Fast Delivery Order Time</label>
<input placeholder='Enter name'  value={formik.values.fastTime} id="fastTime" name="fastTime" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fastTime && formik.touched.fastTime ?"invalid":null}/>
<span className='errSpan'>{formik.errors.fastTime && formik.touched.fastTime ?formik.errors.fastTime:null}</span>

</div>


<div style={{margin:"0 25px"}}>
<label>Fast Delivery Order Price</label>
<input placeholder='Enter price'  value={formik.values.fastPrice} id="fastPrice" name="fastPrice" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fastPrice && formik.touched.fastPrice ?"invalid":null}/>
<span className='errSpan'>{formik.errors.fastPrice && formik.touched.fastPrice ?formik.errors.fastPrice:null}</span>

</div>

<div >
<label>Fast Delivery KM Price OUT Area</label>
<input placeholder='Enter price'  value={formik.values.fastOut} id="fastOut" name="fastOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.fastOut && formik.touched.fastOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.fastOut && formik.touched.fastOut ?formik.errors.fastOut:null}</span>

</div>
</div>

</div>

<div>
<div className='editRow'>


<div >
<label>Ordinary Delivery Order Time</label>
<input placeholder='Enter name'  value={formik.values.ordinaryTime} id="ordinaryTime" name="ordinaryTime" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinaryTime && formik.touched.ordinaryTime ?"invalid":null}/>
<span className='errSpan'>{formik.errors.ordinaryTime && formik.touched.ordinaryTime ?formik.errors.ordinaryTime:null}</span>

</div>


<div style={{margin:"0 25px"}}>
<label>Ordinary Delivery Price</label>
<input placeholder='Enter price'  value={formik.values.ordinaryPrice} id="ordinaryPrice" name="ordinaryPrice" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinaryPrice && formik.touched.ordinaryPrice ?"invalid":null}/>
<span className='errSpan'>{formik.errors.ordinaryPrice && formik.touched.ordinaryPrice ?formik.errors.ordinaryPrice:null}</span>

</div>


<div >
<label>Ordinary Delivery KM Price OUT Area</label>
<input placeholder='Enter price'  value={formik.values.ordinaryOut} id="ordinaryOut" name="ordinaryOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.ordinaryOut && formik.touched.ordinaryOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.ordinaryOut && formik.touched.ordinaryOut ?formik.errors.ordinaryOut:null}</span>

</div>
</div>

</div>
<div>
<div className='editRow'>

<div >
<label>Min Order Price IN Area</label>
<input placeholder='Enter price'  value={formik.values.minIn} id="minIn" name="minIn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.minIn && formik.touched.minIn ?"invalid":null}/>
<span className='errSpan'>{formik.errors.minIn && formik.touched.minIn ?formik.errors.minIn:null}</span>
</div>

<div style={{marginLeft:" 25px"}}>
<label>Min Order Price OUT Area</label>
<input placeholder='Enter price'  value={formik.values.minOut} id="minOut" name="minOut" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.minOut && formik.touched.minOut ?"invalid":null}/>
<span className='errSpan'>{formik.errors.minOut && formik.touched.minOut ?formik.errors.minOut:null}</span>

</div>
</div>
    </div>

<div>
<div className='editRow'>
<div >
<label>Branch Pickup</label>
<div className='select-container'>
  <select  value={formik.values.branchPickup} id="branchPickup" name="branchPickup" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.branchPickup && formik.touched.branchPickup ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.branchPickup && formik.touched.branchPickup?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.branchPickup && formik.touched.branchPickup?formik.errors.branchPickup:null}</span>

</div>

<div style={{marginLeft:" 25px"}}>
<label>Car Option</label>
<div className='select-container'>
  <select  value={formik.values.carOPtion} id="carOPtion" name="carOPtion" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.carOPtion && formik.touched.carOPtion ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.carOPtion && formik.touched.carOPtion?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.carOPtion && formik.touched.carOPtion?formik.errors.carOPtion:null}</span>

</div>
</div></div>






<div>
<div className='editRow'>





<div >
<label>Working From</label>
<input type="time" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
<span className='errSpan'>{formik.errors.from && formik.touched.from ?formik.errors.from:null}</span>


</div>

<div style={{marginLeft:"25px"}}>
<label>Working To</label>
<input type="time"   value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
<span className='errSpan'>{formik.errors.to && formik.touched.to ?formik.errors.to:null}</span>

</div>


</div>

</div>
<div>
<div className='editRow'>
<div >
<label>UrWay Payment</label>
<div className='select-container'>
  <select  value={formik.values.urway} id="urway" name="urway" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.urway && formik.touched.urway ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.urway && formik.touched.urway?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.urway && formik.touched.urway?formik.errors.urway:null}</span>


</div>
<div style={{margin:"0 25px"}}>
<label>Cash Payment</label>
<div className='select-container'>
  <select  value={formik.values.cash} id="cash" name="cash" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.cash && formik.touched.cash ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.cash && formik.touched.cash?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.cash && formik.touched.cash?formik.errors.cash:null}</span>

</div>

<div style={{marginRight:" 25px"}}>
<label>Wallet Payment</label>
<div className='select-container'>
  <select  value={formik.values.wallet} id="wallet" name="wallet" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.wallet && formik.touched.wallet ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.wallet && formik.touched.wallet?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.wallet && formik.touched.wallet?formik.errors.wallet:null}</span>


</div>

<div >
<label>ApplePay Payment</label>
<div className='select-container'>
  <select  value={formik.values.apple} id="apple" name="apple" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.apple && formik.touched.apple ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.apple && formik.touched.apple?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.apple && formik.touched.apple?formik.errors.apple:null}</span>

</div>

</div>
</div>

</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditBranchQr;
