 import '../styles/login.css';
import background from "../assets/Section.svg"
import logo from "../assets/logo.png"
import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useFormik} from "formik";
import { DotLoader } from 'react-spinners'
//import { login } from '../api';

import {BsGlobe} from"react-icons/bs"
function Login() {
  var dt = new Date();
  const year= dt.getFullYear()
    const [hidden,setHidden]=useState(false);

let history= useNavigate()
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [language, SetLanguage]=useState("ar");

    const [loading, setLoading]=useState(false);
    
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    
    
    
  
    let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
 
    const Schema= yup.object().shape({
      email: yup.string().required("Email is required").matches(emailRegx,"Please enter a valid email"),
      password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),
    
    })
    
    
    const formik = useFormik({
      initialValues:{
   
      email: '',
   
      password: '',

       
      },
      validationSchema: Schema,
      onSubmit,
      });



      function onSubmit(value){
        history("/dashboard")
      }
  
 


   

  

  
    return (

     <div className="login">
       <div className='container-fluid'>
         <div className='row'>
       
<div className='col-6 loginContainer'>
  <div style={{flex:1}} >
    <div className='formContainer'>
  <div className='loginLogo ' style={{ backgroundImage: `url(${logo})` }}>
  </div>
  <p id="welcome">{/*t("login.hello")*/}Log in</p>
  <p id="loginTxt">{/*t("login.again")*/} Welcome back! Please enter your details.</p>
  <form className='loginForm' onSubmit={formik.handleSubmit}>
  <label>Email</label>  
  <input placeholder='test@test.com' value={formik.values.email} id="email" name="email"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.email && formik.touched.email?formik.errors.email:null}</span>

  <label>Password</label>
  <input type="password" placeholder='Enter password' value={formik.values.password} id="password" name="password"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password ?formik.errors.password:null}</span>

  <div className='forgetDiv'>
    <div>
    <input type="checkbox"/>

      <label>Remember for 30 days</label>
    </div>
  <Link to="/forgot-password">
  <button>Forgot password?</button>
  </Link> 
  </div>

  {/*i18n.language=="ar"?*/
  1==1?
<span  style={{marginTop:"1em",textAlign:"center"}}>{errorMsgModel? msgAr:null}</span>:
<span  style={{marginTop:"1em",textAlign:"center"}}>{errorMsgModel? msgEn:null}</span>}

{loading?<div className="loaderDiv" style={{height: "100px"}}>

    <DotLoader size={30} color="#ED9434"/></div>:
  <button type='submit' id="loginBtn" >{/*t("login.login")*/}Sign in</button>}
</form></div>
<div style={{display:"flex",justifyContent:"space-between"}}>


<p id="cc">© Alromansiah {year}</p>

<p  id="cc">Powered By: <a target="_blank" style={{fontWeight:"500"}} href="https://www.bdaiat.com/"> Bdaiat Technologies</a> </p>

</div>
</div>

</div>
<div className='col-6 loginBackg' style={{ backgroundImage: `url(${background})` }}>

</div>
         </div>
       </div></div>
    );
  }
  
  export default Login;
  
  