    

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
function AddSource(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])
const [type,setType]=useState("product percentage")




useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
  
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Source</p>

<div className='maxHeight'>

<label>Name :</label>
 <input placeholder='Type Name'/>

 <label>Code :</label>
 <input placeholder='Type Name'/>

  <label>Active :</label>
    <div className='select-container'>
  <select>
      <option> Yes</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Allow Cash :</label>
    <div className='select-container'>
  <select>
      <option> Yes</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>


  <label>Allow Paid :</label>
    <div className='select-container'>
  <select>
      <option> Yes</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  
  <label>Allow Payment Online :</label>
    <div className='select-container'>
  <select>
      <option> Yes</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>





    <div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
</div>

</div>
    </div>
    </div>
    
            </div>
           
     
          
    

    );
}

export default AddSource;
 