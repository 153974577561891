import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';

import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

import { useState } from 'react';
import AddSource from '../components/AddSource';
function EditType() {
    const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 
 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Settings</p>
    <div className='navigateTop'>
    
    <Link to="/dashboard">
    
    <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
    <img src={right}/>
    <Link to="/settings">
    
    <p className='bc1'>Settings</p></Link>
    <img src={right}/>
    <Link to="/settings/types">
    
    <p className='bc2'>Order Types </p>
    </Link>
    </div>
    </div>
 
  </div>

<div  className='editContainer editAgent'>
    <div>


<p className='editTitle'>Edit order type</p>
<div className='editRow'>


<div>
    <label>Name Ar</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
<label>Name En</label>
    <input type="text" placeholder='test'/>
</div>
<div>
<label>Active </label>
<div className='select-container'>
<select>
    <option>Yes</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>

</div>

</div>

</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditType;
