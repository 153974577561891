import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import wallet from "../assets/wallet3.svg"
import logoPlain from "../assets/logoPlain.png"
import dotpoints from "../assets/dotpoints.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import chat from "../assets/chatP.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import area from "../assets/area.svg"
import file from "../assets/file.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import AwafiPoints from '../components/AwafiPoints';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {LuRectangleHorizontal} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import {IoIosCloseCircleOutline} from 'react-icons/io';

import { useState } from 'react';
import UserModal from '../components/UserModal';
function Wallets() {
  const [filter,setFilter]=useState(false)
  const [awafi,setAwafi]=useState(false)
  const [show,setShow]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
 <Navbar/>
 {awafi?<AwafiPoints setModal={setAwafi}/>:null}
 {show?<UserModal setModal={setShow}/>:null}

   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Users</p>
<div className='navigateTop'>
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
  <Link to="/users/users-list">
  <p className='bc1'>Users</p>

  </Link>
<img src={right}/>
<Link to="/users/wallets-list">

<p className='bc2'>Wallets List</p></Link>
</div>
    </div>
    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
Export Positive Balance </button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your users data</p>
<button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>
  </div>

  <div className={filter?'filtersMore':'filtersLess'}>

<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>Phone </label>
<input/>
  </div>
  
  <div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  
  
  
  <div style={{marginLeft:"8px"}} className='flex-2 alignEnd'>
  
  <button className='clear'><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  
  

</div>
 


  </div>

</div>
<div className='tableUsers'>
<table>
  <thead>
  <tr>
    <th>ID </th>
    <th>Name</th>
    <th>Phone</th>
    <th>Country</th>
    <th>Amount</th>
    <th>Type</th>
    <th>Account</th>
    <th>Date</th>
    <th>Payment Method</th>
    <th>Trans ID</th>
    <th>Order ID</th>
    <th>Admin </th>
    <th>Notes </th>
    <th>Expiry Date </th>

  </tr>
  </thead>

  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  className='tableName'>
<div   className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  
  Mariam Aly


</td>
<td>0523452799</td>
<td  className='tableName'>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 


</td>
<td>732938</td>
<td>Egypt</td>
<td>123</td>
<td>in</td>
<td>wallet</td>

<td>2023-2-3</td>
<td>tap</td>
<td>pay_y23agtvszaeelmewrtjkrlcyxq</td>
<td>123</td>
<td>Alromansiah</td>

    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  className='tableName'>
<div   className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  
  Mariam Aly


</td>
<td>0523452799</td>
<td  className='tableName'>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 


</td>
<td>732938</td>
<td>Egypt</td>
<td>123</td>
<td>in</td>
<td>wallet</td>

<td>2023-2-3</td>
<td>tap</td>
<td>pay_y23agtvszaeelmewrtjkrlcyxq</td>
<td>123</td>
<td>Alromansiah</td>


    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Wallets;
