import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import line from "../assets/line.svg"
import logoPlain from "../assets/logoPlain.png"
import sack from "../assets/sack.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import chat from "../assets/chatP.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiUsers}  from 'react-icons/fi';
import {PiUserSquare}  from 'react-icons/pi';
import {GoChecklist} from 'react-icons/go';
import {BiCategory} from 'react-icons/bi';
import {BiGlobe} from 'react-icons/bi';
import {LuImage} from 'react-icons/lu';
import {FiSettings} from 'react-icons/fi';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {LuRectangleHorizontal} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import {IoIosCloseCircleOutline} from 'react-icons/io';

import { useState } from 'react';
function ExternalSales() {
  const [filter,setFilter]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Admins</p>

<div className='navigateTop'>
  <Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
<img src={right}/>
<Link to="/admins/admins-list">

<p className='bc1'>Admin</p></Link>
<img src={right}/>
<Link to="/admins/external-sales">
<p className='bc2'>External Sales List</p>
</Link>
</div>

    </div>
  
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Here is your list data</p>
  </div>

  <div className='filtersMore'>
<div className='filtersContainer'>
  
<div className='flex-2' style={{marginRight:"70px"}}>
  <label>Search </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
<label>Show </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>
<div className='flex-1'>

</div>
<div className='flex-1'>

</div>


  </div>


  </div>

</div>
<div className='tableUsers'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>Name</th>
    <th>Phone Number</th>
    <th>Facility Name</th>
    <th>Facility Activity</th>
    <th>City</th>
    <th>Status</th>
    <th>Type</th>
  

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td style={{color:"#F50A66"}}>
<Link style={{color:"unset"}} to="/admins/external-sales/5">
Mariam Aly
</Link>  
  </td>
<td>0523452799</td>
<td>الرومانسيه</td>
<td>Company</td>
<td>Al-Kharj</td>
<td>Approved</td>

<td>Approved</td>

    </tr>
   
 
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td style={{color:"#F50A66"}}>
<Link style={{color:"unset"}} to="/admins/external-sales/5">
Mariam Aly
</Link>  
  </td><td>0523452799</td>
<td>الرومانسيه</td>
<td>Company</td>
<td>Al-Kharj</td>
<td>Approved</td>

<td>Approved</td>

    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default ExternalSales;
