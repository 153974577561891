     

 import mapLogo from "../assets/mapLogo.png"

import React, {useEffect,useRef, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"

import {GrFormClose} from 'react-icons/gr';
const libraries = ['places'];

function BranchMap(props) {

    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries:libraries
    })
    const markerRef=useRef()

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])

const [position,setPostion]=useState(

    {
  lat:parseFloat(43.4),
  lng:parseFloat(43.4)
  
  
    }
  )  
  

  useEffect(()=>{
    window.scrollTo(20000, 0);
  },[])

  



  function onMarkerDragEnd(evt){

    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();

   setPostion({
  
    lat:newLat,
    lng:newLng
   })
  
  };




    return ( 
      
 
 
        <div  className="AddModal branchMap" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"95vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
          

<p className='modalTitle'>Branch Map :</p>
  {isLoaded?
<GoogleMap zoom={8} 
    center={{ lat: position.lat, lng: position.lng}}

         mapContainerClassName="map"

    options={{
        mapTypeControl: false
    }}
    > 
<MarkerF
icon={mapLogo}
draggable={true}
position={{ lat: position.lat, lng: position.lng}}

onDragEnd={(e) =>onMarkerDragEnd(e)}

/>

</GoogleMap>:null}
 

</div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default BranchMap;
 