import '../styles/categories.css';
import pp from "../assets/categories.png"

import meal from "../assets/meal.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import AddCategory from '../components/AddCategory';

import { useState } from 'react';

function CategoryMeals() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)

  return (
    <div className='dashboardContainer  usersContainer'>

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Categories</p>
<div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/categories">

<p className='bc1'>Categories</p></Link>
<img src={right}/>
<Link to="/categories">

<p className='bc1'>Categories List</p></Link>
<img src={right}/>
<Link to="/categories/meals">

<p className='bc2'>Italian food category</p></Link>
</div>
    </div>
    <button  className='export'>

Save</button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Meals Arrange List</p>
  </div>


</div>
<div className='tableUsers categoryTable adminTable CountryTable'>

<table >
<thead>
  <tr>
    <th>Meals</th>
    <th style={{width:"50%"}}>Arrange</th>
  
  </tr>
  </thead>
  <tbody>
    <tr>
<td >Shrimp paella one</td>
<td  >
<input type="number" placeholder='9'/>
</td>

    </tr>
    <tr>
<td >Spanish Meals</td>
<td  >
<input type="number" placeholder='9'/>
</td>
  </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default CategoryMeals;
