import '../styles/categories.css';
import pp from "../assets/categories.png"

import meal from "../assets/meal.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import { useState } from 'react';

import AddMdiafCategory from '../components/AddMdiafCategory';
function MdiafInquires() {
  const [filter,setFilter]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Mdiaf</p>
<div className='navigateTop'>
  
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/mdiaf/menu">

<p className='bc1'>Mdiaf</p></Link>

<img src={right}/>
<Link to="/mdiaf/inquires">

<p className='bc2'>Inquires List</p>
</Link>
</div>
    </div>
   
  </div>

<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your Inquires data</p>
  </div>

  <div className='filtersMore'>
<div className='filtersContainer'>
  
<div className='flex-2' style={{marginRight:"70px"}}>
  <label>Search </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>

  <div className='flex-1' >
<label>Show </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>
<div className='flex-2' >
</div>
  
  </div></div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>User Names </th>
    <th>Notes </th>
    <th>Created At</th>
  </tr>
  </thead>
  <tbody>
    <tr>
<td >
    <Link style={{color:"#101828",textDecoration:"none"}} to="/mdiaf/inquires/5">    3066

    </Link>
    
    </td>
<td  >Spanish Meals</td>
<td  >Spanish Meals</td>
<td  >23/34/23 </td>
  
    </tr>
    <tr>
<td > <Link style={{color:"#101828",textDecoration:"none"}} to="/mdiaf/inquires/5">    3066

</Link></td>
<td  >Spanish Meals</td>
<td  >Spanish Meals</td>
<td  >23/34/23 </td>
  
    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default MdiafInquires;
