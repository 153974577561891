import '../styles/users.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"

import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import saudi from "../assets/saudi.svg"

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import { Link } from 'react-router-dom';

import {FiSearch} from 'react-icons/fi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

import { useState } from 'react';
import AddSource from '../components/AddSource';
import AddAppVersion from '../components/AddAppVersion';
function EditAppVersion() {
    const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
 {add?<AddAppVersion setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div> 
 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Settings</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/settings">

<p className='bc1'>Settings</p></Link>
<img src={right}/>
<Link to="/settings/versions">

<p className='bc2'>App Versions List</p></Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Update </button>
  </div>

<div  className='editContainer editAgent'>
    <div>


<p className='editTitle'>Edit App Version</p>
<div className='editRow'>


<div>
    <label>Platform </label>
    <div className='select-container'>
<select>
    <option>Andriod</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div style={{margin:"0 25px"}}>
    <label>Version </label>
    <input  type="text" placeholder='4.3.4'/>
</div>

<div></div>
</div>

</div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditAppVersion;
