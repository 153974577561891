import '../styles/users.css';
import logoPlain from "../assets/logoPlain.png"
import pin from "../assets/pin.svg"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import AddMeal from '../components/AddMeal';
import { useState } from 'react';
import { Link } from 'react-router-dom';
function EditMeal() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])



  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddMeal setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Products</p>
<div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/products">

<p className='bc1'>Products</p></Link>
<img src={right}/>
<Link to="/products/meals-list">

<p className='bc2'>Meals List</p></Link>


</div>
    </div>
   
  </div>
 

<div style={{height:"80%"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Meal Data</p>
<div className='editRow'>


<div>
    <label>Product :</label>
    <div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div style={{margin:"0 25px"}}>
    <label>Size :</label>
    <div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div>
    <label>Additional :</label>
    <div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>

</div>

<div>
<div className='editRow'>


<div>
    <label>Default Price SAR</label>
    <input placeholder='100'/>

</div>


<div style={{margin:"0 25px"}}>
<label>Default Aggregators Price SAR</label>
<input placeholder='100'/>

</div>
<div>
<label>Code :</label>
<input placeholder='100'/>

</div>

</div>
</div>
<div>


<div className='editRow'>
<div >
    <label>Calories :</label>
 <input placeholder='100'/>
</div>

<div >
</div>
<div >
</div>

</div>
</div>  </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditMeal;
