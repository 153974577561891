   

 
import React, {useEffect,useRef, useState} from 'react';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import {useFormik} from "formik";
import * as yup from "yup"
function AddCountry(props) {


let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
let decimalRegx= /^\d*\.?\d*$/
    const Schema= yup.object().shape({
        nameAr: yup.string().required("Country name is required").matches(nameRegEx,{message:("Country name should contain letters only")}),
        nameEn: yup.string().required("Country name is required").matches(nameRegEx,{message:("Country name should contain letters only")}),
        phone: yup.string().required("Phone number is required").matches(phoneRegEx,"Enter a valid phone number"),
        code:yup.string().required("Iso code is required").matches(nameRegEx,{message:("Code must contain letters only")}),
        currencyAr: yup.string().required("Currency  is required"),
        currencyEn:yup.string().required("Currency is required"),
        rate: yup.string().required("Exchange rate is required").matches(decimalRegx,{message:("Exchange rate must contain digits and decimal numbers only")}),

        })


        const formik = useFormik({
          initialValues:{
            nameAr: '',
            nameEn: '',
            phone: '',
          code: '',
          currencyAr: '',
          currencyEn: '',
          rate: '',

           
          },
          validationSchema: Schema,
          onSubmit,
          });



          function onSubmit(value){
          }

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

const uploadImg= useRef()
const [image,setImage]=useState()
const [preview,setPreview]=useState()
const[errMsg,setErrMsg]=useState()
const[err,setErr]=useState(false)


useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setErr(false)
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
 
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
        setErr(true)
        setErrMsg("Please drop only one image file");
      }
    
      };

    return ( 
      
   
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Country</p>
<div className='maxHeight'>

<label>Name Ar:</label>
<input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>

    <label>Name En:</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>

    <label>Phone:</label>
    <input placeholder='Enter phone number' value={formik.values.phone} id="phone" name="phone" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.phone && formik.touched.phone?formik.errors.phone:null}</span>

    <label>ISO Code :</label>
    <input placeholder='Enter code' value={formik.values.code} id="code" name="code" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.code && formik.touched.code ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.code && formik.touched.code?formik.errors.code:null}</span>

    <label>Currency Ar :</label>
    <input placeholder='Enter currency' value={formik.values.currencyAr} id="currencyAr" name="currencyAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.currencyAr && formik.touched.currencyAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.currencyAr && formik.touched.currencyAr?formik.errors.currencyAr:null}</span>

    <label>Currency En :</label>
    <input placeholder='Enter currency' value={formik.values.currencyEn} id="currencyEn" name="currencyEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.currencyEn && formik.touched.currencyEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.currencyEn && formik.touched.currencyEn?formik.errors.currencyEn:null}</span>

    <label>Exchange from SAR :</label>
    <input placeholder='Enter currency' value={formik.values.rate} id="rate" name="rate" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.rate && formik.touched.rate ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.rate && formik.touched.rate?formik.errors.rate:null}</span>

    <label>Image:</label>
    <div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>   

  
           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
            </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddCountry;
 