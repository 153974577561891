  
    

 
import React, {useEffect, useState,useRef} from 'react';
import { BeatLoader } from 'react-spinners';
import {BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import qr from "../assets/qr.svg"

function Tables(props) {

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])


const[errMsg,setErrMsg]=useState()
const[err,setErr]=useState(false)
const uploadImg= useRef()
const [image,setImage]=useState()
const [preview,setPreview]=useState()




useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setErr(false)
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
 
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
        setErr(true)
        setErrMsg("Please drop only one image file");
      }
    
      };


    return ( 
      
 
 
        <div  className="AddModal tablesModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCateg" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
         <div className='flexBetween' style={{marginBottom:"48px"}}>
             <div>
             <label>Search for order</label>
    <input style={{width:"28vw"}} placeholder='Enter name'/>
             </div>
 
    <button className='insert' >Insert</button>
             </div>    
<table>
    <thead>
        <tr>

        <th>ID Number</th>
        <th>Status</th>
        <th style={{textAlign:"center"}}>Qr</th>
            
        </tr>
    </thead>
    <tbody>
        <tr>
<td>3066</td>
<td >
<div className="status ">
    <RxDotFilled size={20}/>
    Accepted
        </div>
   
</td><td className='centerData'>
  <button style={{border:"none",background:"transparent"}} onClick={()=>{props.setQrModal(true); props.setModal(false)}}>
    <img src={qr}/>
    </button>
  </td>
        </tr>
        <tr>
<td>3066</td>
<td >
<div className="status statusOrange">
    <RxDotFilled size={20}/>
    Busy
        </div>
   
</td><td className='centerData'>
  <button style={{border:"none",background:"transparent"}} onClick={()=>{props.setQrModal(true); props.setModal(false)}}>
    <img src={qr}/>
    </button>
  </td>
        </tr>
    </tbody>
</table>

            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default Tables;
 