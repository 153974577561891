import '../styles/orders.css';
import logo from "../assets/navLogo.png"
import pp from "../assets/pp.svg"
import branch from "../assets/branch.svg"
import cart from "../assets/cart.svg"
import sale from "../assets/sale.svg"
import area from "../assets/area.svg"
import logoPlain from "../assets/logoPlain.png"
import saudi from "../assets/saudi.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import globe from "../assets/globe.svg"
import { Link } from 'react-router-dom';
import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart } from 'react-minimal-pie-chart';
import { Rating } from 'react-simple-star-rating'
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import Invoice from '../components/Invoice';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {RxDotFilled} from 'react-icons/rx';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {IoIosCloseCircleOutline} from 'react-icons/io';
import {BsCheck} from 'react-icons/bs';
import UserModal from '../components/UserModal';
import { useState } from 'react';

import AddArea from '../components/AddArea';
function Orders() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [invoice,setInvoice]=useState(false)
  const [show,setShow]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
      {add?<AddArea setModal={setAdd}/>:null}
      {invoice?<Invoice setModal={setInvoice}/>:null}
      {show?<UserModal setModal={setShow}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Orders</p>
<div className='navigateTop'>
  
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>
</Link>
  <img src={right}/>
  <Link to="/orders">

<p className='bc1'>Order</p></Link>
<img src={right}/>
<Link to="/orders">

<p className='bc2'>Order List</p>
</Link>
</div>
    </div>
    <div className='ordersBtns'>

    <button className='export'>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_331_16642)">
    <path d="M7.26306 10.9858L10.5964 14.3191M10.5964 14.3191L13.9297 10.9858M10.5964 14.3191V6.65248C10.5964 5.49355 10.5964 4.91409 10.1376 4.26531C9.83282 3.83424 8.95521 3.30221 8.43204 3.23133C7.64464 3.12464 7.34562 3.28063 6.74759 3.59259C4.08251 4.98284 2.26306 7.77185 2.26306 10.9858C2.26306 15.5882 5.99402 19.3191 10.5964 19.3191C15.1988 19.3191 18.9297 15.5882 18.9297 10.9858C18.9297 7.9013 17.2539 5.2082 14.7631 3.76733" stroke="#FCFCFD" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_331_16642">
      <rect width="20" height="20" fill="white" transform="translate(0.596436 0.98584)"/>
    </clipPath>
  </defs>
</svg>
Export   </button>
<button className='sync'>
    Sync
</button>
</div>

  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your order list data</p>
<button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>
  </div>

  <div className={filter?'filtersMore':'filtersLess'}>
<div className='filtersContainer'>
  
<div className='flex-2' >
  <label>Search for order</label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
  <label>Transaction id </label>
  <input/>
  </div>
 
  <div className='flex-1' >
  <label>id </label>
  <input />
  </div>
  <div className='flex-1' >
  <label>External id </label>
  <input />
  </div>
  <div>

     
  </div>

  </div>
  
  <div style={{marginTop:"16px"}}>
<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>Payment </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  <div  className='flex-1' >
  <label>Status </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  <div  className='flex-1' >
  <label>Types </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  <div  className='flex-1' >
  <label>Agents </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  <div  className='flex-1' >
  <label>Sources </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  </div>
</div>
  

<div style={{marginTop:"16px"}}>
<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>Branch </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  <div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  
  <div  className='flex-1' >
  <label>Platform </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  <div  className='flex-1' >
  <label>Codes </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>

  </div>
  </div>

  <div style={{marginTop:"16px"}}>
<div className='filtersContainer'>
  

  <div style={{margin:"0"}} className='flex-1' >
  <label>Sync </label>
  <div className='select-container'>
  <select>
      <option>All</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  </div>
  
  <div  className='flex-1' >
 
  </div>

  <div  className='flex-1' >

  </div>
  
 

  <div style={{marginLeft:"8px"}} className='flex-2 alignEnd'>
  
  <button className='clear'><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>
  
  
  </div>
  </div>

  </div>

</div>
<div className='tableUsers adminTable CountryTable'>

<table>
<thead>
  <tr>
    <th className='alignCheck'>
    <div className='containCheck'>
    <div> 
<input type="checkbox"  id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>

        ID Number</th>

    <th>Customer</th>
    <th>Status </th>
    <th>Branch  </th>
    <th>Payment  </th>
    <th style={{textAlign:"center"}}>Invoice </th>
    <th>Platform </th>
    <th>Sync</th>
    <th>Notes</th>
    <th>Ordered At</th>
    <th>Created By</th>
    <th>Canceled By</th>
    <th>Order Date</th>
    <th>Order Time</th>
    <th>Rate</th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}} className='alignCheck' ><div className='containCheck'>
    <div> 
<input type="checkbox" id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div> 
    
    <Link style={{color:"unset"}} to="5">
    3066
    </Link>
    
    
    </td>

    <td className='tableName' style={{cursor:"pointer"}} onClick={()=>setShow(true)}>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 



</td>
<td>
    <div className="status">
    <RxDotFilled size={20}/>
    Accepted
        </div>

</td>
<td>Rawabi</td>
<td>Cash</td>
<td  className='centerData'>
<button style={{border:"none",background:"transparent"}} onClick={()=>setInvoice(true)}>
  <img  src={calender}/>

  </button>
    </td>
<td>web</td>
<td>YES</td>
<td>YES</td>
<td>12-123-123</td>
<td>YES</td>
<td>YES</td>
<td>Now</td>
<td>Now</td>
<td>5</td>




    </tr>
    <tr>
<td style={{color:"#101828"}} className='alignCheck' ><div className='containCheck'>
    <div> 
<input type="checkbox" id="supervisor" className="chooseClass" value="Other"  />
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div> 
    
    <Link style={{color:"unset"}} to="5">
    3066
    </Link>
    
    
    </td>

    <td className='tableName' style={{cursor:"pointer"}} onClick={()=>setShow(true)}>
<div className='tableImg' style={{ backgroundImage: `url(${pp})` }}>
  </div>
  <div>
  Mariam Aly
  <br/>
  <span>xmariam.aly@gmail.com</span>
  </div>
 



</td>
<td>
    <div className="status">
    <RxDotFilled size={20}/>
    Accepted
        </div>

</td>
<td>Rawabi</td>
<td>Cash</td>
<td  className='centerData'>
<button style={{border:"none",background:"transparent"}} onClick={()=>setInvoice(true)}>
  <img  src={calender}/>

  </button>
    </td>
<td>web</td>
<td>YES</td>
<td>YES</td>
<td>12-123-123</td>
<td>YES</td>
<td>YES</td>
<td>Now</td>
<td>Now</td>
<td>5</td>




    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Orders;
