import '../styles/login.css';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import {HiOutlineKey} from 'react-icons/hi';
import PasswordContainer from '../components/PasswordContainer';
import * as yup from "yup"

import {useFormik} from "formik";
function SetNewPassword() {


  const Schema= yup.object().shape({
   password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),

    confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Password confirmation does not match the password").required("Password confirmation is required")
    })

    const formik = useFormik({
        initialValues:{
    
        password: '',
        confirmPassword: '',

         
        },
        validationSchema: Schema,
        onSubmit,
        });



        function onSubmit(value){
        }


  return (
 <PasswordContainer
 title="Set new password"
 text="Your new password must be different to previously used passwords"
 icon={ <HiOutlineKey color='#D16700'size={23}/>}
 >
  
  <label>Password</label>
  <input type="password" placeholder='Enter name' value={formik.values.password} id="password" name="password"   required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password ?formik.errors.password:null}</span>

  <label className='confirmPass'>Confirm Password</label>
  <input type="password" placeholder='Enter name' value={formik.values.confirmPassword} id="confirmPassword" name="confirmPassword"   required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.confirmPassword && formik.touched.confirmPassword?formik.errors.confirmPassword:null}</span>
  
  <Link to="/password-reset">
  <button id="loginBtn">Reset password</button>

  </Link>
  <Link to="/">

    <button className='bck' ><BiArrowBack size={18} color='#475467'/> Back to log in</button>
 </Link>
    </PasswordContainer>
  );
}

export default SetNewPassword;
