     

 
import React, {useEffect,useRef, useState} from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {FiUploadCloud} from 'react-icons/fi';
import ReactQuill from 'react-quill';

import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
function AddPost(props) {


    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()
    const [text, setText] = useState('');
    const [text2, setText2] = useState('');

       const modules = {
               toolbar: [
                   [{size: []},'bold', 'italic', 'link',{ 'align': [] } ,{'list': 'bullet'}]
            
               ]
         };
       
         const formats = [
           'size','align', 'bold', 'italic',
           'list', 'bullet',
           'link', 
         ];
       
         const handleTextChange = (value) => {
           setText(value);
         };
       
         const handleTextChange2 = (value) => {
            setText2(value);
          };
    
    
    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
     
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal addPost maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"52vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Post</p>
<div className='maxHeight'>

<label>Title Ar :</label>
<input placeholder='Type Name Ar'/>

<label>Title En :</label>
<input placeholder='Type Name En'/>

<label>Description Ar :</label>
<div className='charsDiv'>

<ReactQuill
   theme="snow"
   modules={modules}
   formats={formats}
   value={text}
   
   onChange={handleTextChange}
   />
   <p className='chars'>275 characters left</p>

</div>
<label>Description En :</label>
<div className='charsDiv'>

<ReactQuill
   theme="snow"
   modules={modules}
   formats={formats}
   value={text2}
   
   onChange={handleTextChange2}
   />
   <p className='chars'>275 characters left</p>
</div>
<label>Type :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>


<label>Image :</label>
    <div id="uploadImg" style={{marginBottom:"24px"}}
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p style={{marginBottom:"0"}}><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>

    <div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>

    
            </div>
           
     </div>
            </div>
          
          </div>
        </div>
    

    );
}

export default AddPost;
 