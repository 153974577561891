import '../styles/users.css';

import logoPlain from "../assets/logoPlain.png"
import pin from "../assets/pin.svg"
import right from "../assets/right.svg"
import {useFormik} from "formik";
import * as yup from "yup"
import { Link } from 'react-router-dom';

import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';

import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';


import { useState,useRef } from 'react';
import DropDown from '../components/DropDown';
import AddAdmin from '../components/AddAdmin';
function EditAdmin() {
  const [filter,setFilter]=useState(false)
  const [add,setAdd]=useState(false)
  const [open,setOpen]=useState(false)
  const [checkList,setCheckList]=useState([])
  const adminRef=useRef()
  const emailRef=useRef()
  const passRef=useRef()
  const confirmPassRef=useRef()
  
  let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
  
      const Schema= yup.object().shape({
          admin: yup.string().required("Admin name is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Admin name should contain letters only")}),
          email: yup.string().required("Email is required").matches(emailRegx,"Please enter a valid email"),
          password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),
     
          confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Password confirmation does not match the password").required("Password confirmation is required")
          })
  
          const formik = useFormik({
              initialValues:{
              name: '',
              email: '',
           
              message: '',
  
               
              },
              validationSchema: Schema,
              onSubmit,
              });

function onSubmit(){

}
  return (
    <div className='dashboardContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddAdmin setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Admins</p>
<div className='navigateTop'>
<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link><img src={right}/>
<Link to="/admins/admins-list">

<p className='bc1'>Admin</p></Link>
<img src={right}/>
<Link to="/admins/admins-list">
<p className='bc2'>Admins List</p>
</Link>

</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Admin  </button>
  </div>
 

<div style={{height:"85%"}}  className='editContainer editAgent'>
  <div>

 
<p className='editTitle'>Edit admin data</p>
<div className='editRow'>


<div>
    <label>Name</label>
    <input placeholder='Enter name'  value={formik.values.admin} id="admin" name="admin"  ref={adminRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.admin && formik.touched.admin ?"invalid":null}/>
<span className='errSpan'>{formik.errors.admin && formik.touched.admin ?formik.errors.admin:null}</span>

</div>
<div style={{margin:"0 25px"}}>
    <label>Email</label>
    <input placeholder='test@test.com' value={formik.values.email} id="email" name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.email && formik.touched.email?formik.errors.email:null}</span>
</div>
<div>
    <label>Password</label>
    <input type="password" placeholder='Enter name' value={formik.values.password} id="password" name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password ?formik.errors.password:null}</span>
</div>

</div>

<div>
<div className='editRow'>


<div>
    <label>Password Confirm</label>
    <input type="password" placeholder='Enter name' value={formik.values.confirmPassword} id="confirmPassword" name="confirmPassword"  ref={confirmPassRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.confirmPassword && formik.touched.confirmPassword?formik.errors.confirmPassword:null}</span>

</div>


<div style={{margin:"0 25px"}}>
    <DropDown label="Permissions"/>
</div>
<div >
    <DropDown label="Branch"/>
   
</div>
</div>
</div>
 </div>
<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditAdmin;
