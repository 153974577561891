      

 
import React, {useEffect,useRef, useState} from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {FiUploadCloud} from 'react-icons/fi';
import ReactQuill from 'react-quill';

import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
function AddNewsPost(props) {


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal addPostCs  maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"52vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Post</p>
<div className='maxHeight'>

<label>Description Ar :</label>
<textarea />
<p className='chars'>275 characters left</p>


<label>Description En :</label>
<textarea />
<p className='chars'>275 characters left</p>


<label>Active :</label>
    <div className='select-container'>
<select>
    <option>Yes</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>



    <div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
</div>
    
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddNewsPost;
 