   

 
import React, {useEffect, useRef,useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';
import {useFormik} from "formik";
import * as yup from "yup"
import {GrFormClose} from 'react-icons/gr';
import DropDown from './DropDown';

function AddAdmin(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])
const adminRef=useRef()
const emailRef=useRef()
const passRef=useRef()
const confirmPassRef=useRef()

let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

    const Schema= yup.object().shape({
        admin: yup.string().required("Admin name is required").min(6,"Minimum number of characters is 6").max(70,"Maximum number of characters is 70").matches(nameRegEx,{message:("Admin name should contain letters only")}),
        email: yup.string().required("Email is required").matches(emailRegx,"Please enter a valid email"),
        password: yup.string().min(6, ("Password has to be at least 6 characters")).required(("Password is required")),
   
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Password confirmation does not match the password").required("Password confirmation is required")
        })

        const formik = useFormik({
            initialValues:{
            name: '',
            email: '',
         
            password: '',
            confirmPassword: '',

             
            },
            validationSchema: Schema,
            onSubmit,
            });



            function onSubmit(value){
            }


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Admin</p>
<div className='maxHeight'>

<label>Name:</label>
    <input placeholder='Enter name'  value={formik.values.admin} id="admin" name="admin"  ref={adminRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.admin && formik.touched.admin ?"invalid":null}/>
<span className='errSpan'>{formik.errors.admin && formik.touched.admin ?formik.errors.admin:null}</span>



    <label>Email:</label>
    <input placeholder='test@test.com' value={formik.values.email} id="email" name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.email && formik.touched.email?formik.errors.email:null}</span>

    <label>Password:</label>
    <input type="password" placeholder='Enter name' value={formik.values.password} id="password" name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.password && formik.touched.password ?formik.errors.password:null}</span>


    <label>Confirm Password:</label>
    <input type="password" placeholder='Enter name' value={formik.values.confirmPassword} id="confirmPassword" name="confirmPassword"  ref={confirmPassRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.confirmPassword && formik.touched.confirmPassword?formik.errors.confirmPassword:null}</span>



 
  <DropDown label="Permissions:"/>

    <DropDown label="Branch:"/>


   

  
           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddAdmin;
 