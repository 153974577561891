import '../styles/orders.css';

import logoPlain from "../assets/logoPlain.png"
import car from "../assets/car.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import Navbar from '../components/Navbar';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"
import Status from '../components/Status';
import {RiHome6Line}  from 'react-icons/ri';
import Invoice from '../components/Invoice';
import {FiSearch} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import UserModal from '../components/UserModal';
import { useState } from 'react';
import { GoDotFill} from "react-icons/go";
import AddArea from '../components/AddArea';
function MdiafInquiresDetails() {
 
  const [status,setStatus]=useState(false)



  return (
    <div className='dashboardContainer inquireContainer usersContainer'>
      {status?<Status setModal={setStatus}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div style={{height:"inherit"}}>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

 
  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Mdiaf</p>
<div className='navigateTop'>
  
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/mdiaf/menu">

<p className='bc1'>Mdiaf</p></Link>

<img src={right}/>
<Link to="/mdiaf/inquires">

<p className='bc1'>Inquires List</p>
</Link>
<img src={right}/>
<Link to="/mdiaf/inquires/5">

<p className='bc2'>Inquired Details</p>
</Link>

</div>
    </div>
   
  </div>

<div className='orderContainer'>
    <div className=' container-fluid orderPadding'>


    <div className='row gy-4'>

    
            <div className='col-12'>
<div className='odTable'>

<div className='odTable-flex'>
<p>Inquire #10932 Details:</p>
<div>

</div>
</div>
<div className='odTableContainer'>
<table>
    <thead>
        <tr>

        <th>Product Name</th>
        <th>Quantity</th>
        <th>Price</th>
            
        </tr>
    </thead>
    <tbody>
        <tr>
<td>حلوى الشوروز</td>
<td>5</td>
<td>100</td>
        </tr>
        <tr className='lastRow'>
<td>حلوى الشوروز</td>
<td>5</td>
<td>100</td>
        </tr>
    </tbody>
</table>
</div>
</div>


            </div>

            <div className='col-4'>
<div className='orderDetContainer'>
<p className='orderDet-title'>Shipping information</p>
<p>Inquire #13 Details</p>
<p>Inquires ID: <span>Alo Mariam Test</span></p>
<p>User Name: <span>234 test yes test</span></p>
<p>Address: <span>732 Mahmoud Shoukry street</span></p>
<p>Notes: <span>Home Delivery</span></p>
<p>Phone: <span>04728372</span></p>
<p>Inquired At: <span>12:21 am</span></p>
</div>
            </div>
        

        </div>    </div>
    </div>
  </div>
 


  </div>

   </div>
    </div>
  );
}

export default MdiafInquiresDetails;
