    

 
import React, {useEffect, useState} from 'react';
import { BiChevronDown } from 'react-icons/bi';

function AddSize(props) {

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal AddAdditional" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCity" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Size</p>
<label>Name Ar:</label>
    <input placeholder='Enter name'/>

    <label>Name En:</label>
    <input placeholder='Enter name'/>

  
           <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddSize;
 