import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import AddOffer from '../components/AddOffer';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import DropDown from "../components/DropDown";
function EditOffer() {
  const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddOffer setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Offers</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/promotions/offers">

<p className='bc1'>Offers</p></Link>
<img src={right}/>
<Link to="/promotions/offers">

<p className='bc2'>Offers List</p></Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Offer </button>
  </div>
 

<div style={{height:"73%"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Offer Data</p>
<div className='editRow'>

<div>
    <label>Name :</label>
    <input type="text" placeholder='test'/>
</div>
<div style={{margin:"0 25px"}}>
<label>Conditions Type :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>

<div >
<label>Method :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>


</div>

<div>
<div className='editRow'>

<div style={{maxWidth:"31.5%"}}>

<DropDown/>
</div>

<div style={{margin:"0 25px",maxWidth:"31.5%"}}>

<DropDown/>
</div>


<div>
<label>Amount</label>
    <input type="number" placeholder='test'/>

</div>

</div>


</div>
<div>


<div className='editRow'>
<div >
<label>Uses</label>
    <input placeholder="Enter uses"/>
</div>


<div style={{margin:"0 25px"}}>
<label>From</label>
    <div className='dateDiv'>
    <input type="date"/>
    <img src={calender}/>
    </div>
</div>

<div >
<label>Expiry Date</label>
    <div className='dateDiv'>
    <input type="date"/>
    <img src={calender}/>
    </div>
</div>

</div>
<div className='editRow'>

<div >

<DropDown/>
</div>

</div> 
</div> 
<p className="editQuan">Edit Quantity Data</p>

<div className='editRow'>

<div>
    <label>Mix grill Quarter Bashawer Rice :</label>
    <input type="text" placeholder='test'/>
</div>

<div  style={{margin:"0 25px"}}>
    <label>Mix grill Quarter Bashawer Rice :</label>
    <input type="text" placeholder='test'/>
</div>

<div  style={{margin:"0 25px"}}>
    <label>Mandi Goat Meal Saudi Rice :</label>
    <input type="text" placeholder='test'/>
</div>


</div>

 </div>


<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditOffer;
