   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
import {useFormik} from "formik";
import * as yup from "yup"
function AddOffer(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])
const [type,setType]=useState("product percentage")
const [method,setMethod]=useState("3")


let numRegEx =/^[0-9]+$/;

const Schema= yup.object().shape( 
 {
    type: yup.string().required("Type is required"),
    method: yup.string().required("Method is required"),
    amount:method =="3"? null: yup.string().required("Amount is required").matches(numRegEx,{message:("Amount should contain digits only")}),
            name: yup.string().required("Offer name is required"),
            uses: yup.string().required("Number of uses is required").matches(numRegEx,{message:("Number of uses should contain digits only")}),
            from: yup.string().required("Time from is required"),
            to: yup.string().required("Time to is required"),
            limit: method =="1" || method =="2"? yup.string().required("Limit is required").matches(numRegEx,{message:("Limit should contain digits only")}):null,

  }
    )


    const formik = useFormik({
      initialValues:{
        code: '',
        type: '',
        amount: '',
        uses:'',
        from:'',
        to:'',
        limit:''
      },
      validationSchema: Schema,
      onSubmit,
      });



      function onSubmit(value){
      }



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])


    return ( 
      
  
 
        <div  className="AddModal AddOffer maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"63vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Offer</p>
<div className='maxHeight'>

<div className='container-fluid'>
<div className='row'>
<div className='col-6'>

<label>Type:</label>
    <div className='select-container'>
    <select value={formik.values.type} id="type" name="type" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.type && formik.touched.type ?"invalid":null}>
      <option>Direct</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.type && formik.touched.type?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.type && formik.touched.type?formik.errors.type:null}</span>


  <label>Method:</label>
    <div className='select-container'>
    <select  value={formik.values.method} id="method" name="method" required={true} onChange={(e)=>{formik.handleChange(e); setMethod(e.target.value)}} onBlur={formik.handleBlur}  className={formik.errors.method && formik.touched.method ?"invalid":null}>
    <option value="0">Fixed Amount</option>

      <option value="1">Fixed Amount</option>
      <option value="2">Fixed Amount</option>
      <option value="3">Fixed Amount</option>

    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.method && formik.touched.method?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.method && formik.touched.method?formik.errors.method:null}</span>



{method=="3"?null:
<>
    <label>Amount:</label>
    <input  type="number" placeholder='Enter amount' value={formik.values.amount} id="amount" name="amount" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.amount && formik.touched.amount ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.amount && formik.touched.amount?formik.errors.amount:null}</span>
    </>
    }

<label>Number of uses :</label>
    <input  type="number" placeholder='Enter number' value={formik.values.uses} id="uses" name="uses" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.uses && formik.touched.uses ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.uses && formik.touched.uses?formik.errors.uses:null}</span>

    <label>Working From :</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.from} id="from" name="from" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.from && formik.touched.from ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span style={formik.errors.from && formik.touched.from?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.from && formik.touched.from?formik.errors.from:null}</span>



    {method=="3"?

<DropDown label="Free meals:"/>
:null}


    <div className='modalBtns' style={{marginTop:"32px"}}>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
</div>
<div className='col-6'>

<DropDown label="Conditions:"/>


<DropDown label="Branches:"/>
<label>Offer name :</label>
<input  placeholder='Enter name' value={formik.values.name} id="name" name="name" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.name && formik.touched.name ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.name && formik.touched.name?formik.errors.name:null}</span>


    {method=="3"?
<>


  
<label>Expiry Date :</label>
    <div className='dateDiv'>
    <input type="date" value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
    <img src={calender}/>
    </div>
    <span style={formik.errors.to && formik.touched.to?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.to && formik.touched.to?formik.errors.to:null}</span>
 

    <DropDown label="Applications:"/>

    </>
:
<>

<DropDown label="Applications:"/>

  
<label>Expiry Date :</label>
<div className='dateDiv'>
<input type="date" value={formik.values.to} id="to" name="to" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.to && formik.touched.to ?"invalid":null}/>
<img src={calender}/>
</div>
<span style={formik.errors.to && formik.touched.to?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.to && formik.touched.to?formik.errors.to:null}</span>
</>
}

 
  {method=="1" || method=="2"?
  <>

<label>Limit</label>
<input  type="number" placeholder='Enter number' value={formik.values.limit} id="limit" name="limit" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.limit && formik.touched.limit ?"invalid":null}/>
    <span  className='errSpan'>{formik.errors.limit && formik.touched.limit?formik.errors.limit:null}</span>
    </>:null}

</div>

    </div>
    </div>
    
            </div>
           
     
            </div>
          
          </div>
        </div>
    </div>

    );
}

export default AddOffer;
 