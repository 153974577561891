   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';

import {GrFormClose} from 'react-icons/gr';
import {useFormik} from "formik";
import * as yup from "yup"
function AddCity(props) {


  let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

      const Schema= yup.object().shape({
          nameAr: yup.string().required("City name is required").matches(nameRegEx,{message:("City name should contain letters only")}),
          nameEn: yup.string().required("City name is required").matches(nameRegEx,{message:("City name should contain letters only")}),

          })
  
          const formik = useFormik({
            initialValues:{
              nameAr: '',
              nameEn: '',           
            },
            validationSchema: Schema,
            onSubmit,
            });
  
  
  
            function onSubmit(value){
            }

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCity" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add City</p>
<label>Name Ar:</label>
<input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>

    <label>Name En:</label>
    <input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>


  
           <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddCity;
 