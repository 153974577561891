   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"

function AddWallet(props) {

const history= useNavigate();



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div > 
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Wallet</p>
<div className='maxHeight'>

<label>Amount</label>
    <input placeholder='Enter Amount'/>
    <label>Transaction Type</label>
    <div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
    <label>Account Type</label>
    <div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
    <label>Message Type</label>
    <input placeholder='Default Message'/>
    <label>Notes</label>
    <input placeholder='Enter Notes'/>
    <label>Valid From</label>
    <div className='dateDiv'>
    <input type="date"/>
    <img src={calender}/>
    </div>
 
    <label>Expiry Date</label>
    <div className='dateDiv'>
    <input type="date"/>
    <img src={calender}/>
    </div>
    <label>Order ID</label>
    <input placeholder='# Order id'/>

           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div></div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddWallet;
 