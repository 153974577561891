   

 
import React, {useEffect,useRef, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import {GoogleMap,useLoadScript,MarkerF} from "@react-google-maps/api"
import mapLogo from "../assets/mapLogo.png"

function OrdinaryNo(props) {
    const libraries = ['places'];

    const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw",

    libraries:libraries
    })
    const markerRef=useRef()

    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()

const [position,setPostion]=useState(

    {
  lat:parseFloat(43.4),
  lng:parseFloat(43.4)
  
  
    }
  )  
  

  useEffect(()=>{
    window.scrollTo(20000, 0);
  },[])

  



  function onMarkerDragEnd(evt){

    var newLat = evt.latLng.lat();
    var newLng = evt.latLng.lng();

   setPostion({
  
    lat:newLat,
    lng:newLng
   })
  
  };

  useEffect(() => {
    console.log(preview)
      if (preview) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
    
        };
        reader.readAsDataURL(preview);
      } 
    
    }, [preview]);
    
    const handleDragOver = (e) => {
        e.preventDefault();
 
      };
    
      const handleDragLeave = (e) => {
        e.preventDefault();
      };
    
      const handleDrop = (e) => {
        e.preventDefault();
    
        const files = Array.from(e.dataTransfer.files);
       const imageType = /^image\//;
    
      if (files.length === 1 && imageType.test(files[0].type)) {
        setPreview(files[0]);
      } else {
      }
    
      };



    return ( 
      
 
 

    
    <>
<div className='col-6'>

<label>Delivery :</label>
    <div className='select-container'>
  <select>
      <option>Direct</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Name En :</label>
   <input/>

  <label>Branch Code :</label>
  <input/>


  <label>Working from :</label>
    <input type="time"/>

    <label>Select Country :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Select District :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Wallet Payment:</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>


    <label>Car Option :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>



  <label>Online Payment :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>


   
</div>
<div className='col-6'>

<label>Branch Pickup :</label>
    <div className='select-container'>
  <select>
      <option>Direct</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Name Ar :</label>
   <input/>

  <label>Phone :</label>
  <input/>

  <label>Working To :</label>
    <div className='dateDiv'>
    <input type="time"/>
    </div>


    <label>Select City :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Cash Payment :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>ApplePay Payment:</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>



  <label>Image :</label>
    <div id="uploadImg"
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop</p>
</>}
</div>

  <label>Map Address :</label>
    <div className='select-container'>
  <select>
      <option>And </option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>






</div>
</>


    );
}

export default OrdinaryNo;
 