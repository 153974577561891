 
    

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BsTelephone} from 'react-icons/bs';
import fullLogo from "../assets/fullLogo.png"
import pin from "../assets/area.svg"

function Invoice(props) {

const history= useNavigate();



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal invoice" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"70vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             <div className='invoiceLogo'  style={{ backgroundImage: `url(${fullLogo})` }}>

           </div>

           <p className='invoiceTitle'>INVOICE</p>
           <p className='invoiceName'>Mariam Aly</p>
<div className='invoiceUsrData'>
<svg className='pinSvg' width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="marker-pin-03">
<g id="Icon">
<path d="M11.6009 20.1668C12.5176 15.5835 18.9342 15.0503 18.9342 9.16683C18.9342 5.11674 15.651 1.8335 11.6009 1.8335C7.55082 1.8335 4.26758 5.11674 4.26758 9.16683C4.26758 15.0503 10.6842 15.5835 11.6009 20.1668Z" stroke="#C80853" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6009 11.9168C13.1197 11.9168 14.3509 10.6856 14.3509 9.16683C14.3509 7.64805 13.1197 6.41683 11.6009 6.41683C10.0821 6.41683 8.85091 7.64805 8.85091 9.16683C8.85091 10.6856 10.0821 11.9168 11.6009 11.9168Z" stroke="#C80853" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
</svg>
<p>
    Smouha sidi gaber 7-Mahmoud shoukry street - test</p>
<p>
<BsTelephone size={16} color="rgba(247, 55, 130, 1)"/>

   +201209381092</p>

</div>

<table className='invoiceTable'>
  <thead>
<tr>
<th>Item</th>
<th>Description</th>
<th>Price</th>
<th>QTY</th>
<th style={{paddingRight:"0"}}>Discount</th>
</tr>
</thead>
<tbody>
  <tr>
  <td>فراخ مشويه</td>
  <td>Korem ipsum dolor sit amet, consectetur adipiscing elit.</td>
  <td>60 SAR</td>
  <td>1</td>
  <td style={{paddingRight:"0"}}> 60 SAR</td>  </tr>
  <tr>
  <td>فراخ مشويه</td>
  <td>Korem ipsum dolor sit amet, consectetur adipiscing elit.</td>
  <td>60 SAR</td>
  <td>1</td>
  <td style={{paddingRight:"0"}}>60 SAR</td>  </tr>

  <tr className='invoiceTotal'>
  <td></td>
  <td></td>
  <td className='tdTotal'>Total Price</td>
  <td></td>
  <td style={{paddingRight:"0"}}>27187 SAR</td>  </tr>
  <tr className='invoiceTotal'>
  <td></td>
  <td></td>
  <td className='tdTotal'>Delivery Price</td>
  <td></td>
  <td style={{paddingRight:"0"}}>27187 SAR</td>  </tr>
  <tr className='invoiceTotal'>
  <td></td>
  <td></td>
  <td className='tdTotal'>TAX</td>
  <td></td>
  <td style={{paddingRight:"0"}}>27187 SAR</td>  </tr>

</tbody>
</table>
             </div>
     
            </div>
          
          </div>
        </div>
    

    );
}

export default Invoice;
 