  
    

 
import React, {useEffect, useState,useRef} from 'react';
import { BeatLoader } from 'react-spinners';
import {BiChevronDown} from 'react-icons/bi';
import {BsFillTrashFill}  from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';

function AddMdiafMeal(props) {

useEffect(()=>{
  window.scrollTo(20000, 0);
},[])


    return ( 
      
 
 
        <div  className="AddModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content addCateg" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Mdiaf Meal</p>
<label>Mdiaf Category :</label>
<div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>

  <label>Mdiaf Product :</label>
  <div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  
  <label> Size :</label>
  <div className='select-container'>
  <select>
      <option>10</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  
  
  <label>Price SAR :</label>
  <input type="number"/>
  
  
  
           <div className='modalBtns '>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddMdiafMeal;
 