   

 
import React, {useEffect,useRef, useState} from 'react';
import { BsFillTrashFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import {FiUploadCloud} from 'react-icons/fi';
import {useFormik} from "formik";
import * as yup from "yup"
import calender from "../assets/calender.svg"
import { BsCheck } from 'react-icons/bs';
import DropDown from './DropDown';
function AddProduct(props) {


    const[errMsg,setErrMsg]=useState()
    const[err,setErr]=useState(false)
    const uploadImg= useRef()
    const [image,setImage]=useState()
    const [preview,setPreview]=useState()
    
    let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

        const Schema= yup.object().shape({
            nameAr: yup.string().required("Product name is required").matches(nameRegEx,{message:("Product name should contain letters only")}),
            nameEn: yup.string().required("Product name is required").matches(nameRegEx,{message:("Product name should contain letters only")}),
            describeAr: yup.string().required("Description  is required"),
            describeEn:yup.string().required("Description is required"),
            category: yup.string().required("Product category  is required"),
            delivery:yup.string().required("Delivery option is required"),
    
            })
    
    
            const formik = useFormik({
              initialValues:{
                nameAr: '',
                nameEn: '',
                describeAr: '',
                describeEn: '',
                category: '',
                delivery: '',
        
    
               
              },
              validationSchema: Schema,
              onSubmit,
              });
    
    
    
              function onSubmit(value){
              }
     

    
    useEffect(() => {
        console.log(preview)
          if (preview) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImage(reader.result);
              setErr(false)
        
            };
            reader.readAsDataURL(preview);
          } 
        
        }, [preview]);
        
        const handleDragOver = (e) => {
            e.preventDefault();
     
          };
        
          const handleDragLeave = (e) => {
            e.preventDefault();
          };
        
          const handleDrop = (e) => {
            e.preventDefault();
        
            const files = Array.from(e.dataTransfer.files);
           const imageType = /^image\//;
        
          if (files.length === 1 && imageType.test(files[0].type)) {
            setPreview(files[0]);
          } else {
            setErr(true)
            setErrMsg("Please drop only one image file");
          }
        
          };


useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal addProduct maxHeightModal" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"52vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Add Product</p>
<div className='maxHeight'>

<label>Name Ar :</label>
<input placeholder='Enter name' value={formik.values.nameAr} id="nameAr" name="nameAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameAr && formik.touched.nameAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameAr && formik.touched.nameAr?formik.errors.nameAr:null}</span>

<label>Name En :</label>
<input placeholder='Enter name' value={formik.values.nameEn} id="nameEn" name="nameEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.nameEn && formik.touched.nameEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.nameEn && formik.touched.nameEn?formik.errors.nameEn:null}</span>

<label>Description Ar:</label>
<input placeholder='Enter name' value={formik.values.describeAr} id="describeAr" name="describeAr" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describeAr && formik.touched.describeAr ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.describeAr && formik.touched.describeAr?formik.errors.describeAr:null}</span>

<label>Description En :</label>
<input placeholder='Enter name' value={formik.values.describeEn} id="describeEn" name="describeEn" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.describeEn && formik.touched.describeEn ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.describeEn && formik.touched.describeEn?formik.errors.describeEn:null}</span>

<label>Category :</label>
    <div className='select-container'>
  <select value={formik.values.category} id="category" name="category" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.category && formik.touched.category ?"invalid":null}>
      <option>Direct</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.category && formik.touched.category?{marginBottom:"24px"}:null} className='errSpan'>{formik.errors.category && formik.touched.category?formik.errors.category:null}</span>


  <label>Delivery :</label>
    <div className='select-container'>
  <select  value={formik.values.delivery} id="delivery" name="delivery" required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.delivery && formik.touched.delivery ?"invalid":null}>
      <option>Fixed Amount</option>
    </select>
    <BiChevronDown className='select-arrow' size={20}/>
  </div>
  <span style={formik.errors.delivery && formik.touched.delivery?{marginBottom:"24px"}:null}  className='errSpan'>{formik.errors.delivery && formik.touched.delivery?formik.errors.delivery:null}</span>

   <DropDown label="Extra :"/>

 
<label>Image :</label>
    <div id="uploadImg" style={{marginBottom:"24px"}}
 onDragOver={handleDragOver}
 onDragLeave={handleDragLeave}
 onDrop={handleDrop}
>
  {image?
   <div id="uploadedImg">
      <img src={image}/>
<button onClick={()=>{setPreview(null);setImage(null)}}><BsFillTrashFill color="#3B6DEA"/></button>
   </div>
  :
  <>
 
    <input type="file" accept="image/*" ref={uploadImg}  onChange={(e)=>
    {
      const file= e.target.files[0];
      if(file){
        setPreview(file);
      }
    }}/>
<div id="uploadIcn">
    <div>
    <FiUploadCloud size={20} color="rgba(71, 84, 103, 1)"/>

    </div>
</div>
<p style={{marginBottom:"0"}}><span onClick={()=>uploadImg.current.click()}>Click to upload </span> or drag and drop<br/> SVG, PNG, JPG  yes GIF</p>
</>}
</div>

    <div className='modalBtns'>
           <button className='confirm' >Save</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
</div>
    
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddProduct;
 