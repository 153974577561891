   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import status from "../assets/status.svg"

function Status(props) {

const history= useNavigate();



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
  
 
        <div  className="userModal statusModal" style={{position:"absolute",width:"100%"}}>
     
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"40vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
            
            <div style={{position:"relative"}}>

      
             <div className='navPp' style={{ backgroundImage: `url(${status})` }}>
  </div>
  </div>

<div style={{width:"24vw",margin:"auto"}}>


  <div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>

<button> Save</button>
</div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default Status;
 