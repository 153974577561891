    

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import { LuSearch } from 'react-icons/lu';
import { BsCheck } from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';

import {GrFormClose} from 'react-icons/gr';

function EditBranchArea(props) {

const history= useNavigate();
const [open,setOpen]=useState(false)
const [checkList,setCheckList]=useState([])


function handleCheck(value){
    const newArr=checkList
if (newArr.includes(value)) {
newArr.splice(newArr.indexOf(value), 1);  // Remove value
} else {
newArr.push(value);  // Add value
}
setCheckList(newArr)
}


document.addEventListener("change", function(event) {

  if (event.target.matches("input[type='checkbox']") && !(event.target.id=="supervisor")) {
    console.log("test")

    var checkbox = event.target;
    var parentElement = checkbox.parentElement.parentElement.parentElement;
    
    if (checkbox.checked) {
      parentElement.classList.add("checked");
    } else {
      parentElement.classList.remove("checked");
    }
  }
});



useEffect(()=>{
  window.scrollTo(20000, 0);
},[])

    return ( 
      
 
 
        <div  className="AddModal editBranchArea" style={{position:"absolute",width:"100%"}}>
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",width:"50vw "}}>
          
            <div >
         
             <div style={{height:"20vh",display:"contents"}} className='modalBody addBody'>
             
<p className='modalTitle'>Edit Rawani Branch Areas</p>


 
    <label>Areas</label>
   <div  className={open?'mutli-select':'mutli-select mutli-selectClosed'}>
   <div className='select-container'>

   <div onClick={()=>setOpen(!open)} className='multi-selectInput'>
{/*<p className='multi-selectDef'>Choose</p>*/}
<div className='chocies'>

  <p><RxDotFilled color='rgba(245, 10, 102, 1)'/> Branches <GrFormClose className='closeGray' />
  </p>  
</div>

<div className='chocies'>

  <p><RxDotFilled color='rgba(245, 10, 102, 1)'/> Branches <GrFormClose className='closeGray' />
  </p>  
</div>
</div>
  <BiChevronDown className='select-arrow' size={20}/>
</div>

<div className='select-dropdown'>
<div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='Search'/>
    <div className='spaceBtn'>
<label className="labelCheck">kalam</label>
<div className='containCheck'>
    <div> 
<input type="checkbox" className="chooseClass" value="Other" onChange={(e)=>handleCheck(e.target.value)}/>
    <BsCheck color="rgba(111, 5, 46, 1)" size={20}/> </div></div>
    </div>

    </div>
</div>
   </div>



  
           <div className='modalBtns'>
           <button className='confirm' >Submit</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     
           Cancel</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default EditBranchArea;
 