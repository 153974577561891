import '../styles/login.css';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import {HiOutlineKey} from 'react-icons/hi';
import PasswordContainer from '../components/PasswordContainer';
import {useFormik} from "formik";
import * as yup from "yup"
function ForgotPassword() {



  let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
 
  const Schema= yup.object().shape({
    email: yup.string().required("Email is required").matches(emailRegx,"Please enter a valid email"),
  
  })
  
  
  const formik = useFormik({
    initialValues:{
 
    email: '',

    },
    validationSchema: Schema,
    onSubmit,
    });



    function onSubmit(value){
    }





  return (
 <PasswordContainer
 title="Forgot password?"
 text="No worries, we’ll send you reset instructions."
 icon={ <HiOutlineKey color='#D16700'size={23}/>}
 >
  
  <label>Email</label>
  <input placeholder='test@test.com' value={formik.values.email} id="email" name="email"  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.email && formik.touched.email ?"invalid":null}/>
    <span className='errSpan'>{formik.errors.email && formik.touched.email?formik.errors.email:null}</span>
  <Link to="/check-email">
  <button id="loginBtn">Reset password</button>

  </Link>
  <Link to="/">
    <button className='bck' ><BiArrowBack size={18} color='#475467'/> Back to log in</button>
    </Link>
    </PasswordContainer>
  );
}

export default ForgotPassword;
