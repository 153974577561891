import './App.css';
import { BrowserRouter,Routes,Route,Outlet } from 'react-router-dom';
import Login from './screens/Login';
import ForgotPassword from './screens/ForgotPassword';
import CheckEmail from './screens/CheckEmail';
import SetNewPassword from './screens/SetNewPassword';
import PasswordReset from './screens/PasswordReset';
import Dashboard from './screens/Dashboard';
import Users from './screens/Users';
import SingleUser from './screens/SingleUser';
import EditUser from './screens/EditUser';
import Admins from './screens/Admins';
import ExternalSales from './screens/ExternalSales';
import CustomerServices from './screens/CustomerServices';
import ExternalSalesUser from './screens/ExternalSalesUser';
import EditAgent from './screens/EditAgent';
import EditAdmin from './screens/EditAdmin';
import Countries from './screens/Countries';
import EditCountry from './screens/EditCountry';
import Cities from './screens/Cities';
import EditCity from './screens/EditCity';
import Areas from './screens/Areas';
import EditArea from './screens/EditArea';
import Districts from './screens/Districts';
import EditDistrict from './screens/EditDistrict';
import Orders from './screens/Orders';
import OrderDetails from './screens/OrderDetails';
import Promotions from './screens/Promotions';
import EditPromo from './screens/EditPromo';
import Conditions from './screens/Conditions';
import EditConditions from './screens/EditConditions';
import Offers from './screens/Offers';
import EditOffer from './screens/EditOffer';
import Categories from './screens/Categories';
import EditCategory from './screens/EditCategory';
import CategoryMeals from './screens/CategoryMeals';
import RecommendedProducts from './screens/RecommendedProducts';
import Meals from './screens/Meals';
import Branches from './screens/Branches';
import EditMeal from './screens/EditMeal';
import Reports from './screens/Reports';
import Products from './screens/Products';
import EditProduct from './screens/EditProduct';
import Additional from './screens/Additional';
import EditAdditional from './screens/EditAdditional';
import Extras from './screens/Extras';
import EditExtra from './screens/EditExtra';
import EditSize from './screens/EditSize';
import Sizes from './screens/Sizes';
import EditBranchByType from './screens/EditBranch/EditBranchByType';
import EditBranch from './screens/EditBranch';
import BranchMenu from './screens/BranchMenu';
import Mdiaf from './screens/Mdiaf';
import EditMenu from './screens/EditMenu';
import MdiafCategories from './screens/MdiafCategories';
import EditMdiafCategory from './screens/EditMdiafCategory';
import MdiafMeals from './screens/MdiafMeals';
import EditMdiafMeal from './screens/EditMdiafMeal';
import MdiafProducts from './screens/MdiafProducts';
import EditMdiafProduct from './screens/EditMdiafProduct';
import MdiafServices from './screens/MdiafServices';
import EditMdiafService from './screens/EditMdiafService';
import MdiafInquires from './screens/MdiafInquires';
import MdiafInquiresDetails from './screens/MdiafInquiresDetails';
import MdiafImages from './screens/MdiafImages';
import EditMdiafImage from './screens/EditMdiafImage';
import Sales from './screens/Sales';
import EditSalesMenu from './screens/EditSalesMenu';
import SalesMeals from './screens/SalesMeals';
import EditSalesMeal from './screens/EditSalesMeal';
import SalesServices from './screens/SalesServices';
import EditSalesService from './screens/EditSalesService';
import SalesInquires from './screens/SalesInquires';
import AddSalesClient from './components/AddSalesClient';
import SalesClients from './screens/SalesClients';
import Settings from './screens/Settings';
import EditSettings from './screens/EditSettings';
import SettingsOrderSources from './screens/SettingsOrderSources';
import EditSource from './screens/EditSource';
import SettingsOrderType from './screens/SettingsOrderType';
import EditType from './screens/EditType';
import AppVersions from './screens/AppVersions';
import EditAppVersion from './screens/EditAppVersion';
import Terms from './screens/Terms';
import EditTerms from './screens/EditTerms';
import Blog from './screens/Blog';
import EditPost from './screens/EditPost';
import News from './screens/News';
import EditNewsPost from './screens/EditNewsPost';
import Questions from './screens/Questions';
import EditQuestion from './screens/EditQuestion';
import Observation from './screens/Observation';
import Chat from './screens/Chat';
import ChatActions from './screens/ChatActions';
import Banner from './screens/Banner';
import EditBanner from './screens/EditBanner';
import MealBranches from './screens/MealBranches';
import Addresses from './screens/Addresses';
import UserOrders from './screens/UserOrders';
import Wallets from './screens/Wallets';
import EditCashierBranch from './screens/EditBranch/EditCashierBranch';
import EditWebBranch from './screens/EditBranch/EditWebBranch';
import EditBranchQr from './screens/EditBranch/EditBranchQr';
import EditPosBranch from './screens/EditBranch/EditPosBranch';
import EditExternalSalesBranch from './screens/EditBranch/EditExternalSalesBranch';

function App() {
  var dt = new Date();
  const year= dt.getFullYear()
  return (
    <div className="App">
    <BrowserRouter>
     <Routes> 
           
     <Route
      element={(
        <> 
          <Outlet />
        </>
      )}
    >

      <Route
        element={(
          <>
                      <Outlet />

  <div className='powered'>

<p  id="cc">All copyright reserved for AlRomansiah<span style={{fontWeight:"500"}}> © {year}</span> </p>
<p  id="cc">Version: <span style={{fontWeight:"500"}}> 6.0.0</span> </p>

<p  id="cc">Powered By: <a target="_blank" style={{fontWeight:"500"}} href="https://www.bdaiat.com/"> Bdaiat Technologies</a> </p>

</div>
   

          </>
        )}
      >


<Route exact path="/dashboard" element={<Dashboard/>}/>

<Route exact path="/users/users-list" element={<Users/>}/>
<Route exact path="/users/users-list/:id" element={<SingleUser/>}/>
<Route exact path="/users/edit-user" element={<EditUser/>}/>
<Route exact path="/users/addresses" element={<Addresses/>}/>
<Route exact path="/users/orders" element={<UserOrders/>}/>
<Route exact path="/users/wallets" element={<Wallets/>}/>

<Route exact path="/admins/admins-list" element={<Admins/>}/>
<Route exact path="/admins/external-sales" element={<ExternalSales/>}/>
<Route exact path="/admins/customer-service" element={<CustomerServices/>}/>
<Route exact path="/admins/edit-agent" element={<EditAgent/>}/>
<Route exact path="/admins/edit-admin" element={<EditAdmin/>}/>
<Route exact path="/admins/external-sales/:id" element={<ExternalSalesUser/>}/>

<Route exact path="/countries" element={<Countries/>}/>
<Route exact path="/countries/edit-country" element={<EditCountry/>}/>
<Route exact path="/countries/cities" element={<Cities/>}/>
<Route exact path="/countries/cities/areas" element={<Areas/>}/>
<Route exact path="/countries/edit-city" element={<EditCity/>}/>
<Route exact path="/countries/edit-district" element={<EditDistrict/>}/>
<Route exact path="/countries/edit-area" element={<EditArea/>}/>
<Route exact path="/countries/districts" element={<Districts/>}/>

<Route exact path="/orders" element={<Orders/>}/>
<Route exact path="/orders/car-orders" element={<Orders/>}/>
<Route exact path="/orders/mdiaf-orders" element={<Orders/>}/>
<Route exact path="/orders/:id" element={<OrderDetails/>}/>
<Route exact path="/orders/car-orders/:id" element={<OrderDetails/>}/>
<Route exact path="/orders/mdiaf-orders/:id" element={<OrderDetails/>}/>

<Route exact path="/promotions" element={<Promotions/>}/>
<Route exact path="/promotions/edit-promotions" element={<EditPromo/>}/>
<Route exact path="/promotions/conditions" element={<Conditions/>}/>
<Route exact path="/promotions/offers" element={<Offers/>}/>
<Route exact path="/promotions/edit-conditions" element={<EditConditions/>}/>
<Route exact path="/promotions/edit-offer" element={<EditOffer/>}/>

<Route exact path="/categories" element={<Categories/>}/>
<Route exact path="/categories/edit-category" element={<EditCategory/>}/>
<Route exact path="/categories/meals" element={<CategoryMeals/>}/>
<Route exact path="/categories/recommended-products" element={<RecommendedProducts/>}/>

<Route exact path="/products/meals-list" element={<Meals/>}/>
<Route exact path="/products" element={<Products/>}/>
<Route exact path="/products/branches" element={<MealBranches/>}/>
<Route exact path="/products/edit-meal" element={<EditMeal/>}/>
<Route exact path="/products/edit-product" element={<EditProduct/>}/>
<Route exact path="/products/reports" element={<Reports/>}/>
<Route exact path="/products/additionals-list" element={<Additional/>}/>
<Route exact path="/products/edit-additional" element={<EditAdditional/>}/>
<Route exact path="/products/extras-list" element={<Extras/>}/>
<Route exact path="/products/edit-extra" element={<EditExtra/>}/>
<Route exact path="/products/sizes-list" element={<Sizes/>}/>
<Route exact path="/products/edit-size" element={<EditSize/>}/>

<Route exact path="/branches" element={<Branches/>}/>
<Route exact path="/branches/edit-branch-cashier" element={<EditCashierBranch/>}/>
<Route exact path="/branches/edit-branch-web" element={<EditWebBranch/>}/>
<Route exact path="/branches/edit-branch-qr" element={<EditBranchQr/>}/>
<Route exact path="/branches/edit-branch-pos" element={<EditPosBranch/>}/>
<Route exact path="/branches/edit-branch-external" element={<EditExternalSalesBranch/>}/>

<Route exact path="/branches/edit-branch" element={<EditBranch/>}/>
<Route exact path="/branches/menu" element={<BranchMenu/>}/>

<Route exact path="/mdiaf/menu" element={<Mdiaf/>}/>
<Route exact path="/mdiaf/edit-menu" element={<EditMenu/>}/>
<Route exact path="/mdiaf/categories" element={<MdiafCategories/>}/>
<Route exact path="/mdiaf/edit-category" element={<EditMdiafCategory/>}/>
<Route exact path="/mdiaf/meals" element={<MdiafMeals/>}/>
<Route exact path="/mdiaf/edit-meal" element={<EditMdiafMeal/>}/>
<Route exact path="/mdiaf/products" element={<MdiafProducts/>}/>
<Route exact path="/mdiaf/edit-product" element={<EditMdiafProduct/>}/>
<Route exact path="/mdiaf/services" element={<MdiafServices/>}/>
<Route exact path="/mdiaf/edit-service" element={<EditMdiafService/>}/>
<Route exact path="/mdiaf/inquires" element={<MdiafInquires/>}/>
<Route exact path="/mdiaf/inquires/:id" element={<MdiafInquiresDetails/>}/>
<Route exact path="/mdiaf/images" element={<MdiafImages/>}/>
<Route exact path="/mdiaf/edit-image" element={<EditMdiafImage/>}/>

<Route exact path="/sales/menus" element={<Sales/>}/>
<Route exact path="/sales/edit-menu" element={<EditSalesMenu/>}/>
<Route exact path="/sales/meals" element={<SalesMeals/>}/>
<Route exact path="/sales/edit-meal" element={<EditSalesMeal/>}/>
<Route exact path="/sales/services" element={<SalesServices/>}/>
<Route exact path="/sales/edit-service" element={<EditSalesService/>}/>
<Route exact path="/sales/inquires" element={<SalesInquires/>}/>
<Route exact path="/sales/clients" element={<SalesClients/>}/>

<Route exact path="/settings" element={<Settings/>}/>
<Route exact path="/settings/edit-setting" element={<EditSettings/>}/>
<Route exact path="/settings/sources" element={<SettingsOrderSources/>}/>
<Route exact path="/settings/edit-source" element={<EditSource/>}/>
<Route exact path="/settings/types" element={<SettingsOrderType/>}/>
<Route exact path="/settings/edit-type" element={<EditType/>}/>
<Route exact path="/settings/versions" element={<AppVersions/>}/>
<Route exact path="/settings/edit-version" element={<EditAppVersion/>}/>
<Route exact path="/settings/terms" element={<Terms/>}/>
<Route exact path="/settings/edit-terms" element={<EditTerms/>}/>
<Route exact path="/blog" element={<Blog/>}/>
<Route exact path="/blog/edit-post" element={<EditPost/>}/>
<Route exact path="/blog/newsPosts" element={<News/>}/>
<Route exact path="/blog/edit-newsPost" element={<EditNewsPost/>}/>
<Route exact path="/blog/questions" element={<Questions/>}/>
<Route exact path="/blog/edit-question" element={<EditQuestion/>}/>
<Route exact path="/chat" element={<Chat/>}/>
<Route exact path="/chat/actions" element={<ChatActions/>}/>
<Route exact path="/chat/observation" element={<Observation/>}/>
<Route exact path="/banners" element={<Banner/>}/>
<Route exact path="/banners/edit-banner" element={<EditBanner/>}/>
</Route>


<Route exact path="/" element={<Login/>}/>
<Route exact path="/forgot-password" element={<ForgotPassword/>}/>
<Route exact path="/check-email" element={<CheckEmail/>}/>
<Route exact path="/set-password" element={<SetNewPassword/>}/>
<Route exact path="/password-reset" element={<PasswordReset/>}/>
</Route >

  </Routes>


  </BrowserRouter>
    </div>
  );
}

export default App;
