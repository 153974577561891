 
import logoPlain from "../assets/logoPlain.png"
import sack from "../assets/sack.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import chat from "../assets/chatP.svg"
import edit from "../assets/edit.svg"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {HiOutlineKey} from 'react-icons/hi';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import AddPromo from '../components/AddPromo';
import {RiDeleteBinLine} from 'react-icons/ri';
import AddOffer from '../components/AddOffer';

import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';
function Offers() {
    const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer usersContainer promotionContainer'>
      {add?<AddOffer setModal={setAdd}/>:null}

 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Offers</p>
<div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/promotions/offers">

<p className='bc1'>Offers</p></Link>
<img src={right}/>
<Link to="/promotions/offers">

<p className='bc2'>Offers List</p></Link>
</div>
    </div>

    <button onClick={()=>setAdd(true)} className='export'>

Add Offer </button>
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Your offers list</p>
  </div>

  <div className='filtersMore'>
<div className='filtersContainer'>
  
<div className='flex-2' style={{marginRight:"70px"}}>
  <label>Search </label>

  <div className='srchIcn'>
      <LuSearch size={20} color="rgba(102, 112, 133, 1)"/>
    <input placeholder='User Name or Phone '/>
    </div>

</div>
<div className='flex-1' >
<label>Show </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>
<div className='flex-1'>

</div>
<div className='flex-1'>

</div>


  </div>


  </div>

</div>
<div className='tableUsers adminTable'>

<table>
<thead>
  <tr>
    <th>ID Number</th>
    <th>Name</th>
    <th>Type</th>
    <th>Method</th>
    <th>Amount</th>
    <th>From</th>
    <th>To</th>
    <th>Limit</th>
    <th>Conditions</th>
    <th>Uses</th>

    <th  style={{textAlign:"center"}}>Action</th>

  </tr>
  </thead>
  <tbody>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >
New33
</td>
<td  >direct</td>
<td>234</td>
<td>53</td>
<td>53/12/20</td>
<td>53/12/20</td>
<td>53</td>
<td>53</td>
<td>53</td>

<td>
<Link to="/promotions/edit-offer">
  <button>
  <img src={edit}/>
  </button>
  </Link>

  </td>

    </tr>
    <tr>
<td style={{color:"#101828"}}>3066</td>
<td  >
New33
</td>
<td  >direct</td>
<td>234</td>
<td>53</td>
<td>53/12/20</td>
<td>53/12/20</td>
<td>53</td>
<td>53</td>
<td>53</td>

<td>
<Link to="/promotions/edit-offer">
  <button>
  <img src={edit}/>
  </button>
  </Link>

  </td>

    </tr>
  </tbody>
</table>
</div>  </div>
</div>
<div className='tableBottomPagination'>
<p className='totalTable'>Total Users 3000</p> 
<div className='flexAlign'>
<p className='pageNum'>8/9 Users list</p>
<div>
 
<Stack spacing={2}>
      <Pagination count={11} defaultPage={6} /> 
  
    </Stack>
</div>
<div className='goTo'>
 <p> Go to page
   </p>
  
  <input/>
  <button>Go <BiChevronRight size={18}/> </button>
</div>

</div>

 </div>
  </div>

   </div>
    </div>
  );
}

export default Offers;
