import logoPlain from "../assets/logoPlain.png"
import right from "../assets/right.svg"
import Navbar from '../components/Navbar';
import {RiHome6Line}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import {BsCheck} from 'react-icons/bs';
import {LuSearch} from 'react-icons/lu';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';
import calender from "../assets/calender.svg"
import AddMdiafMeal from '../components/AddMdiafMeal';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import DropDown from "../components/DropDown";
function EditMdiafMeal() {
  const [add,setAdd]=useState(false)


  return (
    <div className='dashboardContainer promoContainer usersContainer singleUserContainer'>
 <Navbar/>
{add? <AddMdiafMeal setModal={setAdd}/>:null}
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>

  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Mdiaf</p>
    <div className='navigateTop'>
<Link to="/dashboard">

<RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/></Link>
<img src={right}/>
<Link to="/mdiaf/menu">

<p className='bc1'>Mdiaf</p></Link>
<img src={right}/>
<Link to="/mdiaf/meals">

<p className='bc2'>Meals List</p>
</Link>
</div>
    </div>
    <button onClick={()=>setAdd(true)} className='export'>

Add Meal </button>
  </div>
 

<div style={{height:"95%"}}  className='editContainer editAgent'>
  <div>


<p className='editTitle'>Edit Mdiaf Meal</p>
<div className='editRow'>

<div>
    <label>Mdiaf Category :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div style={{margin:"0 25px"}}>
<label>Mdiaf Product :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>


</div>

<div>
<div className='editRow'>

<div>
    <label> Size :</label>
    <div className='select-container'>
<select>
    <option>Percentage</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div></div>
<div style={{margin:"0 25px"}}>
<label>Price SAR :</label>
    <input type="number"/>
</div>


</div>


</div>
</div>


<div className='editBtns'>
<button className='confirm'>Submit</button>
<button className='cancel'>Cancel</button>
</div>
</div>

<div></div>

</div>
  </div>

   </div>
    </div>
  );
}

export default EditMdiafMeal;
