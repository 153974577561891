import '../styles/chat.css';
import pp from "../assets/categories.png"

import meal from "../assets/meal.svg"
import logoPlain from "../assets/logoPlain.png"
import message from "../assets/message-chat-square.svg"
import right from "../assets/right.svg"
import calender from "../assets/calender.svg"
import {LuSearch} from 'react-icons/lu';
import upload from "../assets/upload.svg"
import chatImgCs from "../assets/chatImgCs.png"
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { NavLink } from 'react-router-dom';
import {IoIosCloseCircleOutline} from 'react-icons/io';
import {RiHome6Line,RiDeleteBinLine}  from 'react-icons/ri';
import {FiSearch} from 'react-icons/fi';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {IoFilter} from 'react-icons/io5';
import {BiChevronRight,BiChevronDown} from 'react-icons/bi';

import { useState } from 'react';
import DeleteModal from '../components/DeleteModal';

import BlockModal from '../components/BlockModal';
import { BsThreeDots } from 'react-icons/bs';
function Chat() {
  const [filter,setFilter]=useState(false)
  const [block,setBlock]=useState(false)
  const [text,setText]=useState("")

  const [toggle,setToggle]=useState(false)
  const [toggleReply,setToggleReply]=useState(false)

  return (
    <div className='dashboardContainer usersContainer'>
 {block? <BlockModal setModal={setBlock}/>: null}
 <Navbar/>
   <div className='dashobard'>
<div  className='analysisContainer'>
  <div>


  <div className='analysisTop'>
    <div className='logoPlain'  style={{ backgroundImage: `url(${logoPlain})` }}>

    </div>
    <div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>
  </div>



  <div className='flexBetween welcomeDiv' >
    <div >
    <p className='screenTitle'>Chat</p>
<div className='navigateTop'>

<Link to="/dashboard">
  <RiHome6Line size={25} color="rgba(102, 112, 133, 1)"/>

  </Link>
  <img src={right}/>
  <Link to="/chat">

<p className='bc1'>Chat</p>
</Link>
<img src={right}/>

  <Link to="/chat">
<p className='bc2'>Chat List</p></Link>

</div>
    </div>
  
  </div>
 
<div className='tableContainer'>
<div className='tableTop'>
  <div className='flexAlign'>
  <p className='tableTitle'>Chat list</p>
  <button onClick={()=>setFilter(!filter)} className='titleBtn'><IoFilter size={20}/>Filter</button>

  </div>


  <div className={filter?'filtersMore':'filtersLess'}>
<div className='filtersContainer'>
  

<div className='flex-1' >
<label>Read </label>
<div className='select-container'>
<select>
    <option>All</option>
  </select>
  <BiChevronDown className='select-arrow' size={20}/>
</div>
</div>

<div className='flex-1' >
  <label>From </label>
  <div className='date-container'>
  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  <div className='flex-1' >
  <div className='date-container'>
  <label>To </label>

  <input type="date"/>
<img src={calender}/>
  </div>
  </div>
  
  <div className='flex-2 alignCenter' style={{justifyContent:"start",marginLeft:"21px"}}>
  
  <button className='clear' style={{paddingLeft:"0"}}><IoIosCloseCircleOutline size={18}/>  Clear Filters </button>
  <button className='apply'>Apply Filters  </button>

  </div>

  </div>

 


  </div>



</div> 
<div className='chatContainer'>

<div className='chatList'>
    <div className='chatListTopper'>


<p className='chatListTitle'>Customer Service Chat</p>
<p className='chatCount'>40</p>    </div>
<div className='srchDiv'>
<FiSearch color='#667085' size={20}/>
<input placeholder='Search'/>
    </div>

<div className='overflowList'>


    <div className='chatDiv activeChatDiv'>
<div style={{marginBottom:"16px"}} className='flexBetween'>

<div className='alignTxt'>
<div className='msgDot'></div>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"40px",height:"40px" }}>
  </div>
  <div >
  <p className='chatUsrName'>Ahmed Aly</p>
  <p className='chatUsrPhone'>051273672</p>
  </div>

</div>
<p className='timeAgo'>5min ago</p>
</div>
<p className='lastMsg'>Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...</p>
    </div>

    <div className='chatDiv'>
<div style={{marginBottom:"16px"}} className='flexBetween'>

<div className='alignTxt'>
<div className='msgDot'></div>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"40px",height:"40px" }}>
  </div>
  <div >
  <p className='chatUsrName'>Ahmed Aly</p>
  <p className='chatUsrPhone'>051273672</p>
  </div>

</div>
<p className='timeAgo'>5min ago</p>
</div>
<p className='lastMsg'>Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...</p>
    </div>

    <div className='chatDiv'>
<div style={{marginBottom:"16px"}} className='flexBetween'>

<div className='alignTxt'>
<div className='msgDot'></div>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"40px",height:"40px" }}>
  </div>
  <div >
  <p className='chatUsrName'>Ahmed Aly</p>
  <p className='chatUsrPhone'>051273672</p>
  </div>

</div>
<p className='timeAgo'>5min ago</p>
</div>
<p className='lastMsg'>Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...</p>
    </div>

    <div className='chatDiv'>
<div style={{marginBottom:"16px"}} className='flexBetween'>

<div className='alignTxt'>
<div className='msgDot'></div>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"40px",height:"40px" }}>
  </div>
  <div >
  <p className='chatUsrName'>Ahmed Aly</p>
  <p className='chatUsrPhone'>051273672</p>
  </div>

</div>
<p className='timeAgo'>5min ago</p>
</div>
<p className='lastMsg'>Hey Olivia, Katherine sent me over the latest doc. I just have a quick question about the...</p>
    </div>
</div></div>
<div className='chat'>
<div style={{marginBottom:"16px"}} className='flexBetween'>

<div className='alignTxt'>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"56px",height:"56px",marginRight:"16px" }}>
  </div>
  <div style={{textAlign:"start"}} >
  <p className='chatUsr'>Ahmed Aly</p>
  <p className='chatUsrPhone'>051273672</p>
  </div>
</div>
<div>
<button className='block' onClick={()=>setBlock(true)}>Block</button>
<Link to="/chat/actions">
<button className='actnList'>Action List</button>

</Link>

</div>

</div>
<div className='chatScrollContainer'>
<div className='chatScroll'>


<div className='singleTxt'>
<div className='usrLogo'  style={{ backgroundImage: `url(${pp})`,width:"40px",height:"40px" }}>
  </div>
<div>
    <div className='chatData'>
        <p className='chatName'>Mona Youssef</p>
        <p className='chatDate'>10:00 PM Monday 16 August 2023</p>

    </div>
    <div className='chatTxt'>
        <p>Hey, I have a big problem with the system. I can't make an order or check my cart we still have to wait for long time </p>
    </div>
</div>
</div>


<div className='singleTxt singleTxtCs'>

<div>
    <div className='chatData'>
        <p className='chatDate'>10:00 PM Monday 16 August 2023</p>
        <p className='chatName'>Mona Youssef</p>

    </div>
    <div className='chatTxt'>
        <p>Hey, I have a big problem with the system. I can't make an order or check my cart we still have to wait for long time </p>
    </div>
</div>
<div className='usrLogo'  style={{ backgroundImage: `url(${chatImgCs})`,width:"40px",height:"40px" }}>
  </div>

</div>



</div>
<div className='chatInput'>
<textarea onChange={(e)=>setText(e.target.value)} value={text} placeholder='Send a message'/>

<div style={{textAlign:"end"}}>
    <div className='sendOptions'>
    <div style={!toggle?{display:"none"}:null} onClick={()=>{ setToggle(false); setToggleReply(false) }} className="modalOverlay chatModalOverlay"></div>

    <button onClick={()=> setToggle(!toggle)} ><BsThreeDots size={20} color='rgba(102, 112, 133, 1)'/></button>  
   
   {toggle?
    <div className='inputImg'>
      <button>
<img src={upload}/> 
Upload Attachement
</button>

<button onClick={()=>setToggleReply(!toggleReply)} style={{marginBottom:"0"}}>

<img src={message}/> 
Choose Reply
</button>
    </div>:null}

    {toggleReply?



    <div className='replies'>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>
<p onClick={()=>{setText("test reply ready reply");setToggleReply(false);setToggle(false)}}>test reply ready reply</p>


    </div>:null}

      </div>

    <button className='sendTxt'>Send</button>
</div>
</div>
</div>
</div>
<div>
    
</div>

</div>


 </div>
</div>

  </div>

   </div>
    </div>
  );
}

export default Chat;
